<!-- <app-header-one></app-header-one> -->
<app-header-four></app-header-four>

<!-- Start Event Counter-->
<!-- main banner-3 ::before initialy had red overlay-->
<div class="main-banner-3 normal-bg" style="background-image: url('assets/images/sliders/hero_slider_3.png');">
    <div class="transform-center">
        <div class="container">
            <div class="row text-center">
                <div class="col-xl-12 col-lg-12">
                    <div class="banner-content banner-text">
                        <h1 class="text-custom-white fw-600 heading-title">CPSBs Annual Convention Event</h1>
                          
                 </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Event Counter-->

<!-- Start Services -->
<section class="section-padding p-relative">
    <div class="container">
        <div class="section-header">
            <div class="section-heading">
                <h6 class="text-orange mb-xl-10 sub-heading"><span>Gallery</span></h6>
                <h3 class="text-blue fw-700 title">Gallery<span class="text-orange">.</span></h3>
            </div>
        </div>
        <div class="lb-big-text">Gallery</div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="choose-us-box style-2 mb-xl-30" style="padding: 1cap;">
                    
                    <img src="assets/images/gallery/checks.jpeg" class="image-fit" alt="">
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="choose-us-box style-2 mb-xl-30" style="padding: 1cap;">
                    <img src="assets/images/gallery/img5.jpeg" class="image-fit" alt="">
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="choose-us-box style-2 mb-xl-30" style="padding: 1cap;">
                    <img src="assets/images/gallery/img6.jpeg" class="image-fit" alt="">
                </div>
            </div>
           
           

        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="choose-us-box style-2 mb-xl-30" style="padding: 1cap;">
                    
                    <img src="assets/images/gallery/imagers.jpeg" class="image-fit" alt="">
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="choose-us-box style-2 mb-xl-30" style="padding: 1cap;">
                    <img src="assets/images/gallery/image55.jpeg" class="image-fit" alt="">
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="choose-us-box style-2 mb-xl-30" style="padding: 1cap;">
                    <img src="assets/images/gallery/images5.jpeg" class="image-fit" alt="">
                </div>
            </div>
           
           

        </div>
        
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="choose-us-box style-2 mb-xl-30" style="padding: 1cap;">
                    
                    <img src="assets/images/gallery/lists.jpeg" class="image-fit" alt="">
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="choose-us-box style-2 mb-xl-30" style="padding: 1cap;">
                    <img src="assets/images/gallery/viewiam.jpeg" class="image-fit" alt="">
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="choose-us-box style-2 mb-xl-30" style="padding: 1cap;">
                    <img src="assets/images/gallery/makes.jpeg" class="image-fit" alt="">
                </div>
            </div>
           
           

        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="choose-us-box style-2 mb-xl-30" style="padding: 1cap;">
                    
                    <img src="assets/images/gallery/imgse.jpeg" class="image-fit" alt="">
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="choose-us-box style-2 mb-xl-30" style="padding: 1cap;">
                    <img src="assets/images/gallery/viewsd.jpeg" class="image-fit" alt="">
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="choose-us-box style-2 mb-xl-30" style="padding: 1cap;">
                    <img src="assets/images/gallery/imgs.jpeg" class="image-fit" alt="">
                </div>
            </div>
           
           

        </div>
    </div>
</section>
<!-- End Services -->





<!-- Start Past Events -->
<section class="section-padding">
    <div class="container">
        <div class="section-header">
            <div class="section-heading">
                <h6 class="text-orange mb-xl-10 sub-heading"><span>Past Events</span></h6>
                <h3 class="text-blue fw-700 title">View our past events & connect with us<span class="text-orange">.</span></h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="blog-slider row">
                    <div class="col-lg-12 slide-item">
                        <div class="blog-style-1">
                            <div class="post-img animate-img">
                                <a routerLink='/news-detail'>
                                    <img src="assets/images/past_events_1.jpg" class="image-fit" alt="">
                                </a>
                            </div>
                            <div class="post-date">
                                <span class="date">24</span>
                                <span class="month">June</span>
                            </div>
                            <div class="post-content">
                                <div class="post-category">
                                    <p class="double-lines text-orange fw-600 mb-1"><span><a href="#" class="text-orange">Finance</a></span></p>
                                </div>
                                <h2 class="post-title"><a routerLink='/news-detail'>Improving lives with tech HSE lighthouse project</a></h2>
                                <ul class="custom-flex post-meta">
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-user text-orange"></i>
                                            Rosalia D.
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-calendar-alt text-orange"></i>
                                            24th June 2020
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 slide-item">
                        <div class="blog-style-1">
                            <div class="post-img animate-img">
                                <a routerLink='#'>
                                    <img src="assets/images/past_events_4.jpg" class="image-fit" alt="">
                                </a>
                            </div>
                            <div class="post-date">
                                <span class="date">18</span>
                                <span class="month">June</span>
                            </div>
                            <div class="post-content">
                                <div class="post-category">
                                    <p class="double-lines text-orange fw-600 mb-1"><span><a href="#" class="text-orange">Business</a></span></p>
                                </div>
                                <h2 class="post-title"><a routerLink='#'>Improving lives with tech HSE lighthouse project</a></h2>
                                <ul class="custom-flex post-meta">
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-user text-orange"></i>
                                            Rosalia D.
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-calendar-alt text-orange"></i>
                                            18th June 2020
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 slide-item">
                        <div class="blog-style-1">
                            <div class="post-img animate-img">
                                <a routerLink='#'>
                                    <img src="assets/images/past_events_3.jpg" class="image-fit" alt="">
                                </a>
                            </div>
                            <div class="post-date">
                                <span class="date">04</span>
                                <span class="month">June</span>
                            </div>
                            <div class="post-content">
                                <div class="post-category">
                                    <p class="double-lines text-orange fw-600 mb-1"><span><a href="#" class="text-orange">Consultancy</a></span></p>
                                </div>
                                <h2 class="post-title"><a routerLink='/news-detail'>Improving lives with tech HSE lighthouse project</a></h2>
                                <ul class="custom-flex post-meta">
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-user text-orange"></i>
                                            Rosalia D.
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-calendar-alt text-orange"></i>
                                            04th June 2020
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Past Events --> 
  
  <!-- Register Event Modal -->
  <div class="modal fade" id="registerEvent" tabindex="-1" role="dialog" aria-labelledby="registerEventTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content bg-dark-blue text-white">
        <div class="modal-header">
          <h5 class="modal-title text-white" id="registerEventLongTitle">Register for Annual Convention Forum Event</h5>
          <button type="button" class="close" data-dismiss="modal" data-bs-theme="dark" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form action="">
            <div class="form-group">
                <label for="" class="text-white">Full Name</label>
                <input type="text" name="" id="" placeholder="Enter your full name" class="form-control">
            </div>
            <div class="form-group">
                <label for="" class="text-white">Email Address</label>
                <input type="email" name="" id="" placeholder="Enter your email address" class="form-control">
            </div>
            <div class="form-group" style="display: inline-flex; gap: 1em;">
                <input type="checkbox" name="" id="" class="form-control" style="width: 24px; height: 24px;">
                <label for="" class="text-orange">I will be attending the Annual Convention Forum for The County Public Service Boards</label>
            </div>
            <div class="form-group">
                <input type="submit" value="Register Event" class="theme-btn btn-orange">
            </div>
          </form>
        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="theme-btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="theme-btn btn-orange">Save changes</button>
        </div> -->
      </div>
    </div>
  </div>

<app-footer-one></app-footer-one>