<!-- <app-header-one></app-header-one> -->
<app-header-four></app-header-four>

<!-- Start Event Counter-->
<!-- main banner-3 ::before initialy had red overlay-->
<div class="main-banner-3 normal-bg" style="background-image: url('assets/images/sliders/hero_slider_3.png');">
    <div class="transform-center">
        <div class="container">
            <div class="row text-center">
                <div class="col-xl-12 col-lg-12">
                    <div class="banner-content banner-text">
                        <h1 class="text-custom-white fw-600 heading-title">CPSBs Annual Convention Event</h1>
                        <p class="no-margin text-custom-white">We seamlessly merge two key components – economics & informatio</p>
                        <div>
                            <app-event-countdown-timer></app-event-countdown-timer>
                        </div>
                        <a href="#registerEvent" data-toggle="modal" data-target="#registerEvent" class="theme-btn btn-orange">Register Event <i class="fal fa-plus"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Event Counter-->

<!-- Start Event Info -->
<div class="event-info">
    <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="d-flex align-items-center justify-content-center" style="gap: 1em;">
                <span class="event-icon d-flex align-items-center justify-content-center">
                    <i class="far fa-ticket"></i>
                </span>
                <div>
                    <h4>50 Tickets</h4>
                    <p>Available 200 tickets</p>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="d-flex align-items-center justify-content-center" style="gap: 1em;">
                <span class="event-icon d-flex align-items-center justify-content-center">
                    <i class="far fa-calendar"></i>
                </span>
                <div>
                    <h4>Start Date</h4>
                    <p>22nd - 27th Sep 2023</p>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="d-flex align-items-center justify-content-center" style="gap: 1em;">
                <span class="event-icon d-flex align-items-center justify-content-center">
                    <i class="far fa-location"></i>
                </span>
                <div>
                    <h4>Location</h4>
                    <p>Pride Inn Paradise Hotel, Mombasa</p>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="d-flex align-items-center justify-content-center" style="gap: 1em;">
                <span class="event-icon d-flex align-items-center justify-content-center">
                    <i class="far fa-ticket"></i>
                </span>
                <div>
                    <h4>10 Speakers</h4>
                    <p>Profesional & Friendly Toned</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Event Info -->

<!-- Start sponsors slider -->
<div class="section-padding bg-light-white p-relative">
    <div class="container-fluid">
        <div class="section-header">
            <div class="section-heading">
                <h6 class="text-orange mb-xl-10 sub-heading"><span>Sponsors</span></h6>
                <h3 class="text-blue fw-700 title">Platinum sponsors for the event<span class="text-orange">.</span></h3>
            </div>
        </div>
        <div class="lb-big-text">Sponsors</div>
        <div class="row justify-content-center">
            <div class="col-md-10">
                <div class="brand-slider row">
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/sponsors/safaricom-logo.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/sponsors/act-logo.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/sponsors/cpf-logo.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/sponsors/equity-logo.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/sponsors/ethics-logo.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/sponsors/ihrm-logo.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/sponsors/kcb-logo.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/sponsors/lapfund-logo.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/sponsors/nhif-logo.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/sponsors/nssf-logo.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/sponsors/rba-logo.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/sponsors/software-tech-logo.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End sponsors slider -->

<!-- Start About Event -->
<section class="section-padding">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="section-header left-heading">
                    <div class="section-heading">
                        <h6 class="text-orange mb-xl-10 sub-heading"><span>About Event</span></h6>
                        <h3 class="fw-700 title">What the event will entail<span class="text-orange">.</span></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 text-lg-right mb-xl-30">
                <a (click)="scrollToEventSchedule()" href="" class="theme-btn btn-orange">See Event's Schedule</a>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="choose-us-box mb-md-30">
                    <div class="top-sec">
                        <div class="icon">
                            <i class="flaticon-statistics text-orange"></i>
                        </div>
                        <h4 class="fw-600 no-margin"><a routerLink='/service-detail'>Digital consultancy</a></h4>
                    </div>
                    <p class="fw-500 mb-xl-10">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua nim ad minim veniam. </p>
                    <a routerLink='/service-detail' class="fw-600 fs-14 link">Read More <i class="fas fa-long-arrow-right text-orange"></i></a>
                    <i class="flaticon-statistics bg-icon"></i>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="choose-us-box mb-md-30">
                    <div class="top-sec">
                        <div class="icon">
                            <i class="flaticon-market text-orange"></i>
                        </div>
                        <h4 class="fw-600 no-margin"><a routerLink='/service-detail'>Market Research</a></h4>
                    </div>
                    <p class="fw-500 mb-xl-10">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua nim ad minim veniam. </p>
                    <a routerLink='/service-detail' class="fw-600 fs-14 link">Read More <i class="fas fa-long-arrow-right text-orange"></i></a>
                    <i class="flaticon-market bg-icon"></i>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="choose-us-box">
                    <div class="top-sec">
                        <div class="icon">
                            <i class="flaticon-investment text-orange"></i>
                        </div>
                        <h4 class="fw-600 no-margin"><a routerLink='/service-detail'>Productivity</a></h4>
                    </div>
                    <p class="fw-500 mb-xl-10">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua nim ad minim veniam. </p>
                    <a routerLink='/service-detail' class="fw-600 fs-14 link">Read More <i class="fas fa-long-arrow-right text-orange"></i></a>
                    <i class="flaticon-investment bg-icon"></i>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About Event -->

<!-- Start Paybill -->
<div class="quote-sec p-relative">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="quote-box bg-dark-blue">
                    <div class="row align-items-center justify-content-between">
                        <div class="col-lg-6 mb-md-80">
                            <div class="section-header left-heading pb-0">
                                <div class="section-heading">
                                    <h6 class="text-orange mb-xl-10 sub-heading"><span>Get Ticket</span></h6>
                                    <h3 class="fw-700 title text-custom-white">Get tickets today with MPESA Paybill<span class="text-orange">.</span></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 text-center">
                            <div class="mx-auto mb-2" style="width: 240px;">
                                <img src="assets/images/mpesa.jpeg" class="image-fit" alt="">
                            </div>
                            <p class="text-custom-white">We help you see the world differently, discover opportunities you may never have imagined.</p>
                            <div class="row text-center">
                                <div class="col-6">
                                    <p class="text-custom-white contact-no">
                                        <i class="fal fa-ticket text-orange"></i> <br>
                                        90876
                                    </p>
                                    <p class="text-white">Paybill Number</p>
                                </div>
                                <div class="col-6">
                                    <p class="text-custom-white contact-no">
                                        <i class="fal fa-bookmark text-orange"></i> <br>
                                        NCB Event
                                    </p>
                                    <p class="text-white">Account Name</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Paybill -->

<!-- Start Speakers -->
<section class="section-padding team-sec">
    <div class="container">
        <div class="section-header">
            <div class="section-heading">
                <h6 class="text-orange mb-xl-10 sub-heading"><span>Speakers</span></h6>
                <h3 class="text-blue fw-700 title">Our speakers for the event<span class="text-orange">.</span></h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="team-slider row">
                    <div class="slide-item col-12">
                        <div class="team-box animate-img">
                            <img src="assets/images/speakers/rigathi.jpg" class="image-fit" alt="">
                            <div class="team-caption">
                                <div class="content">
                                    <h5 class="fw-600 mb-1"><a routerLink='/team' class="text-custom-white">HE Rigathi Gachagua, EGH,</a></h5>
                                    <p class="text-orange fw-600 double-lines"><span>Deputy President of the Republic of Kenya</span></p>
                                    <p class="text-light-white">Prseside iver the Official Opening of the Convention on Monday 23rd October 2023 </p>
                                    <ul class="custom-flex team-social">
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-facebook-f"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-twitter"></i>
                                            </a>
                                        </li>
                                        <!-- <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-behance"></i>
                                            </a>
                                        </li> -->
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-linkedin"></i>
                                            </a>
                                        </li>
                                        <!-- <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-youtube"></i>
                                            </a>
                                        </li> -->
                                    </ul>
                                </div>
                            </div>
                            <div class="mt-4 text-center">
                                <h5 class="fw-600 mb-1">HE Rigathi Gachagua, EGH,</h5>
                                <p class="text-orange fw-600 double-lines"><span>Deputy President of the Republic of Kenya</span></p>
                            </div>
                        </div>
                    </div>
                    <div class="slide-item col-12">
                        <div class="team-box animate-img">
                            <img src="assets/images/speakers/koskei-1.jpg" class="image-fit" alt="">
                            <div class="team-caption">
                                <div class="content">
                                    <h5 class="fw-600 mb-1"><a routerLink='/team' class="text-custom-white">Mr Felix Kosge</a></h5>
                                    <p class="text-orange fw-600 double-lines"><span>Chief of Staff and Head of Public Service of the Republic of Kenya</span></p>
                                    <p class="text-light-white">Preside over the Official closing of the Convention on Thursday, 26th October 2023 </p>
                                    <ul class="custom-flex team-social">
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-facebook-f"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-linkedin"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="mt-4 text-center">
                                <h5 class="fw-600 mb-1"><a routerLink='/team'>Mr Felix Kosge</a></h5>
                                <p class="text-orange fw-600 double-lines"><span>Chief of Staff and Head of Public Service of the Republic of Kenya</span></p>
                            </div>
                        </div>
                    </div>
                    <div class="slide-item col-12">
                        <div class="team-box animate-img">
                            <img src="assets/images/speakers/oginde.jpg" class="image-fit" alt="">
                            <div class="team-caption">
                                <div class="content">
                                    <h5 class="fw-600 mb-1"><a routerLink='/team' class="text-custom-white">Dr Oginde</a></h5>
                                    <p class="text-orange fw-600 double-lines"><span>Chairman of the EACC</span></p>
                                    <p class="text-light-white">Key Note Speaker on the Topic of Good Corporate nance in the Counties </p>
                                    <ul class="custom-flex team-social">
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-facebook-f"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-linkedin"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="mt-4 text-center">
                                <h5 class="fw-600 mb-1"><a routerLink='/team'>Dr Oginde</a></h5>
                                <p class="text-orange fw-600 double-lines"><span>Chairman of the EACC</span></p>
                            </div>
                        </div>
                    </div>
                    <div class="slide-item col-12">
                        <div class="team-box animate-img">
                            <img src="assets/images/speakers/anne-waiguru.jpg" class="image-fit" alt="">
                            <div class="team-caption">
                                <div class="content">
                                    <h5 class="fw-600 mb-1"><a routerLink='/team' class="text-custom-white">Anne Waiguru</a></h5>
                                    <p class="text-orange fw-600 double-lines"><span>Chairperson of Council of Governors</span></p>
                                    <p class="text-light-white">Key Note Speaker</p>
                                    <ul class="custom-flex team-social">
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-facebook-f"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-linkedin"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="mt-4 text-center">
                                <h5 class="fw-600 mb-1"><a routerLink='/team'>Anne Waiguru</a></h5>
                                <p class="text-orange fw-600 double-lines"><span>Governor - Kirinyaga County</span></p>
                            </div>
                        </div>
                    </div>
                    <div class="slide-item col-12">
                        <div class="team-box animate-img">
                            <img src="assets/images/speakers/eluid-owalo.jpg" class="image-fit" alt="">
                            <div class="team-caption">
                                <div class="content">
                                    <h5 class="fw-600 mb-1"><a routerLink='/team' class="text-custom-white">CS Eluid Owalo</a></h5>
                                    <p class="text-orange fw-600 double-lines"><span>Cabinet Secratary of ICT & Digital Economy in Kenya</span></p>
                                    <p class="text-light-white">Key Note Speaker - Leveraging on ICT to improve service delivery </p>
                                    <ul class="custom-flex team-social">
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-facebook-f"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-linkedin"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="mt-4 text-center">
                                <h5 class="fw-600 mb-1"><a routerLink='/team'>Dr Oginde</a></h5>
                                <p class="text-orange fw-600 double-lines"><span>Chairman of the EACC</span></p>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="slide-item col-12">
                        <div class="team-box animate-img">
                            <img src="assets/images/speakers/koskei-1.jpg" class="image-fit" alt="">
                            <div class="team-caption">
                                <div class="content">
                                    <h5 class="fw-600 mb-1"><a routerLink='/team' class="text-custom-white">Romanisa S. Samu</a></h5>
                                    <p class="text-orange fw-600 double-lines"><span>Consultant</span></p>
                                    <p class="text-light-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidi dunt ut labore et dolore. </p>
                                    <ul class="custom-flex team-social">
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-facebook-f"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-behance"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-linkedin"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-youtube"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Speakers -->

<!-- Start Paybill -->
<div class="quote-sec p-relative">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="quote-box bg-dark-blue">
                    <div class="row align-items-center justify-content-between">
                        <div class="col-lg-6 mb-md-80">
                            <div class="section-header left-heading pb-0">
                                <div class="section-heading">
                                    <h6 class="text-orange mb-xl-10 sub-heading"><span>Register for event</span></h6>
                                    <h3 class="fw-700 title text-custom-white">Get tickets today<span class="text-orange">.</span></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <p class="text-custom-white">We help you see the world differently, discover opportunities you may never have imagined.</p>
                            <a href="#registerEvent" data-toggle="modal" data-target="#registerEvent" class="theme-btn btn-orange">Register For Event <i class="fal fa-plus"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Paybill -->

<!-- Start Days Two Events-->
<div id="eventScheduleTwo" class="section-padding bg-light-white">
    <div class="container">
        <div class="section-header">
            <div class="section-heading">
                <h6 class="text-orange mb-xl-10 sub-heading"><span>Events Info 2024</span></h6>
                <h3 class="text-blue fw-700 title">The days events<span class="text-orange">.</span></h3>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div>
                    <ul class="nav nav-tabs justify-content-center text-center" id="myTabs">
                        <li class="nav-item">
                            <a class="nav-link active" data-toggle="tab" href="#tab6">
                                <h4 class="fw-600 text-orange">Day 1</h4>
                                <p>26th August 2024</p>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#tab7">
                                <h4 class="fw-600 text-orange">Day 2</h4>
                                <p>27th August 2024</p>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#tab8">
                                <h4 class="fw-600 text-orange">Day 3</h4>
                                <p>28th August 2024</p>
                            </a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#tab9">
                                <h4 class="fw-600 text-orange">Day 4</h4>
                                <p>29th August 2024</p>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#tab10">
                                <h4 class="fw-600 text-orange">Day 5</h4>
                                <p>30th August 2024</p>
                            </a>
                        </li> -->
                    </ul>
                    
                    <div class="tab-content p-lg-5" style="background-color: white;">
                        <div id="tab6" class="tab-pane fade show active">
                            <div class="text-center">
                                <h3 class="text-orange mb-xl-10 sub-heading">Day 01</h3>
                                <p>26th August 2024</p>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Time</th>
                                            <th>Topic</th>
                                            <th>Speaker</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                               <b> <i class="far fa-clock text-orange"></i>  09:00 AM - 10:00 AM</b>
                                               <p class="text-orange">Marketing</p>
                                            </td>
                                            <td>
                                                <h5><b>HOW TO MAKETING FOR DESIGN ?</b></h5>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Numquam, fuga! Sapiente, ad? Tempore eius consequatur earum. Deleniti officiis nulla labore quibusdam reiciendis ex excepturi?</p>
                                            </td>
                                            <td>
                                                <img src="assets/images/person_1.png" class="image-fit" alt="" style="width: 48px; height: 48px; border-radius: 50%;">
                                                <p>Speaker</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                               <b> <i class="far fa-clock text-orange"></i>  11:00 AM - 12:00 PM</b>
                                               <p class="text-orange">Marketing</p>
                                            </td>
                                            <td>
                                                <h5><b>HOW TO MAKE BEAUTIFUL DESIGN ?</b></h5>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Numquam, fuga! Sapiente, ad? Tempore eius consequatur earum. Deleniti officiis nulla labore quibusdam reiciendis ex excepturi?</p>
                                            </td>
                                            <td>
                                                <img src="assets/images/person_4.png" class="image-fit" alt="" style="width: 48px; height: 48px; border-radius: 50%;">
                                                <p>Speaker</p>
                                            </td>
                                        </tr>
                                        <tr style="background-color: #ff4815; color: white;">
                                            <td colspan="3" class="text-center">
                                                <b>LUNCH TIME</b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                               <b> <i class="far fa-clock text-orange"></i>  02:00 PM - 03:00 PM</b>
                                               <p class="text-orange">Marketing</p>
                                            </td>
                                            <td>
                                                <h5><b>HOW TO MAKETING FOR DESIGN ?</b></h5>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Numquam, fuga! Sapiente, ad? Tempore eius consequatur earum. Deleniti officiis nulla labore quibusdam reiciendis ex excepturi?</p>
                                            </td>
                                            <td>
                                                <img src="assets/images/person_3.png" class="image-fit" alt="" style="width: 48px; height: 48px; border-radius: 50%;">
                                                <p>Speaker</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            
                        </div>
                        <div id="tab7" class="tab-pane fade">
                            <div class="text-center">
                                <h3 class="text-orange mb-xl-10 sub-heading">Day 02</h3>
                                <p>27th August 2024</p>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Time</th>
                                            <th>Topic</th>
                                            <th>Speaker</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                               <b> <i class="far fa-clock text-orange"></i>  09:00 AM - 10:00 AM</b>
                                               <p class="text-orange">Marketing</p>
                                            </td>
                                            <td>
                                                <h5><b>HOW TO MAKETING FOR DESIGN ?</b></h5>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Numquam, fuga! Sapiente, ad? Tempore eius consequatur earum. Deleniti officiis nulla labore quibusdam reiciendis ex excepturi?</p>
                                            </td>
                                            <td>
                                                <img src="assets/images/person_1.png" class="image-fit" alt="" style="width: 48px; height: 48px; border-radius: 50%;">
                                                <p>Speaker</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                               <b> <i class="far fa-clock text-orange"></i>  11:00 AM - 12:00 PM</b>
                                               <p class="text-orange">Marketing</p>
                                            </td>
                                            <td>
                                                <h5><b>HOW TO MAKE BEAUTIFUL DESIGN ?</b></h5>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Numquam, fuga! Sapiente, ad? Tempore eius consequatur earum. Deleniti officiis nulla labore quibusdam reiciendis ex excepturi?</p>
                                            </td>
                                            <td>
                                                <img src="assets/images/person_4.png" class="image-fit" alt="" style="width: 48px; height: 48px; border-radius: 50%;">
                                                <p>Speaker</p>
                                            </td>
                                        </tr>
                                        <tr style="background-color: #ff4815; color: white;">
                                            <td colspan="3" class="text-center">
                                                <b>LUNCH TIME</b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                               <b> <i class="far fa-clock text-orange"></i>  02:00 PM - 03:00 PM</b>
                                               <p class="text-orange">Marketing</p>
                                            </td>
                                            <td>
                                                <h5><b>HOW TO MAKETING FOR DESIGN ?</b></h5>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Numquam, fuga! Sapiente, ad? Tempore eius consequatur earum. Deleniti officiis nulla labore quibusdam reiciendis ex excepturi?</p>
                                            </td>
                                            <td>
                                                <img src="assets/images/person_3.png" class="image-fit" alt="" style="width: 48px; height: 48px; border-radius: 50%;">
                                                <p>Speaker</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div id="tab8" class="tab-pane fade">
                            <div class="text-center">
                                <h3 class="text-orange mb-xl-10 sub-heading">Day 03</h3>
                                <p>28th August 2024</p>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Time</th>
                                            <th>Topic</th>
                                            <th>Speaker</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                               <b> <i class="far fa-clock text-orange"></i>  09:00 AM - 10:00 AM</b>
                                               <p class="text-orange">Marketing</p>
                                            </td>
                                            <td>
                                                <h5><b>HOW TO MAKETING FOR DESIGN ?</b></h5>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Numquam, fuga! Sapiente, ad? Tempore eius consequatur earum. Deleniti officiis nulla labore quibusdam reiciendis ex excepturi?</p>
                                            </td>
                                            <td>
                                                <img src="assets/images/person_1.png" class="image-fit" alt="" style="width: 48px; height: 48px; border-radius: 50%;">
                                                <p>Speaker</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                               <b> <i class="far fa-clock text-orange"></i>  11:00 AM - 12:00 PM</b>
                                               <p class="text-orange">Marketing</p>
                                            </td>
                                            <td>
                                                <h5><b>HOW TO MAKE BEAUTIFUL DESIGN ?</b></h5>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Numquam, fuga! Sapiente, ad? Tempore eius consequatur earum. Deleniti officiis nulla labore quibusdam reiciendis ex excepturi?</p>
                                            </td>
                                            <td>
                                                <img src="assets/images/person_4.png" class="image-fit" alt="" style="width: 48px; height: 48px; border-radius: 50%;">
                                                <p>Speaker</p>
                                            </td>
                                        </tr>
                                        <tr style="background-color: #ff4815; color: white;">
                                            <td colspan="3" class="text-center">
                                                <b>LUNCH TIME</b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                               <b> <i class="far fa-clock text-orange"></i>  02:00 PM - 03:00 PM</b>
                                               <p class="text-orange">Marketing</p>
                                            </td>
                                            <td>
                                                <h5><b>HOW TO MAKETING FOR DESIGN ?</b></h5>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Numquam, fuga! Sapiente, ad? Tempore eius consequatur earum. Deleniti officiis nulla labore quibusdam reiciendis ex excepturi?</p>
                                            </td>
                                            <td>
                                                <img src="assets/images/person_3.png" class="image-fit" alt="" style="width: 48px; height: 48px; border-radius: 50%;">
                                                <p>Speaker</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div id="tab9" class="tab-pane fade">
                            <div class="text-center">
                                <h3 class="text-orange mb-xl-10 sub-heading">Day 04</h3>
                                <p>25th September 2024</p>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Time</th>
                                            <th>Topic</th>
                                            <th>Speaker</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                               <b> <i class="far fa-clock text-orange"></i>  09:00 AM - 10:00 AM</b>
                                               <p class="text-orange">Marketing</p>
                                            </td>
                                            <td>
                                                <h5><b>HOW TO MAKETING FOR DESIGN ?</b></h5>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Numquam, fuga! Sapiente, ad? Tempore eius consequatur earum. Deleniti officiis nulla labore quibusdam reiciendis ex excepturi?</p>
                                            </td>
                                            <td>
                                                <img src="assets/images/person_1.png" class="image-fit" alt="" style="width: 48px; height: 48px; border-radius: 50%;">
                                                <p>Speaker</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                               <b> <i class="far fa-clock text-orange"></i>  11:00 AM - 12:00 PM</b>
                                               <p class="text-orange">Marketing</p>
                                            </td>
                                            <td>
                                                <h5><b>HOW TO MAKE BEAUTIFUL DESIGN ?</b></h5>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Numquam, fuga! Sapiente, ad? Tempore eius consequatur earum. Deleniti officiis nulla labore quibusdam reiciendis ex excepturi?</p>
                                            </td>
                                            <td>
                                                <img src="assets/images/person_4.png" class="image-fit" alt="" style="width: 48px; height: 48px; border-radius: 50%;">
                                                <p>Speaker</p>
                                            </td>
                                        </tr>
                                        <tr style="background-color: #ff4815; color: white;">
                                            <td colspan="3" class="text-center">
                                                <b>LUNCH TIME</b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                               <b> <i class="far fa-clock text-orange"></i>  02:00 PM - 03:00 PM</b>
                                               <p class="text-orange">Marketing</p>
                                            </td>
                                            <td>
                                                <h5><b>HOW TO MAKETING FOR DESIGN ?</b></h5>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Numquam, fuga! Sapiente, ad? Tempore eius consequatur earum. Deleniti officiis nulla labore quibusdam reiciendis ex excepturi?</p>
                                            </td>
                                            <td>
                                                <img src="assets/images/person_3.png" class="image-fit" alt="" style="width: 48px; height: 48px; border-radius: 50%;">
                                                <p>Speaker</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div id="tab10" class="tab-pane fade">
                            <div class="text-center">
                                <h3 class="text-orange mb-xl-10 sub-heading">Day 05</h3>
                                <p>26th September 2024</p>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Time</th>
                                            <th>Topic</th>
                                            <th>Speaker</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                               <b> <i class="far fa-clock text-orange"></i>  09:00 AM - 10:00 AM</b>
                                               <p class="text-orange">Marketing</p>
                                            </td>
                                            <td>
                                                <h5><b>HOW TO MAKETING FOR DESIGN ?</b></h5>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Numquam, fuga! Sapiente, ad? Tempore eius consequatur earum. Deleniti officiis nulla labore quibusdam reiciendis ex excepturi?</p>
                                            </td>
                                            <td>
                                                <img src="assets/images/person_1.png" class="image-fit" alt="" style="width: 48px; height: 48px; border-radius: 50%;">
                                                <p>Speaker</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                               <b> <i class="far fa-clock text-orange"></i>  11:00 AM - 12:00 PM</b>
                                               <p class="text-orange">Marketing</p>
                                            </td>
                                            <td>
                                                <h5><b>HOW TO MAKE BEAUTIFUL DESIGN ?</b></h5>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Numquam, fuga! Sapiente, ad? Tempore eius consequatur earum. Deleniti officiis nulla labore quibusdam reiciendis ex excepturi?</p>
                                            </td>
                                            <td>
                                                <img src="assets/images/person_4.png" class="image-fit" alt="" style="width: 48px; height: 48px; border-radius: 50%;">
                                                <p>Speaker</p>
                                            </td>
                                        </tr>
                                        <tr style="background-color: #ff4815; color: white;">
                                            <td colspan="3" class="text-center">
                                                <b>LUNCH TIME</b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                               <b> <i class="far fa-clock text-orange"></i>  02:00 PM - 03:00 PM</b>
                                               <p class="text-orange">Marketing</p>
                                            </td>
                                            <td>
                                                <h5><b>HOW TO MAKETING FOR DESIGN ?</b></h5>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Numquam, fuga! Sapiente, ad? Tempore eius consequatur earum. Deleniti officiis nulla labore quibusdam reiciendis ex excepturi?</p>
                                            </td>
                                            <td>
                                                <img src="assets/images/person_3.png" class="image-fit" alt="" style="width: 48px; height: 48px; border-radius: 50%;">
                                                <p>Speaker</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="text-center">
                            <a href="assets/docs/Programme of the 2024 AGM of the CPSBs-NCF.- 26.08.2024.pdf" class="theme-btn btn-orange">Download Schedule <i class="fal fa-download"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Days Two Events-->

<!-- Start Days Events-->
<div id="eventSchedule" class="section-padding bg-light-white">
    <div class="container">
        <div class="section-header">
            <div class="section-heading">
                <h6 class="text-orange mb-xl-10 sub-heading"><span>Events Info</span></h6>
                <h3 class="text-blue fw-700 title">The days events<span class="text-orange">.</span></h3>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div>
                    <ul class="nav nav-tabs justify-content-center text-center" id="myTabs">
                        <li class="nav-item">
                            <a class="nav-link active" data-toggle="tab" href="#tab1">
                                <h4 class="fw-600 text-orange">Day 1</h4>
                                <p>22nd Sep 2023</p>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#tab2">
                                <h4 class="fw-600 text-orange">Day 2</h4>
                                <p>23rd Sep 2023</p>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#tab3">
                                <h4 class="fw-600 text-orange">Day 3</h4>
                                <p>24th Sep 2023</p>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#tab4">
                                <h4 class="fw-600 text-orange">Day 4</h4>
                                <p>25th Sep 2023</p>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#tab5">
                                <h4 class="fw-600 text-orange">Day 5</h4>
                                <p>26th Sep 2023</p>
                            </a>
                        </li>
                    </ul>
                    
                    <div class="tab-content p-lg-5" style="background-color: white;">
                        <div id="tab1" class="tab-pane fade show active">
                            <div class="text-center">
                                <h3 class="text-orange mb-xl-10 sub-heading">Day 01</h3>
                                <p>22nd September 2023</p>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Time</th>
                                            <th>Topic</th>
                                            <th>Speaker</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                               <b> <i class="far fa-clock text-orange"></i>  09:00 AM - 10:00 AM</b>
                                               <p class="text-orange">Marketing</p>
                                            </td>
                                            <td>
                                                <h5><b>HOW TO MAKETING FOR DESIGN ?</b></h5>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Numquam, fuga! Sapiente, ad? Tempore eius consequatur earum. Deleniti officiis nulla labore quibusdam reiciendis ex excepturi?</p>
                                            </td>
                                            <td>
                                                <img src="assets/images/person_1.png" class="image-fit" alt="" style="width: 48px; height: 48px; border-radius: 50%;">
                                                <p>Speaker</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                               <b> <i class="far fa-clock text-orange"></i>  11:00 AM - 12:00 PM</b>
                                               <p class="text-orange">Marketing</p>
                                            </td>
                                            <td>
                                                <h5><b>HOW TO MAKE BEAUTIFUL DESIGN ?</b></h5>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Numquam, fuga! Sapiente, ad? Tempore eius consequatur earum. Deleniti officiis nulla labore quibusdam reiciendis ex excepturi?</p>
                                            </td>
                                            <td>
                                                <img src="assets/images/person_4.png" class="image-fit" alt="" style="width: 48px; height: 48px; border-radius: 50%;">
                                                <p>Speaker</p>
                                            </td>
                                        </tr>
                                        <tr style="background-color: #ff4815; color: white;">
                                            <td colspan="3" class="text-center">
                                                <b>LUNCH TIME</b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                               <b> <i class="far fa-clock text-orange"></i>  02:00 PM - 03:00 PM</b>
                                               <p class="text-orange">Marketing</p>
                                            </td>
                                            <td>
                                                <h5><b>HOW TO MAKETING FOR DESIGN ?</b></h5>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Numquam, fuga! Sapiente, ad? Tempore eius consequatur earum. Deleniti officiis nulla labore quibusdam reiciendis ex excepturi?</p>
                                            </td>
                                            <td>
                                                <img src="assets/images/person_3.png" class="image-fit" alt="" style="width: 48px; height: 48px; border-radius: 50%;">
                                                <p>Speaker</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            
                        </div>
                        <div id="tab2" class="tab-pane fade">
                            <div class="text-center">
                                <h3 class="text-orange mb-xl-10 sub-heading">Day 02</h3>
                                <p>23rd September 2023</p>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Time</th>
                                            <th>Topic</th>
                                            <th>Speaker</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                               <b> <i class="far fa-clock text-orange"></i>  09:00 AM - 10:00 AM</b>
                                               <p class="text-orange">Marketing</p>
                                            </td>
                                            <td>
                                                <h5><b>HOW TO MAKETING FOR DESIGN ?</b></h5>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Numquam, fuga! Sapiente, ad? Tempore eius consequatur earum. Deleniti officiis nulla labore quibusdam reiciendis ex excepturi?</p>
                                            </td>
                                            <td>
                                                <img src="assets/images/person_1.png" class="image-fit" alt="" style="width: 48px; height: 48px; border-radius: 50%;">
                                                <p>Speaker</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                               <b> <i class="far fa-clock text-orange"></i>  11:00 AM - 12:00 PM</b>
                                               <p class="text-orange">Marketing</p>
                                            </td>
                                            <td>
                                                <h5><b>HOW TO MAKE BEAUTIFUL DESIGN ?</b></h5>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Numquam, fuga! Sapiente, ad? Tempore eius consequatur earum. Deleniti officiis nulla labore quibusdam reiciendis ex excepturi?</p>
                                            </td>
                                            <td>
                                                <img src="assets/images/person_4.png" class="image-fit" alt="" style="width: 48px; height: 48px; border-radius: 50%;">
                                                <p>Speaker</p>
                                            </td>
                                        </tr>
                                        <tr style="background-color: #ff4815; color: white;">
                                            <td colspan="3" class="text-center">
                                                <b>LUNCH TIME</b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                               <b> <i class="far fa-clock text-orange"></i>  02:00 PM - 03:00 PM</b>
                                               <p class="text-orange">Marketing</p>
                                            </td>
                                            <td>
                                                <h5><b>HOW TO MAKETING FOR DESIGN ?</b></h5>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Numquam, fuga! Sapiente, ad? Tempore eius consequatur earum. Deleniti officiis nulla labore quibusdam reiciendis ex excepturi?</p>
                                            </td>
                                            <td>
                                                <img src="assets/images/person_3.png" class="image-fit" alt="" style="width: 48px; height: 48px; border-radius: 50%;">
                                                <p>Speaker</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div id="tab3" class="tab-pane fade">
                            <div class="text-center">
                                <h3 class="text-orange mb-xl-10 sub-heading">Day 03</h3>
                                <p>24th September 2023</p>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Time</th>
                                            <th>Topic</th>
                                            <th>Speaker</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                               <b> <i class="far fa-clock text-orange"></i>  09:00 AM - 10:00 AM</b>
                                               <p class="text-orange">Marketing</p>
                                            </td>
                                            <td>
                                                <h5><b>HOW TO MAKETING FOR DESIGN ?</b></h5>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Numquam, fuga! Sapiente, ad? Tempore eius consequatur earum. Deleniti officiis nulla labore quibusdam reiciendis ex excepturi?</p>
                                            </td>
                                            <td>
                                                <img src="assets/images/person_1.png" class="image-fit" alt="" style="width: 48px; height: 48px; border-radius: 50%;">
                                                <p>Speaker</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                               <b> <i class="far fa-clock text-orange"></i>  11:00 AM - 12:00 PM</b>
                                               <p class="text-orange">Marketing</p>
                                            </td>
                                            <td>
                                                <h5><b>HOW TO MAKE BEAUTIFUL DESIGN ?</b></h5>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Numquam, fuga! Sapiente, ad? Tempore eius consequatur earum. Deleniti officiis nulla labore quibusdam reiciendis ex excepturi?</p>
                                            </td>
                                            <td>
                                                <img src="assets/images/person_4.png" class="image-fit" alt="" style="width: 48px; height: 48px; border-radius: 50%;">
                                                <p>Speaker</p>
                                            </td>
                                        </tr>
                                        <tr style="background-color: #ff4815; color: white;">
                                            <td colspan="3" class="text-center">
                                                <b>LUNCH TIME</b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                               <b> <i class="far fa-clock text-orange"></i>  02:00 PM - 03:00 PM</b>
                                               <p class="text-orange">Marketing</p>
                                            </td>
                                            <td>
                                                <h5><b>HOW TO MAKETING FOR DESIGN ?</b></h5>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Numquam, fuga! Sapiente, ad? Tempore eius consequatur earum. Deleniti officiis nulla labore quibusdam reiciendis ex excepturi?</p>
                                            </td>
                                            <td>
                                                <img src="assets/images/person_3.png" class="image-fit" alt="" style="width: 48px; height: 48px; border-radius: 50%;">
                                                <p>Speaker</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div id="tab4" class="tab-pane fade">
                            <div class="text-center">
                                <h3 class="text-orange mb-xl-10 sub-heading">Day 04</h3>
                                <p>25th September 2023</p>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Time</th>
                                            <th>Topic</th>
                                            <th>Speaker</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                               <b> <i class="far fa-clock text-orange"></i>  09:00 AM - 10:00 AM</b>
                                               <p class="text-orange">Marketing</p>
                                            </td>
                                            <td>
                                                <h5><b>HOW TO MAKETING FOR DESIGN ?</b></h5>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Numquam, fuga! Sapiente, ad? Tempore eius consequatur earum. Deleniti officiis nulla labore quibusdam reiciendis ex excepturi?</p>
                                            </td>
                                            <td>
                                                <img src="assets/images/person_1.png" class="image-fit" alt="" style="width: 48px; height: 48px; border-radius: 50%;">
                                                <p>Speaker</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                               <b> <i class="far fa-clock text-orange"></i>  11:00 AM - 12:00 PM</b>
                                               <p class="text-orange">Marketing</p>
                                            </td>
                                            <td>
                                                <h5><b>HOW TO MAKE BEAUTIFUL DESIGN ?</b></h5>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Numquam, fuga! Sapiente, ad? Tempore eius consequatur earum. Deleniti officiis nulla labore quibusdam reiciendis ex excepturi?</p>
                                            </td>
                                            <td>
                                                <img src="assets/images/person_4.png" class="image-fit" alt="" style="width: 48px; height: 48px; border-radius: 50%;">
                                                <p>Speaker</p>
                                            </td>
                                        </tr>
                                        <tr style="background-color: #ff4815; color: white;">
                                            <td colspan="3" class="text-center">
                                                <b>LUNCH TIME</b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                               <b> <i class="far fa-clock text-orange"></i>  02:00 PM - 03:00 PM</b>
                                               <p class="text-orange">Marketing</p>
                                            </td>
                                            <td>
                                                <h5><b>HOW TO MAKETING FOR DESIGN ?</b></h5>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Numquam, fuga! Sapiente, ad? Tempore eius consequatur earum. Deleniti officiis nulla labore quibusdam reiciendis ex excepturi?</p>
                                            </td>
                                            <td>
                                                <img src="assets/images/person_3.png" class="image-fit" alt="" style="width: 48px; height: 48px; border-radius: 50%;">
                                                <p>Speaker</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div id="tab5" class="tab-pane fade">
                            <div class="text-center">
                                <h3 class="text-orange mb-xl-10 sub-heading">Day 05</h3>
                                <p>26th September 2023</p>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Time</th>
                                            <th>Topic</th>
                                            <th>Speaker</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                               <b> <i class="far fa-clock text-orange"></i>  09:00 AM - 10:00 AM</b>
                                               <p class="text-orange">Marketing</p>
                                            </td>
                                            <td>
                                                <h5><b>HOW TO MAKETING FOR DESIGN ?</b></h5>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Numquam, fuga! Sapiente, ad? Tempore eius consequatur earum. Deleniti officiis nulla labore quibusdam reiciendis ex excepturi?</p>
                                            </td>
                                            <td>
                                                <img src="assets/images/person_1.png" class="image-fit" alt="" style="width: 48px; height: 48px; border-radius: 50%;">
                                                <p>Speaker</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                               <b> <i class="far fa-clock text-orange"></i>  11:00 AM - 12:00 PM</b>
                                               <p class="text-orange">Marketing</p>
                                            </td>
                                            <td>
                                                <h5><b>HOW TO MAKE BEAUTIFUL DESIGN ?</b></h5>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Numquam, fuga! Sapiente, ad? Tempore eius consequatur earum. Deleniti officiis nulla labore quibusdam reiciendis ex excepturi?</p>
                                            </td>
                                            <td>
                                                <img src="assets/images/person_4.png" class="image-fit" alt="" style="width: 48px; height: 48px; border-radius: 50%;">
                                                <p>Speaker</p>
                                            </td>
                                        </tr>
                                        <tr style="background-color: #ff4815; color: white;">
                                            <td colspan="3" class="text-center">
                                                <b>LUNCH TIME</b>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                               <b> <i class="far fa-clock text-orange"></i>  02:00 PM - 03:00 PM</b>
                                               <p class="text-orange">Marketing</p>
                                            </td>
                                            <td>
                                                <h5><b>HOW TO MAKETING FOR DESIGN ?</b></h5>
                                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Numquam, fuga! Sapiente, ad? Tempore eius consequatur earum. Deleniti officiis nulla labore quibusdam reiciendis ex excepturi?</p>
                                            </td>
                                            <td>
                                                <img src="assets/images/person_3.png" class="image-fit" alt="" style="width: 48px; height: 48px; border-radius: 50%;">
                                                <p>Speaker</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="text-center">
                            <a href="#" class="theme-btn btn-orange">Download Schedule <i class="fal fa-download"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Days Events-->

<!-- Start Faqs -->
<section class="section-padding-top faqs-sec">
    <div class="container">
        <div class="row align-items-end">
            <div class="col-lg-12">
                <div class="left-side">
                    <div class="section-header left-heading">
                        <div class="section-heading">
                            <h6 class="text-orange mb-xl-10 sub-heading"><span>FAQ</span></h6>
                            <h3 class="fw-700 title">You have to know before start everything<span class="text-orange">.</span></h3>
                        </div>
                    </div>
                    <div id="accordion" class="custom-faqs">
                        <div class="card">
                            <div class="card-header">
                                <a class="collapsed card-link" data-toggle="collapse" href="#collapseOne">
                                    What is the The County Public Service Boards National Consultative Forum?
                                    <span>
                                        <i class="fal fa-arrow-down"></i>
                                    </span>
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse" data-parent="#accordion">
                                <div class="card-body">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco lab oris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header">
                                <a class="card-link" data-toggle="collapse" href="#collapseTwo">
                                    What does The County Public Service Boards National Consultative Forum do?
                                    <span>
                                        <i class="fal fa-arrow-down"></i>
                                    </span>
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse show" data-parent="#accordion">
                                <div class="card-body">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco lab oris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header">
                                <a class="collapsed card-link" data-toggle="collapse" href="#collapseThree">
                                    Who are the members of The County Public Service Boards National Consultative Forum do?
                                    <span>
                                        <i class="fal fa-arrow-down"></i>
                                    </span>
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" data-parent="#accordion">
                                <div class="card-body">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco lab oris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header">
                                <a class="collapsed card-link" data-toggle="collapse" href="#collapseFour">
                                    When does The County Public Service Boards National Consultative Forum happen?
                                    <span>
                                        <i class="fal fa-arrow-down"></i>
                                    </span>
                                </a>
                            </div>
                            <div id="collapseFour" class="collapse" data-parent="#accordion">
                                <div class="card-body">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco lab oris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-6">
                <div class="faq-man p-relative">
                    <img src="assets/images/faq-man.png" class="image-fit" alt="">
                </div>
            </div> -->
        </div>
    </div>
</section>
<!-- End Faqs -->

<!-- Start Past Events -->
<section class="section-padding">
    <div class="container">
        <div class="section-header">
            <div class="section-heading">
                <h6 class="text-orange mb-xl-10 sub-heading"><span>Past Events</span></h6>
                <h3 class="text-blue fw-700 title">View our past events & connect with us<span class="text-orange">.</span></h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="blog-slider row">
                    <div class="col-lg-12 slide-item">
                        <div class="blog-style-1">
                            <div class="post-img animate-img">
                                <a routerLink='/news-detail'>
                                    <img src="assets/images/past_events_1.jpg" class="image-fit" alt="">
                                </a>
                            </div>
                            <div class="post-date">
                                <span class="date">24</span>
                                <span class="month">June</span>
                            </div>
                            <div class="post-content">
                                <div class="post-category">
                                    <p class="double-lines text-orange fw-600 mb-1"><span><a href="#" class="text-orange">Finance</a></span></p>
                                </div>
                                <h2 class="post-title"><a routerLink='/news-detail'>Improving lives with tech HSE lighthouse project</a></h2>
                                <ul class="custom-flex post-meta">
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-user text-orange"></i>
                                            Rosalia D.
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-calendar-alt text-orange"></i>
                                            24th June 2020
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 slide-item">
                        <div class="blog-style-1">
                            <div class="post-img animate-img">
                                <a routerLink='#'>
                                    <img src="assets/images/past_events_4.jpg" class="image-fit" alt="">
                                </a>
                            </div>
                            <div class="post-date">
                                <span class="date">18</span>
                                <span class="month">June</span>
                            </div>
                            <div class="post-content">
                                <div class="post-category">
                                    <p class="double-lines text-orange fw-600 mb-1"><span><a href="#" class="text-orange">Business</a></span></p>
                                </div>
                                <h2 class="post-title"><a routerLink='#'>Improving lives with tech HSE lighthouse project</a></h2>
                                <ul class="custom-flex post-meta">
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-user text-orange"></i>
                                            Rosalia D.
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-calendar-alt text-orange"></i>
                                            18th June 2020
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 slide-item">
                        <div class="blog-style-1">
                            <div class="post-img animate-img">
                                <a routerLink='#'>
                                    <img src="assets/images/past_events_3.jpg" class="image-fit" alt="">
                                </a>
                            </div>
                            <div class="post-date">
                                <span class="date">04</span>
                                <span class="month">June</span>
                            </div>
                            <div class="post-content">
                                <div class="post-category">
                                    <p class="double-lines text-orange fw-600 mb-1"><span><a href="#" class="text-orange">Consultancy</a></span></p>
                                </div>
                                <h2 class="post-title"><a routerLink='/news-detail'>Improving lives with tech HSE lighthouse project</a></h2>
                                <ul class="custom-flex post-meta">
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-user text-orange"></i>
                                            Rosalia D.
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-calendar-alt text-orange"></i>
                                            04th June 2020
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Past Events --> 
  
  <!-- Register Event Modal -->
  <div class="modal fade" id="registerEvent" tabindex="-1" role="dialog" aria-labelledby="registerEventTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content bg-dark-blue text-white">
        <div class="modal-header">
          <h5 class="modal-title text-white" id="registerEventLongTitle">Register for Annual Convention Forum Event</h5>
          <button type="button" class="close" data-dismiss="modal" data-bs-theme="dark" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form action="">
            <div class="form-group">
                <label for="" class="text-white">Full Name</label>
                <input type="text" name="" id="" placeholder="Enter your full name" class="form-control">
            </div>
            <div class="form-group">
                <label for="" class="text-white">Email Address</label>
                <input type="email" name="" id="" placeholder="Enter your email address" class="form-control">
            </div>
            <div class="form-group" style="display: inline-flex; gap: 1em;">
                <input type="checkbox" name="" id="" class="form-control" style="width: 24px; height: 24px;">
                <label for="" class="text-orange">I will be attending the Annual Convention Forum for The County Public Service Boards</label>
            </div>
            <div class="form-group">
                <input type="submit" value="Register Event" class="theme-btn btn-orange">
            </div>
          </form>
        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="theme-btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="theme-btn btn-orange">Save changes</button>
        </div> -->
      </div>
    </div>
  </div>

<app-footer-one></app-footer-one>