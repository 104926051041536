<app-header-one></app-header-one>
<!-- Start Subheader -->
<div class="normal-bg subheader" style="background-image: url('assets/images/subheader.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="subheader-content">
                    <h1 class="page-title text-custom-white fw-600">News Grid</h1>
                    <ul class="custom-flex breadcrumb">
                        <li><a routerLink='/'>Home</a></li>
                        <li>News</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
<!-- Start News -->
<section class="section-padding">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="blog-style-1 mb-xl-30">
                    <div class="post-img animate-img">
                        <a routerLink='/news-detail'>
                            <img src="assets/images/blog-1.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="post-date">
                        <span class="date">24</span>
                        <span class="month">June</span>
                    </div>
                    <div class="post-content">
                        <div class="post-category">
                            <p class="double-lines text-orange fw-600 mb-1"><span><a href="#" class="text-orange">Finance</a></span></p>
                        </div>
                        <h2 class="post-title"><a routerLink='/news-detail'>Improving lives with tech HSE lighthouse project</a></h2>
                        <ul class="custom-flex post-meta">
                            <li>
                                <a href="#">
                                    <i class="fal fa-user text-orange"></i>
                                    Rosalia D.
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fal fa-calendar-alt text-orange"></i>
                                    24th June 2020
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="blog-style-1 mb-xl-30">
                    <div class="post-img animate-img">
                        <a routerLink='/news-detail'>
                            <img src="assets/images/blog-2.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="post-date">
                        <span class="date">18</span>
                        <span class="month">June</span>
                    </div>
                    <div class="post-content">
                        <div class="post-category">
                            <p class="double-lines text-orange fw-600 mb-1"><span><a href="#" class="text-orange">Business</a></span></p>
                        </div>
                        <h2 class="post-title"><a routerLink='/news-detail'>With a large collection of elements and shortcodes</a></h2>
                        <ul class="custom-flex post-meta">
                            <li>
                                <a href="#">
                                    <i class="fal fa-user text-orange"></i>
                                    Rosalia D.
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fal fa-calendar-alt text-orange"></i>
                                    18th June 2020
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="blog-style-1 mb-xl-30">
                    <div class="post-img animate-img">
                        <a routerLink='/news-detail'>
                            <img src="assets/images/blog-3.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="post-date">
                        <span class="date">04</span>
                        <span class="month">June</span>
                    </div>
                    <div class="post-content">
                        <div class="post-category">
                            <p class="double-lines text-orange fw-600 mb-1"><span><a href="#" class="text-orange">Consultancy</a></span></p>
                        </div>
                        <h2 class="post-title"><a routerLink='/news-detail'>To choose from you can create any UX/UX layout.</a></h2>
                        <ul class="custom-flex post-meta">
                            <li>
                                <a href="#">
                                    <i class="fal fa-user text-orange"></i>
                                    Rosalia D.
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fal fa-calendar-alt text-orange"></i>
                                    04th June 2020
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="blog-style-1 mb-xl-30">
                    <div class="post-img animate-img">
                        <a routerLink='/news-detail'>
                            <img src="assets/images/blog-4.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="post-date">
                        <span class="date">24</span>
                        <span class="month">June</span>
                    </div>
                    <div class="post-content">
                        <div class="post-category">
                            <p class="double-lines text-orange fw-600 mb-1"><span><a href="#" class="text-orange">Finance</a></span></p>
                        </div>
                        <h2 class="post-title"><a routerLink='/news-detail'>Improving lives with tech HSE lighthouse project</a></h2>
                        <ul class="custom-flex post-meta">
                            <li>
                                <a href="#">
                                    <i class="fal fa-user text-orange"></i>
                                    Rosalia D.
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fal fa-calendar-alt text-orange"></i>
                                    24th June 2020
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="blog-style-1 mb-xl-30">
                    <div class="post-img animate-img">
                        <a routerLink='/news-detail'>
                            <img src="assets/images/blog-5.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="post-date">
                        <span class="date">18</span>
                        <span class="month">June</span>
                    </div>
                    <div class="post-content">
                        <div class="post-category">
                            <p class="double-lines text-orange fw-600 mb-1"><span><a href="#" class="text-orange">Business</a></span></p>
                        </div>
                        <h2 class="post-title"><a routerLink='/news-detail'>With a large collection of elements and shortcodes</a></h2>
                        <ul class="custom-flex post-meta">
                            <li>
                                <a href="#">
                                    <i class="fal fa-user text-orange"></i>
                                    Rosalia D.
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fal fa-calendar-alt text-orange"></i>
                                    18th June 2020
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="blog-style-1 mb-xl-30">
                    <div class="post-img animate-img">
                        <a routerLink='/news-detail'>
                            <img src="assets/images/blog-6.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="post-date">
                        <span class="date">04</span>
                        <span class="month">June</span>
                    </div>
                    <div class="post-content">
                        <div class="post-category">
                            <p class="double-lines text-orange fw-600 mb-1"><span><a href="#" class="text-orange">Consultancy</a></span></p>
                        </div>
                        <h2 class="post-title"><a routerLink='/news-detail'>To choose from you can create any UX/UX layout.</a></h2>
                        <ul class="custom-flex post-meta">
                            <li>
                                <a href="#">
                                    <i class="fal fa-user text-orange"></i>
                                    Rosalia D.
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fal fa-calendar-alt text-orange"></i>
                                    04th June 2020
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="blog-style-1 mb-xl-30">
                    <div class="post-img animate-img">
                        <a routerLink='/news-detail'>
                            <img src="assets/images/blog-7.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="post-date">
                        <span class="date">24</span>
                        <span class="month">June</span>
                    </div>
                    <div class="post-content">
                        <div class="post-category">
                            <p class="double-lines text-orange fw-600 mb-1"><span><a href="#" class="text-orange">Finance</a></span></p>
                        </div>
                        <h2 class="post-title"><a routerLink='/news-detail'>Improving lives with tech HSE lighthouse project</a></h2>
                        <ul class="custom-flex post-meta">
                            <li>
                                <a href="#">
                                    <i class="fal fa-user text-orange"></i>
                                    Rosalia D.
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fal fa-calendar-alt text-orange"></i>
                                    24th June 2020
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="blog-style-1 mb-xl-30">
                    <div class="post-img animate-img">
                        <a routerLink='/news-detail'>
                            <img src="assets/images/blog-8.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="post-date">
                        <span class="date">18</span>
                        <span class="month">June</span>
                    </div>
                    <div class="post-content">
                        <div class="post-category">
                            <p class="double-lines text-orange fw-600 mb-1"><span><a href="#" class="text-orange">Business</a></span></p>
                        </div>
                        <h2 class="post-title"><a routerLink='/news-detail'>With a large collection of elements and shortcodes</a></h2>
                        <ul class="custom-flex post-meta">
                            <li>
                                <a href="#">
                                    <i class="fal fa-user text-orange"></i>
                                    Rosalia D.
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fal fa-calendar-alt text-orange"></i>
                                    18th June 2020
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="blog-style-1 mb-xl-30">
                    <div class="post-img animate-img">
                        <a routerLink='/news-detail'>
                            <img src="assets/images/blog-9.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="post-date">
                        <span class="date">04</span>
                        <span class="month">June</span>
                    </div>
                    <div class="post-content">
                        <div class="post-category">
                            <p class="double-lines text-orange fw-600 mb-1"><span><a href="#" class="text-orange">Consultancy</a></span></p>
                        </div>
                        <h2 class="post-title"><a routerLink='/news-detail'>To choose from you can create any UX/UX layout.</a></h2>
                        <ul class="custom-flex post-meta">
                            <li>
                                <a href="#">
                                    <i class="fal fa-user text-orange"></i>
                                    Rosalia D.
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fal fa-calendar-alt text-orange"></i>
                                    04th June 2020
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="blog-style-1 mb-xl-30">
                    <div class="post-img animate-img">
                        <a routerLink='/news-detail'>
                            <img src="assets/images/blog-10.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="post-date">
                        <span class="date">24</span>
                        <span class="month">June</span>
                    </div>
                    <div class="post-content">
                        <div class="post-category">
                            <p class="double-lines text-orange fw-600 mb-1"><span><a href="#" class="text-orange">Finance</a></span></p>
                        </div>
                        <h2 class="post-title"><a routerLink='/news-detail'>Improving lives with tech HSE lighthouse project</a></h2>
                        <ul class="custom-flex post-meta">
                            <li>
                                <a href="#">
                                    <i class="fal fa-user text-orange"></i>
                                    Rosalia D.
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fal fa-calendar-alt text-orange"></i>
                                    24th June 2020
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="blog-style-1 mb-xl-30">
                    <div class="post-img animate-img">
                        <a routerLink='/news-detail'>
                            <img src="assets/images/blog-11.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="post-date">
                        <span class="date">18</span>
                        <span class="month">June</span>
                    </div>
                    <div class="post-content">
                        <div class="post-category">
                            <p class="double-lines text-orange fw-600 mb-1"><span><a href="#" class="text-orange">Business</a></span></p>
                        </div>
                        <h2 class="post-title"><a routerLink='/news-detail'>With a large collection of elements and shortcodes</a></h2>
                        <ul class="custom-flex post-meta">
                            <li>
                                <a href="#">
                                    <i class="fal fa-user text-orange"></i>
                                    Rosalia D.
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fal fa-calendar-alt text-orange"></i>
                                    18th June 2020
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="blog-style-1 mb-xl-30">
                    <div class="post-img animate-img">
                        <a routerLink='/news-detail'>
                            <img src="assets/images/blog-12.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="post-date">
                        <span class="date">04</span>
                        <span class="month">June</span>
                    </div>
                    <div class="post-content">
                        <div class="post-category">
                            <p class="double-lines text-orange fw-600 mb-1"><span><a href="#" class="text-orange">Consultancy</a></span></p>
                        </div>
                        <h2 class="post-title"><a routerLink='/news-detail'>To choose from you can create any UX/UX layout.</a></h2>
                        <ul class="custom-flex post-meta">
                            <li>
                                <a href="#">
                                    <i class="fal fa-user text-orange"></i>
                                    Rosalia D.
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fal fa-calendar-alt text-orange"></i>
                                    04th June 2020
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <ul class="custom pagination justify-content-center mt-4">
                    <li class="page-item"><a class="page-link" href="#"><i class="far fa-angle-double-left"></i></a></li>
                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                    <li class="page-item active"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item"><a class="page-link" href="#">...</a></li>
                    <li class="page-item"><a class="page-link" href="#">10</a></li>
                    <li class="page-item"><a class="page-link" href="#"><i class="far fa-angle-double-right"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</section>
<!-- End News --> 
<app-footer-one></app-footer-one>