<div class="container mt-5 text-white">
    <div class="row">
      <div class="col">
        <div class="text-center">
          <span class="counter-data">{{ days }}</span>
          <p class="counter-text">Days</p>
        </div>
      </div>
      <div class="col">
        <div class="text-center">
          <span class="counter-data">{{ hours }}</span>
          <p class="counter-text">Hours</p>
        </div>
      </div>
      <div class="col">
        <div class="text-center">
          <span class="counter-data">{{ minutes }}</span>
          <p class="counter-text">Minutes</p>
        </div>
      </div>
      <div class="col">
        <div class="text-center">
          <span class="counter-data">{{ seconds }}</span>
          <p class="counter-text">Seconds</p>
        </div>
      </div>
    </div>
  </div>
  