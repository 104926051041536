<app-header-one></app-header-one>
<!-- Start Subheader -->
<div class="normal-bg subheader" style="background-image: url('assets/images/subheader.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="subheader-content">
                    <h1 class="page-title text-custom-white fw-600">News Feeds</h1>
                    <ul class="custom-flex breadcrumb">
                        <li><a routerLink='/'>Home</a></li>
                        <li>News</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
<!-- Start News -->
<section class="section-padding bg-light-white">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <article class="post-standard mb-xl-30">
                    <div class="post-image animate-img">
                        <a href="#">
                            <img src="assets/images/blog-standard-1.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="post-content">
                        <ul class="custom-flex post-category">
                            <li>
                                <a href="#">Business</a>
                            </li>
                        </ul>
                        <h2 class="post-title">
                            <a routerLink='/news-detail'>Lorem ipsum dolor sit amet, consecte cing elit, sed do eiusmod tempor.</a>
                        </h2>
                        <ul class="custom-flex post-meta">
                            <li>
                                <a href="#">
                                    <i class="fal fa-eye"></i>
                                    232 Views
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fal fa-comments"></i>
                                    35 Comments
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fal fa-calendar-alt"></i>
                                    24th March 2020
                                </a>
                            </li>
                        </ul>
                        <p class="post-description">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.
                        </p>
                        <div class="post-footer">
                            <div class="post-author">
                                <div class="author-image">
                                    <img src="assets/images/author-1.png" class="image-fit rounded-circle" alt="">
                                </div>
                                <a href="#">by Hetmayer</a>
                            </div>
                            <div class="post-link">
                                <a routerLink='/news-detail'>
                                    <i class="fal fa-arrow-right"></i>
                                    Read More
                                </a>
                            </div>
                        </div>
                    </div>
                </article>
                <article class="post-standard mb-xl-30">
                    <div class="post-image animate-img">
                        <a href="#">
                            <img src="assets/images/blog-standard-2.jpg" class="image-fit" alt="">
                        </a>
                        <div class="transform-center text-center">
                            <a href="#" class="post-video" data-toggle="modal" data-target="#videomodal">
                                <i class="fas fa-play"></i>
                            </a>
                        </div>
                    </div>
                    <div class="post-content">
                        <ul class="custom-flex post-category">
                            <li>
                                <a href="#">Business</a>
                            </li>
                        </ul>
                        <h2 class="post-title">
                            <a routerLink='/news-detail'>Adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore.</a>
                        </h2>
                        <ul class="custom-flex post-meta">
                            <li>
                                <a href="#">
                                    <i class="fal fa-eye"></i>
                                    232 Views
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fal fa-comments"></i>
                                    35 Comments
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fal fa-calendar-alt"></i>
                                    24th March 2020
                                </a>
                            </li>
                        </ul>
                        <p class="post-description">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.
                        </p>
                        <div class="post-footer">
                            <div class="post-author">
                                <div class="author-image">
                                    <img src="assets/images/author-1.png" class="image-fit rounded-circle" alt="">
                                </div>
                                <a href="#">by Hetmayer</a>
                            </div>
                            <div class="post-link">
                                <a routerLink='/news-detail'>
                                    <i class="fal fa-arrow-right"></i>
                                    Read More
                                </a>
                            </div>
                        </div>
                    </div>
                </article>
                <article class="post-standard mb-xl-30">
                    <div class="blog-post-slider blog-arrow">
                        <div class="post-image animate-img">
                            <a href="#">
                                <img src="assets/images/blog-standard-1.jpg" class="image-fit" alt="">
                            </a>
                        </div>
                        <div class="post-image animate-img">
                            <a href="#">
                                <img src="assets/images/blog-standard-2.jpg" class="image-fit" alt="">
                            </a>
                        </div>
                        <div class="post-image animate-img">
                            <a href="#">
                                <img src="assets/images/blog-standard-3.jpg" class="image-fit" alt="">
                            </a>
                        </div>
                        <div class="post-image animate-img">
                            <a href="#">
                                <img src="assets/images/blog-standard-1.jpg" class="image-fit" alt="">
                            </a>
                        </div>
                        <div class="post-image animate-img">
                            <a href="#">
                                <img src="assets/images/blog-standard-2.jpg" class="image-fit" alt="">
                            </a>
                        </div>
                        <div class="post-image animate-img">
                            <a href="#">
                                <img src="assets/images/blog-standard-3.jpg" class="image-fit" alt="">
                            </a>
                        </div>
                    </div>
                    <div class="post-content">
                        <ul class="custom-flex post-category">
                            <li>
                                <a href="#">Business</a>
                            </li>
                        </ul>
                        <h2 class="post-title">
                            <a routerLink='/news-detail'>Magna aliqua. Ut enim ad minim veniam, nostrud exercitation ullamco</a>
                        </h2>
                        <ul class="custom-flex post-meta">
                            <li>
                                <a href="#">
                                    <i class="fal fa-eye"></i>
                                    232 Views
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fal fa-comments"></i>
                                    35 Comments
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fal fa-calendar-alt"></i>
                                    24th March 2020
                                </a>
                            </li>
                        </ul>
                        <p class="post-description">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.
                        </p>
                        <div class="post-footer">
                            <div class="post-author">
                                <div class="author-image">
                                    <img src="assets/images/author-1.png" class="image-fit rounded-circle" alt="">
                                </div>
                                <a href="#">by Hetmayer</a>
                            </div>
                            <div class="post-link">
                                <a routerLink='/news-detail'>
                                    <i class="fal fa-arrow-right"></i>
                                    Read More
                                </a>
                            </div>
                        </div>
                    </div>
                </article>
                <article class="post-standard mb-xl-30">
                    <div class="post-image">
                        <a href="#">
                            <img src="assets/images/blog-audio.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="post-content">
                        <ul class="custom-flex post-category">
                            <li>
                                <a href="#">Business</a>
                            </li>
                        </ul>
                        <h2 class="post-title">
                            <a routerLink='/news-detail'>Laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor.</a>
                        </h2>
                        <ul class="custom-flex post-meta">
                            <li>
                                <a href="#">
                                    <i class="fal fa-eye"></i>
                                    232 Views
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fal fa-comments"></i>
                                    35 Comments
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fal fa-calendar-alt"></i>
                                    24th March 2020
                                </a>
                            </li>
                        </ul>
                        <p class="post-description">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.
                        </p>
                        <div class="post-footer">
                            <div class="post-author">
                                <div class="author-image">
                                    <img src="assets/images/author-1.png" class="image-fit rounded-circle" alt="">
                                </div>
                                <a href="#">by Hetmayer</a>
                            </div>
                            <div class="post-link">
                                <a routerLink='/news-detail'>
                                    <i class="fal fa-arrow-right"></i>
                                    Read More
                                </a>
                            </div>
                        </div>
                    </div>
                </article>
                <article class="post-standard mb-xl-30">
                    <div class="post-content">
                        <ul class="custom-flex post-category">
                            <li>
                                <a href="#">Business</a>
                            </li>
                        </ul>
                        <h2 class="post-title">
                            <a routerLink='/news-detail'>In reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</a>
                        </h2>
                        <ul class="custom-flex post-meta">
                            <li>
                                <a href="#">
                                    <i class="fal fa-eye"></i>
                                    232 Views
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fal fa-comments"></i>
                                    35 Comments
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fal fa-calendar-alt"></i>
                                    24th March 2020
                                </a>
                            </li>
                        </ul>
                        <p class="post-description">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.
                        </p>
                        <div class="post-footer">
                            <div class="post-author">
                                <div class="author-image">
                                    <img src="assets/images/author-1.png" class="image-fit rounded-circle" alt="">
                                </div>
                                <a href="#">by Hetmayer</a>
                            </div>
                            <div class="post-link">
                                <a routerLink='/news-detail'>
                                    <i class="fal fa-arrow-right"></i>
                                    Read More
                                </a>
                            </div>
                        </div>
                    </div>
                </article>
                <div class="post-blockquote mb-xl-30 normal-bg" style="background-image: url('assets/images/blog-pattern.png');">
                    <div class="icon">
                        <i class="fas fa-quote-left"></i>
                    </div>
                    <div class="blockquote-text">
                        <h2 class="post-title">
                            <a href="#" class="text-white">Excepteur sint occaecat cupida tat non proident, sunt in.</a>
                        </h2>
                        <ul class="custom-flex post-meta">
                            <li>
                                <a href="#" class="text-custom-white">
                                    <i class="fal fa-eye"></i>
                                    232 Views
                                </a>
                            </li>
                            <li>
                                <a href="#" class="text-custom-white">
                                    <i class="fal fa-comments"></i>
                                    35 Comments
                                </a>
                            </li>
                            <li>
                                <a href="#" class="text-custom-white">
                                    <i class="fal fa-calendar-alt"></i>
                                    24th March 2020
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <article class="post-standard mb-xl-30 normal-bg" style="background-image: url('assets/images/blog-standard-4.jpg');">
                    <div class="post-content">
                        <ul class="custom-flex post-category">
                            <li>
                                <a href="#">Business</a>
                            </li>
                        </ul>
                        <h2 class="post-title">
                            <a routerLink='/news-detail'>Lorem ipsum dolor sit amet, consecte cing elit, sed do eiusmod tempor.</a>
                        </h2>
                        <ul class="custom-flex post-meta">
                            <li>
                                <a href="#">
                                    <i class="fal fa-eye"></i>
                                    232 Views
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fal fa-comments"></i>
                                    35 Comments
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fal fa-calendar-alt"></i>
                                    24th March 2020
                                </a>
                            </li>
                        </ul>
                    </div>
                </article>
                <div class="row">
                    <div class="col-12">
                        <ul class="custom pagination justify-content-center mt-4 mb-md-80">
                            <li class="page-item"><a class="page-link" href="#"><i class="far fa-angle-double-left"></i></a></li>
                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                            <li class="page-item active"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item"><a class="page-link" href="#">...</a></li>
                            <li class="page-item"><a class="page-link" href="#">10</a></li>
                            <li class="page-item"><a class="page-link" href="#"><i class="far fa-angle-double-right"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <aside class="col-lg-4">
                <app-news-sidebar></app-news-sidebar>
            </aside>
        </div>
    </div>
</section>
<!-- End News -->
<!-- The Modal -->
<div class="video-modal modal" id="videomodal">
    <div class="modal-dialog">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <!-- Modal body -->
            <div class="modal-body">
                <iframe src="https://www.youtube.com/embed/pbiEfJd2Hpw" class="image-fit" title="video"></iframe>
            </div>
        </div>
    </div>
</div>
<app-footer-one></app-footer-one>