<app-header-one></app-header-one>
<!-- Start Subheader -->
<div class="normal-bg subheader" style="background-image: url('assets/images/subheader.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="subheader-content">
                    <h1 class="page-title text-custom-white fw-600">Our Portfolio</h1>
                    <ul class="custom-flex breadcrumb">
                        <li><a routerLink='/'>Home</a></li>
                        <li>Portfolio</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
<!-- Start Portfolio -->
<section class="section-padding">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="service-block-3 mb-xl-30">
                    <div class="service-image animate-img">
                        <img src="assets/images/service-1.jpg" class="image-fit" alt="">
                    </div>
                    <div class="service-caption">
                        <div class="left-side">
                            <p class="text-orange double-lines fw-600 mb-1 fs-16"><span>Development</span></p>
                            <h4 class="fw-600 no-margin"><a routerLink='/project-detail'>Ed do eiusmod tem</a></h4>
                        </div>
                        <a routerLink='/project-detail' class="block-btn">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-block-3 mb-xl-30">
                    <div class="service-image animate-img">
                        <img src="assets/images/service-2.jpg" class="image-fit" alt="">
                    </div>
                    <div class="service-caption">
                        <div class="left-side">
                            <p class="text-orange double-lines fw-600 mb-1 fs-16"><span>Design</span></p>
                            <h4 class="fw-600 no-margin"><a routerLink='/project-detail'>Por incididunt ut</a></h4>
                        </div>
                        <a routerLink='/project-detail' class="block-btn">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-block-3 mb-xl-30">
                    <div class="service-image animate-img">
                        <img src="assets/images/service-3.jpg" class="image-fit" alt="">
                    </div>
                    <div class="service-caption">
                        <div class="left-side">
                            <p class="text-orange double-lines fw-600 mb-1 fs-16"><span>Finance</span></p>
                            <h4 class="fw-600 no-margin"><a routerLink='/project-detail'>Labore et dolore</a></h4>
                        </div>
                        <a routerLink='/project-detail' class="block-btn">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-block-3 mb-xl-30">
                    <div class="service-image animate-img">
                        <img src="assets/images/service-4.jpg" class="image-fit" alt="">
                    </div>
                    <div class="service-caption">
                        <div class="left-side">
                            <p class="text-orange double-lines fw-600 mb-1 fs-16"><span>Game Design</span></p>
                            <h4 class="fw-600 no-margin"><a routerLink='/project-detail'>Magna aliqua it en</a></h4>
                        </div>
                        <a routerLink='/project-detail' class="block-btn">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-block-3 mb-xl-30">
                    <div class="service-image animate-img">
                        <img src="assets/images/service-5.jpg" class="image-fit" alt="">
                    </div>
                    <div class="service-caption">
                        <div class="left-side">
                            <p class="text-orange double-lines fw-600 mb-1 fs-16"><span>UX Design</span></p>
                            <h4 class="fw-600 no-margin"><a routerLink='/project-detail'>Im ad minim veniam</a></h4>
                        </div>
                        <a routerLink='/project-detail' class="block-btn">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-block-3 mb-xl-30">
                    <div class="service-image animate-img">
                        <img src="assets/images/service-6.jpg" class="image-fit" alt="">
                    </div>
                    <div class="service-caption">
                        <div class="left-side">
                            <p class="text-orange double-lines fw-600 mb-1 fs-16"><span>Development</span></p>
                            <h4 class="fw-600 no-margin"><a routerLink='/project-detail'>Quis nostrud exerci</a></h4>
                        </div>
                        <a routerLink='/project-detail' class="block-btn">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-block-3 mb-xl-30">
                    <div class="service-image animate-img">
                        <img src="assets/images/service-7.jpg" class="image-fit" alt="">
                    </div>
                    <div class="service-caption">
                        <div class="left-side">
                            <p class="text-orange double-lines fw-600 mb-1 fs-16"><span>Business Planning</span></p>
                            <h4 class="fw-600 no-margin"><a routerLink='/project-detail'>Tation ullamco labor</a></h4>
                        </div>
                        <a routerLink='/project-detail' class="block-btn">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-block-3 mb-xl-30">
                    <div class="service-image animate-img">
                        <img src="assets/images/service-8.jpg" class="image-fit" alt="">
                    </div>
                    <div class="service-caption">
                        <div class="left-side">
                            <p class="text-orange double-lines fw-600 mb-1 fs-16"><span>Consultancy</span></p>
                            <h4 class="fw-600 no-margin"><a routerLink='/project-detail'>Is nisi ut aliquip ex</a></h4>
                        </div>
                        <a routerLink='/project-detail' class="block-btn">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-block-3 mb-xl-30">
                    <div class="service-image animate-img">
                        <img src="assets/images/service-9.jpg" class="image-fit" alt="">
                    </div>
                    <div class="service-caption">
                        <div class="left-side">
                            <p class="text-orange double-lines fw-600 mb-1 fs-16"><span>Growth</span></p>
                            <h4 class="fw-600 no-margin"><a routerLink='/project-detail'>Ea commodo con</a></h4>
                        </div>
                        <a routerLink='/project-detail' class="block-btn">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-block-3 mb-md-30">
                    <div class="service-image animate-img">
                        <img src="assets/images/service-10.jpg" class="image-fit" alt="">
                    </div>
                    <div class="service-caption">
                        <div class="left-side">
                            <p class="text-orange double-lines fw-600 mb-1 fs-16"><span>Finance</span></p>
                            <h4 class="fw-600 no-margin"><a routerLink='/project-detail'>Lorem ipsum dolor</a></h4>
                        </div>
                        <a routerLink='/project-detail' class="block-btn">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-block-3 mb-md-30">
                    <div class="service-image animate-img">
                        <img src="assets/images/service-11.jpg" class="image-fit" alt="">
                    </div>
                    <div class="service-caption">
                        <div class="left-side">
                            <p class="text-orange double-lines fw-600 mb-1 fs-16"><span>Consultancy</span></p>
                            <h4 class="fw-600 no-margin"><a routerLink='/project-detail'>Sit amet, consecte</a></h4>
                        </div>
                        <a routerLink='/project-detail' class="block-btn">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-block-3">
                    <div class="service-image animate-img">
                        <img src="assets/images/service-12.jpg" class="image-fit" alt="">
                    </div>
                    <div class="service-caption">
                        <div class="left-side">
                            <p class="text-orange double-lines fw-600 mb-1 fs-16"><span>Design</span></p>
                            <h4 class="fw-600 no-margin"><a routerLink='/project-detail'>Tr adipisicing elit, s</a></h4>
                        </div>
                        <a routerLink='/project-detail' class="block-btn">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Portfolio -->
<!-- Start brand slider -->
<div class="section-padding clients bg-orange">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-md-10">
                <div class="brand-slider row">
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/client-1.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/client-2.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/client-3.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/client-4.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/client-5.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/client-1.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/client-2.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/client-3.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/client-4.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/client-5.png" class="image-fit" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End brand slider -->
<!-- Start Testimonials -->
<section class="normal-bg section-padding testimonials-sec" style="background-image: url('assets/images/map-bg.jpg');">
    <div class="container">
        <div class="section-header">
            <div class="section-heading">
                <h6 class="text-orange mb-xl-10 sub-heading"><span>Testimonials</span></h6>
                <h3 class="fw-700 title">Clients feedbacks<span class="text-orange">.</span></h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="testimonial-slider">
                    <div class="col-12 slide-item">
                        <div class="testimonial-box">
                            <div class="user-img">
                                <img src="assets/images/testi-1.jpg" class="image-fit" alt="">
                            </div>
                            <p class="fw-500">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore ma gna aliqua. Ut enim ad minim ve niam, quis nostrud exercitation ullamco lab oris nisi ut aliquip ex ea commodo cons equat. Duis aute irure dolor in reprehe nderit in voluptate velit esse.</p>
                            <h4 class="no-margin fw-600 mb-1"><a href="#">Miranda H.Halim</a></h4>
                            <p class="no-margin fw-600 text-orange double-lines"><span>Founder, Miranda Co.</span></p>
                        </div>
                    </div>
                    <div class="col-12 slide-item">
                        <div class="testimonial-box">
                            <div class="user-img">
                                <img src="assets/images/testi-2.jpg" class="image-fit" alt="">
                            </div>
                            <p class="fw-500">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore ma gna aliqua. Ut enim ad minim ve niam, quis nostrud exercitation ullamco lab oris nisi ut aliquip ex ea commodo cons equat. Duis aute irure dolor in reprehe nderit in voluptate velit esse.</p>
                            <h4 class="no-margin fw-600 mb-1"><a href="#">Miranda H.Halim</a></h4>
                            <p class="no-margin fw-600 text-orange double-lines"><span>Founder, Miranda Co.</span></p>
                        </div>
                    </div>
                    <div class="col-12 slide-item">
                        <div class="testimonial-box">
                            <div class="user-img">
                                <img src="assets/images/testi-3.jpg" class="image-fit" alt="">
                            </div>
                            <p class="fw-500">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore ma gna aliqua. Ut enim ad minim ve niam, quis nostrud exercitation ullamco lab oris nisi ut aliquip ex ea commodo cons equat. Duis aute irure dolor in reprehe nderit in voluptate velit esse.</p>
                            <h4 class="no-margin fw-600 mb-1"><a href="#">Miranda H.Halim</a></h4>
                            <p class="no-margin fw-600 text-orange double-lines"><span>Founder, Miranda Co.</span></p>
                        </div>
                    </div>
                    <div class="col-12 slide-item">
                        <div class="testimonial-box">
                            <div class="user-img">
                                <img src="assets/images/testi-1.jpg" class="image-fit" alt="">
                            </div>
                            <p class="fw-500">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore ma gna aliqua. Ut enim ad minim ve niam, quis nostrud exercitation ullamco lab oris nisi ut aliquip ex ea commodo cons equat. Duis aute irure dolor in reprehe nderit in voluptate velit esse.</p>
                            <h4 class="no-margin fw-600 mb-1"><a href="#">Miranda H.Halim</a></h4>
                            <p class="no-margin fw-600 text-orange double-lines"><span>Founder, Miranda Co.</span></p>
                        </div>
                    </div>
                    <div class="col-12 slide-item">
                        <div class="testimonial-box">
                            <div class="user-img">
                                <img src="assets/images/testi-2.jpg" class="image-fit" alt="">
                            </div>
                            <p class="fw-500">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore ma gna aliqua. Ut enim ad minim ve niam, quis nostrud exercitation ullamco lab oris nisi ut aliquip ex ea commodo cons equat. Duis aute irure dolor in reprehe nderit in voluptate velit esse.</p>
                            <h4 class="no-margin fw-600 mb-1"><a href="#">Miranda H.Halim</a></h4>
                            <p class="no-margin fw-600 text-orange double-lines"><span>Founder, Miranda Co.</span></p>
                        </div>
                    </div>
                    <div class="col-12 slide-item">
                        <div class="testimonial-box">
                            <div class="user-img">
                                <img src="assets/images/testi-3.jpg" class="image-fit" alt="">
                            </div>
                            <p class="fw-500">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore ma gna aliqua. Ut enim ad minim ve niam, quis nostrud exercitation ullamco lab oris nisi ut aliquip ex ea commodo cons equat. Duis aute irure dolor in reprehe nderit in voluptate velit esse.</p>
                            <h4 class="no-margin fw-600 mb-1"><a href="#">Miranda H.Halim</a></h4>
                            <p class="no-margin fw-600 text-orange double-lines"><span>Founder, Miranda Co.</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Testimonials -->
<app-footer-one></app-footer-one>