import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}


  ngOnInit(): void {
  }

}
