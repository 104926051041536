import { Component, ElementRef, OnInit, Renderer2  } from '@angular/core';

@Component({
  selector: 'app-news-grid',
  templateUrl: './news-grid.component.html',
  styleUrls: ['./news-grid.component.css']
})
export class NewsGridComponent implements OnInit {

  
  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
  }

}
