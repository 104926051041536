<!-- <app-header-one></app-header-one> -->
<app-header-four></app-header-four>

<!-- Start Slider -->
<div class="main-slider-section p-relative">
    <div class="main-slider">
        <div class="slide-item">
            <img src="assets/images/sliders/hero_slider_1.png" class="image-fit">
            <div class="transform-center">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-9 col-lg-7 text-center">
                            <div class="banner-content">
                                <!-- <a href="#" class="video-btn">
                                    <i class="fas fa-play"></i>
                                </a> -->
                                <h1 class="text-custom-white fw-600 heading-title">The County Public Service Boards National Consultative Forum</h1>
                                <p class="no-margin text-custom-white">The Republic of Kenya</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="slide-item">
            <img src="assets/images/sliders/hero_slider_3.png" alt="" class="image-fit">
            <div class="transform-center">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-9 col-lg-7 text-center">
                            <div class="banner-content">
                                <!-- <a href="#" class="video-btn">
                                    <i class="fas fa-play"></i>
                                </a> -->
                                <h2 class="text-custom-white fw-600 heading-title">CPSBs Annual Convention Forum.</h2>
                                <p class="no-margin text-custom-white">Learn more about this upcoming even on 23rd - 27th September 2023</p>
                                <a href="/upcoming-event" class="theme-btn btn-orange">Register For Event <i class="far fa-plus"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- slider nav -->
    <div class="container">
        <div class="row justify-content-end">
            <div class="col-lg-7">
                <div class="main-slider-nav">
                    <div class="slide-item">
                        <h4 class="fw-600 mb-xl-10">CPSBs Members</h4>
                        <p class="no-margin fw-500">Learn More About Us</p>
                    </div>
                    <div class="slide-item">
                        <h4 class="fw-600 mb-xl-10">Annual Convention Forum</h4>
                        <p class="no-margin fw-500">Register Upcoming Event</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Slider -->

<!-- Start about us -->
<section class="section-padding aboutus-3">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="section-header left-heading">
                    <div class="section-heading">
                        <h6 class="text-orange mb-xl-10 sub-heading"><span>About Us</span></h6>
                        <h3 class="fw-700 title">Core level values and amazing team<span class="text-orange">.</span></h3>
                        <p class="desc fw-500">Welcome to the County Public Service Boards National Consultative Forum (CPSBs-NCF), a registered society that unites all 47 County Public Service Boards (CPSBs) in the Republic of Kenya. Established to foster cooperation and collaboration among these entities, CPSBs-NCF plays a pivotal role in ensuring efficient public service delivery across Kenya's counties.</p>
                    </div>
                </div>
                <ul class="custom about-list">
                    <li>
                        <span>
                            <i class="fal fa-check"></i>
                        </span>
                        <div class="content">
                            <h5 class="fw-600"><a href="#">Mission and vision</a></h5>
                            <p class="fw-500">To promote excellence and efficiency in County Public Service Boards through collaboration, coordination, and adherence to constitutional and legal mandates.</p>
                        </div>
                    </li>
                    <li>
                        <span>
                            <i class="fal fa-check"></i>
                        </span>
                        <div class="content">
                            <h5 class="fw-600"><a href="#">Expert team with good portfolio</a></h5>
                            <p class="fw-500">A unified and effective county public service system that serves the people of Kenya with integrity and dedication.</p>
                        </div>
                    </li>
                </ul>
                <a href="/about" class="theme-btn btn-orange">Learn More About Us <i class="far fa-arrow-alt-circle-right"></i></a>
            </div>
            <div class="col-lg-6">
                <div class="right-side p-relative">
                    <img src="assets/images/team_1.jpg" class="image-fit" alt="">
                    <div class="about-counter">
                        <div>
                            <span class="count">40</span><span class="text-custom-white">+</span>
                            <p class="no-margin fs-12 text-custom-white">Years Of Experience</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End about us -->

<!-- Start About -->
<!-- <section class="section-padding about-sec count-section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-images p-relative mb-md-80">
                    <img src="assets/images/value_1.jpg" class="img-fluid image-one" alt="">
                    <img src="assets/images/about-icon-1.png" class="img-fluid icon-one" alt="">
                    <img src="assets/images/team_1.jpg" class="img-fluid image-two" alt="">
                    <img src="assets/images/about-icon-2.png" class="img-fluid icon-two" alt="">
                </div>
            </div>
            <div class="col-lg-6 col-md-10">
                <div class="section-header left-heading pb-0">
                    <div class="section-heading">
                        <h6 class="text-orange mb-xl-10 sub-heading"><span>About Us</span></h6>
                        <h3 class="text-blue fw-700 title">Core level values and amazing team<span class="text-orange">.</span></h3>
                        <p class="desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim veniam.</p>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-4 text-center">
                        <span class="count">82</span><span class="text-orange fs-16">%</span>
                    </div>
                    <div class="col-8">
                        <h4 class="mb-xl-10 fw-600">Our mission and vision<span class="text-orange">.</span></h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End About -->

<!-- Start sponsors slider -->
<!-- <div class="section-padding bg-light-white p-relative">
    <div class="container-fluid">
        <div class="section-header">
            <div class="section-heading">
                <h6 class="text-orange mb-xl-10 sub-heading"><span>Sponsors</span></h6>
                <h3 class="text-blue fw-700 title">Platinum sponsors for the event<span class="text-orange">.</span></h3>
            </div>
        </div>
        <div class="lb-big-text">Sponsors</div>
        <div class="row justify-content-center">
            <div class="col-md-10">
                <div class="brand-slider row">
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/sponsors/safaricom-logo.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/sponsors/act-logo.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/sponsors/cpf-logo.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/sponsors/equity-logo.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/sponsors/ethics-logo.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/sponsors/ihrm-logo.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/sponsors/kcb-logo.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/sponsors/lapfund-logo.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/sponsors/nhif-logo.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/sponsors/nssf-logo.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/sponsors/rba-logo.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/sponsors/software-tech-logo.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End sponsors slider -->

<!-- Start Profit Share -->
<!-- <section class="section-padding bg-light-white">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 mb-md-80">
                <div class="section-header left-heading pb-0">
                    <div class="section-heading">
                        <h6 class="text-orange mb-xl-10 sub-heading"><span>Profit Share</span></h6>
                        <h3 class="text-blue fw-700 title">Any kind business profit share<span class="text-orange">.</span></h3>
                        <p class="desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim veniam.</p>
                    </div>
                </div>
                <ul class="custom profit-list">
                    <li>Kind business & shop profit share</li>
                    <li>Solid solution with great intregation</li>
                </ul>
                <a routerLink='/project-detail' class="theme-btn btn-orange">
                    Case Study <i class="fal fa-plus"></i>
                </a>
            </div>
            <div class="col-lg-6">
                <div class="profit-chart-box">
                    <a routerLink='/' class="chart-logo">
                        <img src="assets/images/logo-icon.png" class="image-fit" alt="">
                    </a>
                    <a href="#" class="profit-icon icon-ps-1">
                        <span class="icon">
                            <i class="flaticon-receipt"></i>
                        </span>
                        <span class="icon-text">Finance</span>
                    </a>
                    <a href="#" class="profit-icon icon-ps-2">
                        <span class="icon">
                            <i class="flaticon-businessman"></i>
                        </span>
                        <span class="icon-text">Consultancy</span>
                    </a>
                    <a href="#" class="profit-icon icon-ps-3">
                        <span class="icon">
                            <i class="flaticon-tax"></i>
                        </span>
                        <span class="icon-text">Tax</span>
                    </a>
                    <a href="#" class="profit-icon icon-ps-4">
                        <span class="icon">
                            <i class="flaticon-label"></i>
                        </span>
                        <span class="icon-text">Profit Share</span>
                    </a>
                    <a href="#" class="profit-icon icon-ps-5">
                        <span class="icon">
                            <i class="flaticon-payment-method"></i>
                        </span>
                        <span class="icon-text">Banking</span>
                    </a>
                    <a href="#" class="profit-icon icon-ps-6">
                        <span class="icon">
                            <i class="flaticon-presentation"></i>
                        </span>
                        <span class="icon-text">Growth</span>
                    </a>
                    <a href="#" class="profit-icon icon-ps-7">
                        <span class="icon">
                            <i class="flaticon-suitcase"></i>
                        </span>
                        <span class="icon-text">Policy</span>
                    </a>
                    <a href="#" class="profit-icon icon-ps-8">
                        <span class="icon">
                            <i class="flaticon-credit"></i>
                        </span>
                        <span class="icon-text">Home Loan</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Profit Share -->

<!-- Start Services -->
<section class="section-padding p-relative">
    <div class="container">
        <div class="section-header">
            <div class="section-heading">
                <h6 class="text-orange mb-xl-10 sub-heading"><span>Services</span></h6>
                <h3 class="text-blue fw-700 title">How we service you<span class="text-orange">.</span></h3>
            </div>
        </div>
        <div class="lb-big-text">Services</div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="choose-us-box style-2 mb-xl-30">
                    <div class="top-sec">
                        <div class="icon">
                            <i class="flaticon-statistics text-orange"></i>
                        </div>
                        <h4 class="fw-600 no-margin"><a routerLink='/service-detail'>Collaboration & Consensus Building</a></h4>
                    </div>
                    <p class="fw-500 mb-xl-10">CPSBs-NCF provides a platform for County Public Service Boards (CPSBs) to collaborate, share insights, and build consensus on matters related to their mandates, ensuring a unified approach to common challenges.</p>
                    <a routerLink='/service-detail' class="fw-600 fs-14 link">Read More <i class="fas fa-long-arrow-right text-orange"></i></a>
                    <i class="flaticon-statistics bg-icon"></i>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="choose-us-box style-2 mb-xl-30">
                    <div class="top-sec">
                        <div class="icon">
                            <i class="flaticon-market text-orange"></i>
                        </div>
                        <h4 class="fw-600 no-margin"><a routerLink='/service-detail'>Policy Advocacy</a></h4>
                    </div>
                    <p class="fw-500 mb-xl-10">The forum engages in policy advocacy and works with stakeholders to promote the enactment of laws and regulations that support the successful implementation of the Devolution system of government.</p>
                    <a routerLink='/service-detail' class="fw-600 fs-14 link">Read More <i class="fas fa-long-arrow-right text-orange"></i></a>
                    <i class="flaticon-market bg-icon"></i>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="choose-us-box style-2 mb-xl-30">
                    <div class="top-sec">
                        <div class="icon">
                            <i class="flaticon-investment text-orange"></i>
                        </div>
                        <h4 class="fw-600 no-margin"><a routerLink='/service-detail'>Capacity Building & Training</a></h4>
                    </div>
                    <p class="fw-500 mb-xl-10">CPSBs-NCF offers continuous capacity building programs and training sessions to empower CPSB members with the knowledge and skills needed for effective public service governance.</p>
                    <a routerLink='/service-detail' class="fw-600 fs-14 link">Read More <i class="fas fa-long-arrow-right text-orange"></i></a>
                    <i class="flaticon-investment bg-icon"></i>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="choose-us-box style-2 mb-md-30">
                    <div class="top-sec">
                        <div class="icon">
                            <i class="flaticon-graphic text-orange"></i>
                        </div>
                        <h4 class="fw-600 no-margin"><a routerLink='/service-detail'>Conflict Resolution & Dispute Mediation</a></h4>
                    </div>
                    <p class="fw-500 mb-xl-10">The forum provides a mechanism for the peaceful resolution of disputes within CPSBs, fostering a harmonious working environment and minimizing conflicts.</p>
                    <a routerLink='/service-detail' class="fw-600 fs-14 link">Read More <i class="fas fa-long-arrow-right text-orange"></i></a>
                    <i class="flaticon-graphic bg-icon"></i>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="choose-us-box style-2 mb-md-30">
                    <div class="top-sec">
                        <div class="icon">
                            <i class="flaticon-growth text-orange"></i>
                        </div>
                        <h4 class="fw-600 no-margin"><a routerLink='/service-detail'>Networking & Information Exchange</a></h4>
                    </div>
                    <p class="fw-500 mb-xl-10">CPSBs-NCF facilitates networking opportunities and information exchange among CPSB members, promoting the sharing of best practices and innovative solutions.</p>
                    <a routerLink='/service-detail' class="fw-600 fs-14 link">Read More <i class="fas fa-long-arrow-right text-orange"></i></a>
                    <i class="flaticon-growth bg-icon"></i>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="choose-us-box style-2">
                    <div class="top-sec">
                        <div class="icon">
                            <i class="flaticon-box text-orange"></i>
                        </div>
                        <h4 class="fw-600 no-margin"><a routerLink='/service-detail'>Research & Policy Analysis</a></h4>
                    </div>
                    <p class="fw-500 mb-xl-10">The forum conducts research and policy analysis to provide data-driven insights and recommendations for enhancing the functioning of County Public Service Boards, ultimately improving public service delivery.</p>
                    <a routerLink='/service-detail' class="fw-600 fs-14 link">Read More <i class="fas fa-long-arrow-right text-orange"></i></a>
                    <i class="flaticon-box bg-icon"></i>
                </div>
            </div>

        </div>
    </div>
</section>
<!-- End Services -->

<!-- Start Process -->
<!-- <section class="section-padding process-sec p-relative">
    <div class="container">
        <div class="section-header">
            <div class="section-heading">
                <h6 class="text-orange mb-xl-10 sub-heading"><span>Process</span></h6>
                <h3 class="text-blue fw-700 title">How your business going witn us<span class="text-orange">.</span></h3>
            </div>
        </div>
        <div class="lb-big-text">Process</div>
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="process-box">
                    <a href="#" class="text-orange fw-600 fs-14">Read more <i class="fal fa-plus ml-1 fw-600 fs-12"></i></a>
                    <h4 class="fw-600">Get a free trail option</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua nim ad minim veniam. </p>
                    <span class="icon text-orange">
                        <i class="flaticon-statistics"></i>
                    </span>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="process-box">
                    <a href="#" class="text-orange fw-600 fs-14">Read more <i class="fal fa-plus ml-1 fw-600 fs-12"></i></a>
                    <h4 class="fw-600">More solid research</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua nim ad minim veniam. </p>
                    <span class="icon text-orange">
                        <i class="flaticon-market"></i>
                    </span>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="process-box">
                    <a href="#" class="text-orange fw-600 fs-14">Read more <i class="fal fa-plus ml-1 fw-600 fs-12"></i></a>
                    <h4 class="fw-600">Save your finance</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua nim ad minim veniam. </p>
                    <span class="icon text-orange">
                        <i class="flaticon-statistics"></i>
                    </span>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Process -->

<!-- Start Team -->
<section class="section-padding team-sec">
    <div class="container">
        <div class="row align-items-center mb-5">
            
            <div class="col-lg-12">
                <div class="section-header center pb-0">
                    <div class="section-heading">
                        <h6 class="text-orange mb-xl-10 sub-heading"><span>The Team</span></h6>
                        <h3 class="fw-700 title">Our Great Leaders<span class="text-orange">.</span></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="about-head-text">
                    <p class="fw-500">Our organization is currently under the esteemed leadership of Hon. Jared Kopiyo, the National Chairperson of CPSB National Consultative Forum. Assisting him is a dedicated 9-member Executive Committee
                        During the 3rd Administration Cycle (2021-2024), the Executive Committee members were elected in April 2022 to serve a 2-year term.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="team-block-2 p-relative mb-xl-30">
                    <div class="team-image animate-img">
                        <img src="assets/images/leaders/jared.png" class="image-fit" alt="">
                    </div>
                    <ul class="team-social-media custom">
                        <li>
                            <a href="#">
                                <i class="fal fa-plus"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-linkedin"></i>
                            </a>
                        </li>
                    </ul>
                    <div class="team-caption">
                        <h4><a href="#">Hon. Jared Kopiyo</a></h4>
                        <p class="title-first no-margin fw-500 double-lines text-orange"><span>National Chairman,</span></p>
                        <p class="muted"><i>The CPSBs National Consultative Forum (The National Forum)</i></p>
                        <p><span class="text-orange">Chairman,</span> <br> Migori County Public Service Board Regional Representative: <b>Nyanza Region</b></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="team-block-2 p-relative mb-xl-30">
                    <div class="team-image animate-img">
                        <img src="assets/images/leaders/farida.png" class="image-fit" alt="">
                    </div>
                    <ul class="team-social-media custom">
                        <li>
                            <a href="#">
                                <i class="fal fa-plus"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-linkedin"></i>
                            </a>
                        </li>
                    </ul>
                    <div class="team-caption">
                        <h4><a href="#">Mrs. Farida Abdalla</a></h4>
                        <p class="title-first no-margin fw-500 double-lines text-orange"><span>The Vice Chairperson</span></p>
                        <p class="muted"><i>The CPSBs National Consultative Forum (The National Forum)</i></p>
                        <p><span class="text-orange">Chairperson,</span> <br> Mombasa County Public Service Board Regional Representative: <b>Coast Region</b></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="team-block-2 p-relative mb-xl-30">
                    <div class="team-image animate-img">
                        <img src="assets/images/leaders/samson.png" class="image-fit" alt="">
                    </div>
                    <ul class="team-social-media custom">
                        <li>
                            <a href="#">
                                <i class="fal fa-plus"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-linkedin"></i>
                            </a>
                        </li>
                    </ul>
                    <div class="team-caption">
                        <h4><a href="#">CS. Samson Kibii</a></h4>
                        <p class="title-first no-margin fw-500 double-lines text-orange"><span>The Secretary-General</span></p>
                        <p class="muted"><i>The CPSBs National Consultative Forum (The National Forum)</i></p>
                        <p><span class="text-orange">Secretary/CEO,</span> <br> Baringo County Public Service Board Regional Representative: <b>North Rift Region</b></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="team-block-2 p-relative mb-xl-30">
                    <div class="team-image animate-img">
                        <img src="assets/images/leaders/nicodemus.png" class="image-fit" alt="">
                    </div>
                    <ul class="team-social-media custom">
                        <li>
                            <a href="#">
                                <i class="fal fa-plus"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-linkedin"></i>
                            </a>
                        </li>
                    </ul>
                    <div class="team-caption">
                        <h4><a href="#">Dr. CS.;CPA; Nicodemus Muteti</a></h4>
                        <p class="title-first no-margin fw-500 double-lines text-orange"><span>The National Treasurer</span></p>
                        <p class="muted"><i>The CPSBs National Consultative Forum (The National Forum)</i></p>
                        <p><span class="text-orange">Chairperson,</span> <br> Makueni County Public Service Board Regional Representative: <b> Eastern Region</b></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="team-block-2 p-relative mb-md-30">
                    <div class="team-image animate-img">
                        <img src="assets/images/leaders/bonee.png" class="image-fit" alt="">
                    </div>
                    <ul class="team-social-media custom">
                        <li>
                            <a href="#">
                                <i class="fal fa-plus"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-linkedin"></i>
                            </a>
                        </li>
                    </ul>
                    <div class="team-caption">
                        <h4><a href="#">Mr. Bonee Wasike</a></h4>
                        <p class="title-first no-margin fw-500 double-lines text-orange"><span>Member - Excom</span></p>
                        <p class="muted"><i>The CPSBs National Consultative Forum (The National Forum)</i></p>
                        <p><span class="text-orange">Member,</span> <br> Bungoma County Public Service Board Regional Representative: <b> Western Region</b></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="team-block-2 p-relative mb-md-30">
                    <div class="team-image animate-img">
                        <img src="assets/images/leaders/christina.png" class="image-fit" alt="">
                    </div>
                    <ul class="team-social-media custom">
                        <li>
                            <a href="#">
                                <i class="fal fa-plus"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-linkedin"></i>
                            </a>
                        </li>
                    </ul>
                    <div class="team-caption">
                        <h4><a href="#">Ms. Christina Kibet</a></h4>
                        <p class="title-first no-margin fw-500 double-lines text-orange"><span>Member - Excom</span></p>
                        <p class="muted"><i>The CPSBs National Consultative Forum (The National Forum)</i></p>
                        <p><span class="text-orange">Member,</span> <br>  Laikipia County Public Service Board
                            Regional Representative:
                             <b> South Rift Region</b></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="team-block-2 p-relative mb-sm-30">
                    <div class="team-image animate-img">
                        <img src="assets/images/leaders/jama.png" class="image-fit" alt="">
                    </div>
                    <ul class="team-social-media custom">
                        <li>
                            <a href="#">
                                <i class="fal fa-plus"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-linkedin"></i>
                            </a>
                        </li>
                    </ul>
                    <div class="team-caption">
                        <h4><a href="#">Mr. Jama Genney</a></h4>
                        <p class="title-first no-margin fw-500 double-lines text-orange"><span>Member - Excom</span></p>
                        <p class="muted"><i>The CPSBs National Consultative Forum (The National Forum)</i></p>
                        <p><span class="text-orange">Member,</span> <br>  Mandera County Public Service Board Regional Representative:
                             <b> North Eastern Region</b></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="team-block-2 p-relative">
                    <div class="team-image animate-img">
                        <img src="assets/images/leaders/consolata.png" class="image-fit" alt="">
                    </div>
                    <ul class="team-social-media custom">
                        <li>
                            <a href="#">
                                <i class="fal fa-plus"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-linkedin"></i>
                            </a>
                        </li>
                    </ul>
                    <div class="team-caption">
                        <h4><a href="#">Dr. Consolata Kimuya</a></h4>
                        <p class="title-first no-margin fw-500 double-lines text-orange"><span>Member - Excom</span></p>
                        <p class="muted"><i>The CPSBs National Consultative Forum (The National Forum)</i></p>
                        <p><span class="text-orange">Member,</span> <br>  Nairobi City County Public Service Board
                            Regional Representative:                            
                             <b> Nairobi Region</b></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="team-block-2 p-relative mb-sm-30">
                    <div class="team-image animate-img">
                        <img src="assets/images/leaders/william.png" class="image-fit" alt="">
                    </div>
                    <ul class="team-social-media custom">
                        <li>
                            <a href="#">
                                <i class="fal fa-plus"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-linkedin"></i>
                            </a>
                        </li>
                    </ul>
                    <div class="team-caption">
                        <h4><a href="#">FCS. William Gatehi</a></h4>
                        <p class="title-first no-margin fw-500 double-lines text-orange"><span>Member - Excom</span></p>
                        <p class="muted"><i>The CPSBs National Consultative Forum (The National Forum)</i></p>
                        <p><span class="text-orange">Chairman,</span> <br>  Nyandarua County Public Service Board Regional Representative:                            
                             <b> Central Region</b></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="team-block-2 p-relative mb-sm-30">
                    <div class="team-image animate-img">
                        <img src="assets/images/leaders/yvonne.png" class="image-fit" alt="">
                    </div>
                    <ul class="team-social-media custom">
                        <li>
                            <a href="#">
                                <i class="fal fa-plus"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-linkedin"></i>
                            </a>
                        </li>
                    </ul>
                    <div class="team-caption">
                        <h4><a href="#">Ms. Yvonne Kinya</a></h4>
                        <p class="title-first no-margin fw-500 double-lines text-orange"><span>Senior Administrative Officer</span></p>
                        <p class="muted"><i>The CPSBs National Consultative Forum (The National Forum)</i></p>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Team -->

<!-- Start Event -->
<section class="skillset-sec">
    <div class="container-fluid no-padding">
        <div class="row no-gutters">
            <div class="col-lg-6">
                <img src="assets/images/gallery/annual_convention_event_2.jpg" class="image-fit" alt="">
            </div>
            <div class="col-lg-6 bg-dark-blue section-padding">
                <div class="section-header left-heading">
                    <div class="section-heading">
                        <h6 class="text-orange mb-xl-10 sub-heading"><span>Upcoming Event</span></h6>
                        <h3 class="text-custom-white fw-700 title">Get an amazing experience with the CPSBs-NCF Event<span class="text-orange">.</span></h3>
                        <p class="desc text-custom-white">The CPSBs-NCF has been in existence since 2014 and has been convening an Annual Convention once every year to bring together all its members to network and discuss contemporary issues affecting their mandates.</p>
                        <a routerLink='/contact' class="theme-btn btn-orange">Register For Event Here <i class="fal fa-plus"></i></a>
                    </div>
                </div>
                <!-- <div class="skill-progress-bar-sec">
                    <div class="skill-progress-bar mb-xl-30">
                        <div class="progressbar-wrap">
                            <div class="progressbar" data-width="72"></div>
                        </div>
                        <div class="progressbar-text">
                            <p class="text-custom-white">Digital content create</p>
                            <p class="text-custom-white">72%</p>
                        </div>
                    </div>
                    <div class="skill-progress-bar mb-xl-30">
                        <div class="progressbar-wrap">
                            <div class="progressbar" data-width="80"></div>
                        </div>
                        <div class="progressbar-text">
                            <p class="text-custom-white">UI/UX Designing</p>
                            <p class="text-custom-white">80%</p>
                        </div>
                    </div>
                    <div class="skill-progress-bar mb-xl-30">
                        <div class="progressbar-wrap">
                            <div class="progressbar" data-width="90"></div>
                        </div>
                        <div class="progressbar-text">
                            <p class="text-custom-white">Digital consultancy</p>
                            <p class="text-custom-white">90%</p>
                        </div>
                    </div>
                    <div class="skill-progress-bar">
                        <div class="progressbar-wrap">
                            <div class="progressbar" data-width="98"></div>
                        </div>
                        <div class="progressbar-text">
                            <p class="text-custom-white">Youtube video making</p>
                            <p class="text-custom-white">98%</p>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</section>
<!-- End Event -->

<!-- Start Skillset -->
<!-- <section class="skillset-sec">
    <div class="container-fluid no-padding">
        <div class="row no-gutters">
            <div class="col-lg-6">
                <img src="assets/images/skillset.jpg" class="image-fit" alt="">
            </div>
            <div class="col-lg-6 bg-dark-blue section-padding">
                <div class="section-header left-heading">
                    <div class="section-heading">
                        <h6 class="text-orange mb-xl-10 sub-heading"><span>Our Skillset</span></h6>
                        <h3 class="text-custom-white fw-700 title">Get amazing experience with our cool team<span class="text-orange">.</span></h3>
                        <p class="desc text-custom-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim veniam.</p>
                    </div>
                </div>
                <div class="skill-progress-bar-sec">
                    <div class="skill-progress-bar mb-xl-30">
                        <div class="progressbar-wrap">
                            <div class="progressbar" data-width="72"></div>
                        </div>
                        <div class="progressbar-text">
                            <p class="text-custom-white">Digital content create</p>
                            <p class="text-custom-white">72%</p>
                        </div>
                    </div>
                    <div class="skill-progress-bar mb-xl-30">
                        <div class="progressbar-wrap">
                            <div class="progressbar" data-width="80"></div>
                        </div>
                        <div class="progressbar-text">
                            <p class="text-custom-white">UI/UX Designing</p>
                            <p class="text-custom-white">80%</p>
                        </div>
                    </div>
                    <div class="skill-progress-bar mb-xl-30">
                        <div class="progressbar-wrap">
                            <div class="progressbar" data-width="90"></div>
                        </div>
                        <div class="progressbar-text">
                            <p class="text-custom-white">Digital consultancy</p>
                            <p class="text-custom-white">90%</p>
                        </div>
                    </div>
                    <div class="skill-progress-bar">
                        <div class="progressbar-wrap">
                            <div class="progressbar" data-width="98"></div>
                        </div>
                        <div class="progressbar-text">
                            <p class="text-custom-white">Youtube video making</p>
                            <p class="text-custom-white">98%</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Skillset -->

<!-- Start Faqs -->
<section class="section-padding-top faqs-sec">
    <div class="container">
        <div class="row align-items-end">
            <div class="col-lg-12">
                <div class="left-side">
                    <div class="section-header left-heading">
                        <div class="section-heading">
                            <h6 class="text-orange mb-xl-10 sub-heading"><span>FAQ</span></h6>
                            <h3 class="fw-700 title">You have to know before start everything<span class="text-orange">.</span></h3>
                        </div>
                    </div>
                    <div id="accordion" class="custom-faqs">
                        <div class="card">
                            <div class="card-header">
                                <a class="collapsed card-link" data-toggle="collapse" href="#collapseOne">
                                    What is the The County Public Service Boards National Consultative Forum?
                                    <span>
                                        <i class="fal fa-arrow-down"></i>
                                    </span>
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse" data-parent="#accordion">
                                <div class="card-body">
                                    CPSBs-NCF, or the County Public Service Boards National Consultative Forum, is a registered society comprising all 47 County Public Service Boards in Kenya. Its purpose is to foster cooperation, collaboration, and consensus-building among County Public Service Boards to address common challenges and promote effective public service delivery.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header">
                                <a class="card-link" data-toggle="collapse" href="#collapseTwo">
                                    Who can become a member of CPSBs-NCF?
                                    <span>
                                        <i class="fal fa-arrow-down"></i>
                                    </span>
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse show" data-parent="#accordion">
                                <div class="card-body">
                                    Membership in CPSBs-NCF is typically limited to County Public Service Boards in Kenya. Each County Public Service Board is represented through its appointed members in the forum.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header">
                                <a class="collapsed card-link" data-toggle="collapse" href="#collapseThree">
                                    What is the role of CPSBs-NCF in relation to County Public Service Boards?
                                    <span>
                                        <i class="fal fa-arrow-down"></i>
                                    </span>
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" data-parent="#accordion">
                                <div class="card-body">
                                    CPSBs-NCF serves as a platform for County Public Service Boards to articulate their issues, share knowledge and experience, engage in decision-making, and collaborate on solutions to challenges faced in executing their duties.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header">
                                <a class="collapsed card-link" data-toggle="collapse" href="#collapseFour">
                                    How can County Public Service Boards benefit from CPSBs-NCF membership?
                                    <span>
                                        <i class="fal fa-arrow-down"></i>
                                    </span>
                                </a>
                            </div>
                            <div id="collapseFour" class="collapse" data-parent="#accordion">
                                <div class="card-body">
                                    Membership in CPSBs-NCF provides County Public Service Boards with opportunities for networking, capacity building, advocacy, and access to resources and information that can enhance their effectiveness in public service governance.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-6">
                <div class="faq-man p-relative">
                    <img src="assets/images/faq-man.png" class="image-fit" alt="">
                </div>
            </div> -->
        </div>
    </div>
</section>
<!-- End Faqs -->

<!-- Start Paybill -->
<div class="quote-sec p-relative">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="quote-box bg-dark-blue">
                    <div class="row align-items-center justify-content-between">
                        <div class="col-lg-6 mb-md-80">
                            <div class="section-header left-heading pb-0">
                                <div class="section-heading">
                                    <h6 class="text-orange mb-xl-10 sub-heading"><span>Get Ticket</span></h6>
                                    <h3 class="fw-700 title text-custom-white">Get tickets today with MPESA Paybill<span class="text-orange">.</span></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 text-center">
                            <div class="mx-auto mb-2" style="width: 240px;">
                                <img src="assets/images/mpesa.jpeg" class="image-fit" alt="">
                            </div>
                            <p class="text-custom-white">We help you see the world differently, discover opportunities you may never have imagined.</p>
                            <div class="row text-center">
                                <div class="col-6">
                                    <p class="text-custom-white contact-no">
                                        <i class="fal fa-ticket text-orange"></i> <br>
                                        90876
                                    </p>
                                    <p class="text-white">Paybill Number</p>
                                </div>
                                <div class="col-6">
                                    <p class="text-custom-white contact-no">
                                        <i class="fal fa-bookmark text-orange"></i> <br>
                                        NCB Event
                                    </p>
                                    <p class="text-white">Account Name</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Paybill -->

<!-- Start Quote -->
<!-- <div class="quote-sec p-relative">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="quote-box bg-dark-blue">
                    <div class="row align-items-center justify-content-between">
                        <div class="col-lg-6 mb-md-80">
                            <div class="section-header left-heading pb-0">
                                <div class="section-heading">
                                    <h6 class="text-orange mb-xl-10 sub-heading"><span>Get A Quote</span></h6>
                                    <h3 class="fw-700 title text-custom-white">Get a free consultancy right now<span class="text-orange">.</span></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5">
                            <p class="text-custom-white">We help you see the world differently, discover opportunities you may never have imagined.</p>
                            <a href="#" class="text-custom-white contact-no">
                                <i class="fal fa-phone text-orange"></i>
                                +989 876 765 65 4
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Quote -->

<!-- Start Gallery -->
<section class="section-padding slider-gallery-sec p-relative">
    <div class="container-fluid no-padding">
        <div class="section-header">
            <div class="section-heading">
                <h6 class="text-orange mb-xl-10 sub-heading"><span>Gallery</span></h6>
                <h3 class="text-blue fw-700 title">See what we are upto<span class="text-orange">.</span></h3>
            </div>
        </div>
        <div class="lb-big-text">Gallery</div>
        <div class="row">
            <div class="col-12">
                <div class="slider-gallery row arrow-layout">
                    <div class="col-12 slide-item">
                        <img src="assets/images/gallery/annual_convention_event_1.jpg" class="image-fit" alt="">
                    </div>
                    <div class="col-12 slide-item">
                        <img src="assets/images/gallery/IGRTC_&_EXCOM_LEADERSHIP.jpeg" class="image-fit" alt="">
                    </div>
                    <div class="col-12 slide-item">
                        <img src="assets/images/gallery/annual_convention_event_2.jpg" class="image-fit" alt="">
                    </div>
                    <div class="col-12 slide-item">
                        <img src="assets/images/gallery/Ambrose_Lochokwe_Overall_best_Chieftain_in_championing_&_transforming_developed_public_service_in_Africa.jpeg" class="image-fit" alt="" style="object-position: top center;">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="section-padding slider-gallery-sec p-relative">
    <div class="container-fluid no-padding">
        <div class="row">
            <div class="col-12">
                <div class="slider-gallery row arrow-layout">
                    <div class="col-12 slide-item">
                        <img src="assets/images/gallery-1.jpg" class="image-fit" alt="">
                        <div class="gallery-text">
                            <p class="text-orange mb-xl-10 fw-600 double-lines"><span>Consultancy</span></p>
                            <h3 class="fw-600"><a routerLink='/project-detail'>Miranda halim group consultancy</a></h3>
                        </div>
                    </div>
                    <div class="col-12 slide-item">
                        <img src="assets/images/gallery-2.jpg" class="image-fit" alt="">
                        <div class="gallery-text">
                            <p class="text-orange mb-xl-10 fw-600 double-lines"><span>Consultancy</span></p>
                            <h3 class="fw-600"><a routerLink='/project-detail'>Miranda halim group consultancy</a></h3>
                        </div>
                    </div>
                    <div class="col-12 slide-item">
                        <img src="assets/images/gallery-3.jpg" class="image-fit" alt="">
                        <div class="gallery-text">
                            <p class="text-orange mb-xl-10 fw-600 double-lines"><span>Consultancy</span></p>
                            <h3 class="fw-600"><a routerLink='/project-detail'>Miranda halim group consultancy</a></h3>
                        </div>
                    </div>
                    <div class="col-12 slide-item">
                        <img src="assets/images/gallery-4.jpg" class="image-fit" alt="">
                        <div class="gallery-text">
                            <p class="text-orange mb-xl-10 fw-600 double-lines"><span>Consultancy</span></p>
                            <h3 class="fw-600"><a routerLink='/project-detail'>Miranda halim group consultancy</a></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Gallery -->

<!-- Start Testimonials -->
<!-- <section class="normal-bg section-padding testimonials-sec" style="background-image: url('assets/images/map-bg.jpg');">
    <div class="container">
        <div class="section-header">
            <div class="section-heading">
                <h6 class="text-orange mb-xl-10 sub-heading"><span>Testimonials</span></h6>
                <h3 class="fw-700 title">Clients feedbacks<span class="text-orange">.</span></h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="testimonial-slider">
                    <div class="col-12 slide-item">
                        <div class="testimonial-box">
                            <div class="user-img">
                                <img src="assets/images/testi-1.jpg" class="image-fit" alt="">
                            </div>
                            <p class="fw-500">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore ma gna aliqua. Ut enim ad minim ve niam, quis nostrud exercitation ullamco lab oris nisi ut aliquip ex ea commodo cons equat. Duis aute irure dolor in reprehe nderit in voluptate velit esse.</p>
                            <h4 class="no-margin fw-600 mb-1"><a href="#">Miranda H.Halim</a></h4>
                            <p class="no-margin fw-600 text-orange double-lines"><span>Founder, Miranda Co.</span></p>
                        </div>
                    </div>
                    <div class="col-12 slide-item">
                        <div class="testimonial-box">
                            <div class="user-img">
                                <img src="assets/images/testi-2.jpg" class="image-fit" alt="">
                            </div>
                            <p class="fw-500">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore ma gna aliqua. Ut enim ad minim ve niam, quis nostrud exercitation ullamco lab oris nisi ut aliquip ex ea commodo cons equat. Duis aute irure dolor in reprehe nderit in voluptate velit esse.</p>
                            <h4 class="no-margin fw-600 mb-1"><a href="#">Miranda H.Halim</a></h4>
                            <p class="no-margin fw-600 text-orange double-lines"><span>Founder, Miranda Co.</span></p>
                        </div>
                    </div>
                    <div class="col-12 slide-item">
                        <div class="testimonial-box">
                            <div class="user-img">
                                <img src="assets/images/testi-3.jpg" class="image-fit" alt="">
                            </div>
                            <p class="fw-500">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore ma gna aliqua. Ut enim ad minim ve niam, quis nostrud exercitation ullamco lab oris nisi ut aliquip ex ea commodo cons equat. Duis aute irure dolor in reprehe nderit in voluptate velit esse.</p>
                            <h4 class="no-margin fw-600 mb-1"><a href="#">Miranda H.Halim</a></h4>
                            <p class="no-margin fw-600 text-orange double-lines"><span>Founder, Miranda Co.</span></p>
                        </div>
                    </div>
                    <div class="col-12 slide-item">
                        <div class="testimonial-box">
                            <div class="user-img">
                                <img src="assets/images/testi-1.jpg" class="image-fit" alt="">
                            </div>
                            <p class="fw-500">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore ma gna aliqua. Ut enim ad minim ve niam, quis nostrud exercitation ullamco lab oris nisi ut aliquip ex ea commodo cons equat. Duis aute irure dolor in reprehe nderit in voluptate velit esse.</p>
                            <h4 class="no-margin fw-600 mb-1"><a href="#">Miranda H.Halim</a></h4>
                            <p class="no-margin fw-600 text-orange double-lines"><span>Founder, Miranda Co.</span></p>
                        </div>
                    </div>
                    <div class="col-12 slide-item">
                        <div class="testimonial-box">
                            <div class="user-img">
                                <img src="assets/images/testi-2.jpg" class="image-fit" alt="">
                            </div>
                            <p class="fw-500">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore ma gna aliqua. Ut enim ad minim ve niam, quis nostrud exercitation ullamco lab oris nisi ut aliquip ex ea commodo cons equat. Duis aute irure dolor in reprehe nderit in voluptate velit esse.</p>
                            <h4 class="no-margin fw-600 mb-1"><a href="#">Miranda H.Halim</a></h4>
                            <p class="no-margin fw-600 text-orange double-lines"><span>Founder, Miranda Co.</span></p>
                        </div>
                    </div>
                    <div class="col-12 slide-item">
                        <div class="testimonial-box">
                            <div class="user-img">
                                <img src="assets/images/testi-3.jpg" class="image-fit" alt="">
                            </div>
                            <p class="fw-500">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore ma gna aliqua. Ut enim ad minim ve niam, quis nostrud exercitation ullamco lab oris nisi ut aliquip ex ea commodo cons equat. Duis aute irure dolor in reprehe nderit in voluptate velit esse.</p>
                            <h4 class="no-margin fw-600 mb-1"><a href="#">Miranda H.Halim</a></h4>
                            <p class="no-margin fw-600 text-orange double-lines"><span>Founder, Miranda Co.</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- End Testimonials -->

<app-footer-one></app-footer-one>