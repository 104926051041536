<div class="sidebar service-sidebar">
    <ul class="custom service-category mb-xl-30">
        <li>
            <a href="#">
                Financial Planning
                <i class="fal fa-arrow-right"></i>
            </a>
        </li>
        <li>
            <a href="#">
                Tax Planning
                <i class="fal fa-arrow-right"></i>
            </a>
        </li>
        <li>
            <a href="#">
                Insurance Consulting
                <i class="fal fa-arrow-right"></i>
            </a>
        </li>
        <li>
            <a href="#">
                Saving Strategy
                <i class="fal fa-arrow-right"></i>
            </a>
        </li>
        <li>
            <a href="#">
                Mutual Funds
                <i class="fal fa-arrow-right"></i>
            </a>
        </li>
        <li>
            <a href="#">
                Business Loan
                <i class="fal fa-arrow-right"></i>
            </a>
        </li>
    </ul>
    <ul class="custom service-docs mb-xl-30">
        <li>
            <a href="#">
                <i class="fal fa-file-pdf"></i>
                Full Documents
            </a>
        </li>
        <li>
            <a href="#">
                <i class="fal fa-file-image"></i>
                Photo Gallery
            </a>
        </li>
        <li>
            <a href="#">
                <i class="fal fa-file-music"></i>
                Audio Record
            </a>
        </li>
        <li>
            <a href="#">
                <i class="fal fa-file-invoice-dollar"></i>
                Transactions Details
            </a>
        </li>
    </ul>
    <div class="service-quote mb-xl-30">
        <p class="fw-400">We work with clients to integrate the flow of the customer experi ence across channels (e.g., face-to-face, telephone), opening up new lead sources, supporting sales for smaller-value transactions,and creating new models for service. We continuously new practical.</p>
        <div class="quote-author">
            <div class="quote-img">
                <img src="assets/images/author-big.jpg" class="image-fit" alt="">
            </div>
            <div class="quote-text">
                <h5 class="fw-600 no-margin">Rosaline D.</h5>
                <p class="no-margin fw-500 text-orange">Founder, Cixix Co.</p>
            </div>
        </div>
    </div>
    <div class="adv-banner">
        <img src="assets/images/service-adv.jpg" class="image-fit" alt="">
    </div>
</div>