<app-header-one></app-header-one>
<!-- Start Subheader -->
<div class="normal-bg subheader" style="background-image: url('assets/images/subheader.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="subheader-content">
                    <h1 class="page-title text-custom-white fw-600">What we do</h1>
                    <ul class="custom-flex breadcrumb">
                        <li><a routerLink='/'>Home</a></li>
                        <li>Services</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
<!-- Start Services -->
<section class="section-padding">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="service-block-3 mb-xl-30">
                    <div class="service-image animate-img">
                        <img src="assets/images/service-1.jpg" class="image-fit" alt="">
                    </div>
                    <div class="service-caption">
                        <div class="left-side">
                            <p class="text-orange double-lines fw-600 mb-1 fs-16"><span>Finance</span></p>
                            <h4 class="fw-600 no-margin"><a routerLink='/service-detail'>Digital Consultancy</a></h4>
                        </div>
                        <a routerLink='/service-detail' class="block-btn">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-block-3 mb-xl-30">
                    <div class="service-image animate-img">
                        <img src="assets/images/service-2.jpg" class="image-fit" alt="">
                    </div>
                    <div class="service-caption">
                        <div class="left-side">
                            <p class="text-orange double-lines fw-600 mb-1 fs-16"><span>Consultancy</span></p>
                            <h4 class="fw-600 no-margin"><a routerLink='/service-detail'>Digital Consultancy</a></h4>
                        </div>
                        <a routerLink='/service-detail' class="block-btn">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-block-3 mb-xl-30">
                    <div class="service-image animate-img">
                        <img src="assets/images/service-3.jpg" class="image-fit" alt="">
                    </div>
                    <div class="service-caption">
                        <div class="left-side">
                            <p class="text-orange double-lines fw-600 mb-1 fs-16"><span>Strategy & Planning</span></p>
                            <h4 class="fw-600 no-margin"><a routerLink='/service-detail'>Digital Consultancy</a></h4>
                        </div>
                        <a routerLink='/service-detail' class="block-btn">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-block-3 mb-md-30">
                    <div class="service-image animate-img">
                        <img src="assets/images/service-4.jpg" class="image-fit" alt="">
                    </div>
                    <div class="service-caption">
                        <div class="left-side">
                            <p class="text-orange double-lines fw-600 mb-1 fs-16"><span>Design</span></p>
                            <h4 class="fw-600 no-margin"><a routerLink='/service-detail'>UX Designing</a></h4>
                        </div>
                        <a routerLink='/service-detail' class="block-btn">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-block-3 mb-md-30">
                    <div class="service-image animate-img">
                        <img src="assets/images/service-5.jpg" class="image-fit" alt="">
                    </div>
                    <div class="service-caption">
                        <div class="left-side">
                            <p class="text-orange double-lines fw-600 mb-1 fs-16"><span>Web</span></p>
                            <h4 class="fw-600 no-margin"><a routerLink='/service-detail'>Web Development</a></h4>
                        </div>
                        <a routerLink='/service-detail' class="block-btn">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-block-3">
                    <div class="service-image animate-img">
                        <img src="assets/images/service-6.jpg" class="image-fit" alt="">
                    </div>
                    <div class="service-caption">
                        <div class="left-side">
                            <p class="text-orange double-lines fw-600 mb-1 fs-16"><span>Development</span></p>
                            <h4 class="fw-600 no-margin"><a routerLink='/service-detail'>Game development</a></h4>
                        </div>
                        <a routerLink='/service-detail' class="block-btn">
                            <i class="fal fa-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services -->
<!-- Start Video -->
<div class="video-box-sec">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="video-box">
                    <div class="video_wrapper video_wrapper_full js-videoWrapper">
                        <iframe class="videoIframe js-videoIframe" src="https://www.youtube.com/embed/pbiEfJd2Hpw" data-src="https://www.youtube.com/embed/pbiEfJd2Hpw?autoplay=1" allow="autoplay"></iframe>
                        <div class="videoPoster js-videoPoster">
                            <img src="assets/images/video-img.jpg" class="image-fit" alt="#">
                            <div class="video-inner video-btn-wrapper"> <a href="JavaScript:Void(0);" class="text-orange"><i class="fas fa-play"></i></a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Video -->
<!-- Start Cta -->
<section class="about-style-2 call-to-action bg-orange">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-head-text">
                    <p class="text-custom-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="section-header left-heading pb-0">
                    <div class="section-heading">
                        <h6 class="text-custom-white mb-xl-10 sub-heading"><span>Our Values</span></h6>
                        <h3 class="text-custom-white fw-700 title">Core level values and amazing team<span class="text-orange">.</span></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Start Cta -->
<!-- Start Our Values -->
<section class="our-values-sec">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="values-block mb-md-30">
                    <div class="values-block-inner">
                        <div class="icon">
                            <i class="flaticon-global"></i>
                        </div>
                        <h3 class="fw-600"><a routerLink='/service-detail'>Core product develop</a></h3>
                        <p class="fw-500 fs-16">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore</p>
                    </div>
                    <a routerLink='/service-detail' class="theme-btn btn-light full-width">Read More <i class="fal fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="values-block mb-md-30">
                    <div class="values-block-inner">
                        <div class="icon">
                            <i class="flaticon-box-1"></i>
                        </div>
                        <h3 class="fw-600"><a routerLink='/service-detail'>Core product develop</a></h3>
                        <p class="fw-500 fs-16">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore</p>
                    </div>
                    <a routerLink='/service-detail' class="theme-btn btn-light full-width">Read More <i class="fal fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="values-block">
                    <div class="values-block-inner">
                        <div class="icon">
                            <i class="flaticon-innovation"></i>
                        </div>
                        <h3 class="fw-600"><a routerLink='/service-detail'>Core product develop</a></h3>
                        <p class="fw-500 fs-16">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore</p>
                    </div>
                    <a routerLink='/service-detail' class="theme-btn btn-light full-width">Read More <i class="fal fa-arrow-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Our Values -->
<!-- Start Skillset -->
<section class="section-padding skillset-2 count-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="left-skillset mb-md-80">
                    <div class="section-header left-heading pb-0">
                        <div class="section-heading">
                            <h6 class="text-orange mb-xl-10 sub-heading"><span>Our Skillset</span></h6>
                            <h3 class="fw-700 title">Get amazing experience with our cool team<span class="text-orange">.</span></h3>
                            <p class="desc fw-500">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim veniam.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="skill-set-bx normal-bg" style="background-image: url('assets/images/skillset-box.jpg');">
                                <div class="counter">
                                <span class="count text-custom-white">90</span><span class="text-custom-white fs-16">%</span>
                                </div>
                                <p class="text-custom-white">Problem Solving</p>
                            </div>
                        </div>
                        <div class="col-6 skill-box-2">
                            <div class="skill-set-bx normal-bg" style="background-image: url('assets/images/skillset-box.jpg');">
                                <div class="counter">
                                <span class="count text-custom-white">65</span><span class="text-custom-white fs-16">%</span>
                                </div>
                                <p class="text-custom-white">Business Grow</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <img src="assets/images/skillset-2.jpg" class="image-fit" alt="">
            </div>
        </div>
    </div>
</section>
<!-- End Skillset -->
<app-footer-one></app-footer-one>