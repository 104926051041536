<app-header-four></app-header-four>
<!-- Start Subheader -->
<!-- End Subheader -->
<!-- Start News -->
<section class="section-padding">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="blog-style-1 mb-xl-30">
                    <div class="post-img animate-img">
                        <a href='/assets/docs/news/salary.pdf'>
                            <img src="assets/images/news/counties.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="post-date">
                        <span class="date">21</span>
                        <span class="month">Sep</span>
                    </div>
                    <div class="post-content">
                        <div class="post-category">
                            <p class="double-lines text-orange fw-600 mb-1"><span><a href="#" class="text-orange">Finance</a></span></p>
                        </div>
                        <h2 class="post-title"><a href='/assets/docs/news/salary.pdf'>SALARY IMPLEMENTATION 20242025</a></h2>
                        <!-- <ul class="custom-flex post-meta">
                            <li>
                                <a href="#">
                                    <i class="fal fa-user text-orange"></i>
                                    Rosalia D.
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fal fa-calendar-alt text-orange"></i>
                                    24th June 2020
                                </a>
                            </li>
                        </ul> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="blog-style-1 mb-xl-30">
                    <div class="post-img animate-img">
                        <a href='/assets/docs/news/implementation.pdf'>
                            <img src="assets/images/news/salary.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="post-date">
                        <span class="date">21</span>
                        <span class="month">Sep</span>
                    </div>
                    <div class="post-content">
                        <div class="post-category">
                            <p class="double-lines text-orange fw-600 mb-1"><span><a href="#" class="text-orange">Finance</a></span></p>
                        </div>
                        <h2 class="post-title"><a href='/assets/docs/news/implementation.pdf'>NATIONAL GOVERNMENT EMPLOYEE'S SALARY INCREMENT</a></h2>
                        <!-- <ul class="custom-flex post-meta">
                            <li>
                                <a href="#">
                                    <i class="fal fa-user text-orange"></i>
                                    Rosalia D.
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fal fa-calendar-alt text-orange"></i>
                                    18th June 2020
                                </a>
                            </li>
                        </ul> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="blog-style-1 mb-xl-30">
                    <div class="post-img animate-img">
                        <a href='/assets/docs/news/rumeneration.pdf'>
                            <img src="assets/images/news/rumeneration.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="post-date">
                        <span class="date">21</span>
                        <span class="month">Sep</span>
                    </div>
                    <div class="post-content">
                        <div class="post-category">
                            <p class="double-lines text-orange fw-600 mb-1"><span><a href="#" class="text-orange">Finance</a></span></p>
                        </div>
                        <h2 class="post-title"><a href='/assets/docs/news/rumeneration.pdf'>REMUNERATION AND BENEFITS FOR COUNTY GOVERNMENT EMPLOYEES</a></h2>
                        <!-- <ul class="custom-flex post-meta">
                            <li>
                                <a href="#">
                                    <i class="fal fa-user text-orange"></i>
                                    Rosalia D.
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fal fa-calendar-alt text-orange"></i>
                                    04th June 2020
                                </a>
                            </li>
                        </ul> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="blog-style-1 mb-xl-30">
                    <div class="post-img animate-img">
                        <a  href='/assets/docs/news/press.pdf'>
                            <img src="assets/images/news/press.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="post-date">
                        <span class="date">21</span>
                        <span class="month">Sep</span>
                    </div>
                    <div class="post-content">
                        <div class="post-category">
                            <p class="double-lines text-orange fw-600 mb-1"><span><a href="#" class="text-orange">Finance</a></span></p>
                        </div>
                        <h2 class="post-title"><a href='/assets/docs/news/press.pdf'>PRESS RELEASE ON SALARY INCREASE FOR COUNTIES</a></h2>
                        <!-- <ul class="custom-flex post-meta">
                            <li>
                                <a href="#">
                                    <i class="fal fa-user text-orange"></i>
                                    Rosalia D.
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fal fa-calendar-alt text-orange"></i>
                                    24th June 2020
                                </a>
                            </li>
                        </ul> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="blog-style-1 mb-xl-30">
                    <div class="post-img animate-img">
                        <a  href='#'>
                            <img src="assets/images/news/plannings.jpeg" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="post-date">
                        <span class="date">30</span>
                        <span class="month">Sep</span>
                    </div>
                    <div class="post-content">
                        <div class="post-category">
                            <p class="double-lines text-orange fw-600 mb-1"><span><a href="#" class="text-orange">Planning</a></span></p>
                        </div>
                        <h2 class="post-title"><a href='#'>Courtesy call on Kisumu County Public Service Board. Planning for the upcoming 2024 Annual Convention</a></h2>
                        <!-- <ul class="custom-flex post-meta">
                            <li>
                                <a href="#">
                                    <i class="fal fa-user text-orange"></i>
                                    Rosalia D.
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fal fa-calendar-alt text-orange"></i>
                                    24th June 2020
                                </a>
                            </li>
                        </ul> -->
                    </div>
                </div>
            </div>
          
           
           
        </div>
        <div class="row">
            <div class="col-12">
                <ul class="custom pagination justify-content-center mt-4">
                    <li class="page-item"><a class="page-link" href="#"><i class="far fa-angle-double-left"></i></a></li>
                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                    <li class="page-item active"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item"><a class="page-link" href="#">...</a></li>
                    <li class="page-item"><a class="page-link" href="#">10</a></li>
                    <li class="page-item"><a class="page-link" href="#"><i class="far fa-angle-double-right"></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</section>
<!-- End News --> 
<app-footer-one></app-footer-one>