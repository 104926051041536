<div class="sidebar shop-sidebar">
    <div class="widget color-filter-widget mb-xl-30">
        <h5 class="widget-title">Filter By Color</h5>
        <form>
            <label class="custom-checkbox">
                <input type="checkbox" name="#">  
                <span class="checkmark"></span> 
                Red
            </label>
            <label class="custom-checkbox">
                <input type="checkbox" name="#" checked>  
                <span class="checkmark"></span> 
                Blue
            </label>
            <label class="custom-checkbox">
                <input type="checkbox" name="#" checked>  
                <span class="checkmark"></span> 
                White
            </label>
            <label class="custom-checkbox">
                <input type="checkbox" name="#">  
                <span class="checkmark"></span> 
                Green
            </label>
            <label class="custom-checkbox">
                <input type="checkbox" name="#">  
                <span class="checkmark"></span> 
                Voilet
            </label>
            <label class="custom-checkbox">
                <input type="checkbox" name="#">  
                <span class="checkmark"></span> 
                Yellow
            </label>
            <label class="custom-checkbox">
                <input type="checkbox" name="#">  
                <span class="checkmark"></span> 
                Orange
            </label>
        </form>
    </div>
    <div class="widget size-filter-widget mb-xl-30">
        <h5 class="widget-title">Filter By Size</h5>
        <ul class="custom">
            <li>
                <a href="#">XS (56)</a>
            </li>
            <li>
                <a href="#">S (284)</a>
            </li>
            <li>
                <a href="#">M (284)</a>
            </li>
            <li>
                <a href="#">L (284)</a>
            </li>
            <li>
                <a href="#">XL (38)</a>
            </li>
        </ul>
    </div>
    <div class="widget price-filter-widget mb-xl-30">
        <h5 class="widget-title">Filter By Price</h5>
        <div id="shop-range-price"></div>
        <div class="range-slider">
            <input type="text" id="shop-price" readonly>
            <span>-</span>
            <input type="text" id="shop1-price" readonly>
            <p class="no-margin">Filter</p>
        </div>
    </div>
    <div class="widget tag-filter-widget mb-xl-30">
        <h5 class="widget-title">Filter By Tags</h5>
        <div class="tags">
            <a href="#">Accessories</a>
            <a href="#">Clothing</a>
            <a href="#">Fashion</a>
            <a href="#">Footwear</a>
            <a href="#">Good</a>
            <a href="#">Kid</a>
            <a href="#">Men</a>
            <a href="#">Wear</a>
            <a href="#">Women</a>
        </div>
    </div>
    <div class="adv-banner">
        <img src="assets/images/shop-adv.jpg" class="image-fit" alt="">
    </div>
</div>