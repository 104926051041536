<app-header-one></app-header-one>
<!-- Start Subheader -->
<div class="normal-bg subheader" style="background-image: url('assets/images/subheader.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="subheader-content">
                    <h1 class="page-title text-custom-white fw-600">Careers</h1>
                    <ul class="custom-flex breadcrumb">
                        <li><a routerLink='/'>Home</a></li>
                        <li>Careers</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
<!-- Start Careers -->
<section class="section-padding career-sec">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="table-responsive careers-table">
                    <table class="table table-striped">
                        <tbody>
                            <tr>
                                <td>
                                    <div class="icon">
                                        <i class="fab fa-google"></i>
                                    </div>
                                </td>
                                <td>
                                    <h3 class="fw-600 no-margin"><a href="#">Engineer: Observability Applications</a></h3>
                                </td>
                                <td>Status: Full Time</td>
                                <td>Location: Holololo, UK</td>
                                <td>
                                    <a href="#" class="theme-btn btn-white-orange">
                                        Apply Now
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="icon">
                                        <i class="fab fa-facebook-f"></i>
                                    </div>
                                </td>
                                <td>
                                    <h3 class="fw-600 no-margin"><a href="#">UI/UX: Senior UX Designer</a></h3>
                                </td>
                                <td>Status: Full Time</td>
                                <td>Location: Holololo, UK</td>
                                <td>
                                    <a href="#" class="theme-btn btn-light-2">
                                        Apply Now
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="icon">
                                        <i class="fab fa-twitter"></i>
                                    </div>
                                </td>
                                <td>
                                    <h3 class="fw-600 no-margin"><a href="#">Developer: Software Developer</a></h3>
                                </td>
                                <td>Status: Full Time</td>
                                <td>Location: Holololo, UK</td>
                                <td>
                                    <a href="#" class="theme-btn btn-white-orange">
                                        Apply Now
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="icon">
                                        <i class="fab fa-behance"></i>
                                    </div>
                                </td>
                                <td>
                                    <h3 class="fw-600 no-margin"><a href="#">Consultant: Digital Stratigist</a></h3>
                                </td>
                                <td>Status: Full Time</td>
                                <td>Location: Holololo, UK</td>
                                <td>
                                    <a href="#" class="theme-btn btn-light-2">
                                        Apply Now
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="icon">
                                        <i class="fab fa-youtube"></i>
                                    </div>
                                </td>
                                <td>
                                    <h3 class="fw-600 no-margin"><a href="#">Writter: English Translator</a></h3>
                                </td>
                                <td>Status: Full Time</td>
                                <td>Location: Holololo, UK</td>
                                <td>
                                    <a href="#" class="theme-btn btn-white-orange">
                                        Apply Now
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="icon">
                                        <i class="fab fa-linkedin"></i>
                                    </div>
                                </td>
                                <td>
                                    <h3 class="fw-600 no-margin"><a href="#">Support: Support Engineer</a></h3>
                                </td>
                                <td>Status: Full Time</td>
                                <td>Location: Holololo, UK</td>
                                <td>
                                    <a href="#" class="theme-btn btn-light-2">
                                        Apply Now
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="icon">
                                        <i class="fab fa-youtube"></i>
                                    </div>
                                </td>
                                <td>
                                    <h3 class="fw-600 no-margin"><a href="#">Developer: Software Developer</a></h3>
                                </td>
                                <td>Status: Full Time</td>
                                <td>Location: Holololo, UK</td>
                                <td>
                                    <a href="#" class="theme-btn btn-white-orange">
                                        Apply Now
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Careers -->
<!-- Start benefits -->
<section class="section-padding bg-dark-blue">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="section-header left-heading">
                    <div class="section-heading">
                        <h6 class="text-orange mb-xl-10 sub-heading"><span>Others</span></h6>
                        <h3 class="text-custom-white fw-700 title">You Should Apply Here For Move Forward<span class="text-orange">.</span></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 text-lg-right mb-xl-30">
                <a href="#" class="theme-btn btn-orange">Apply Now <i class="fal fa-plus"></i></a>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-xl col-md-4 col-sm-6">
                <div class="benefit-box mb-lg-30">
                    <div class="icon text-orange">
                        <i class="flaticon-safe"></i>
                    </div>
                    <h5 class="fw-600"><a href="#" class="text-custom-white">Health Care</a></h5>
                    <p class="text-custom-white no-margin">Lorem ipsum dolor sit amet, consectetur adipi sicing elit, sed.</p>
                </div>
            </div>
            <div class="col-xl col-md-4 col-sm-6">
                <div class="benefit-box mb-lg-30">
                    <div class="icon text-orange">
                        <i class="flaticon-tax-1"></i>
                    </div>
                    <h5 class="fw-600"><a href="#" class="text-custom-white">Flexibilty</a></h5>
                    <p class="text-custom-white no-margin">Lorem ipsum dolor sit amet, consectetur adipi sicing elit, sed.</p>
                </div>
            </div>
            <div class="col-xl col-md-4 col-sm-6">
                <div class="benefit-box mb-lg-30">
                    <div class="icon text-orange">
                        <i class="flaticon-meeting"></i>
                    </div>
                    <h5 class="fw-600"><a href="#" class="text-custom-white">Meals</a></h5>
                    <p class="text-custom-white no-margin">Lorem ipsum dolor sit amet, consectetur adipi sicing elit, sed.</p>
                </div>
            </div>
            <div class="col-xl col-md-4 col-sm-6">
                <div class="benefit-box mb-sm-30">
                    <div class="icon text-orange">
                        <i class="flaticon-lcd"></i>
                    </div>
                    <h5 class="fw-600"><a href="#" class="text-custom-white">Gaming Zone</a></h5>
                    <p class="text-custom-white no-margin">Lorem ipsum dolor sit amet, consectetur adipi sicing elit, sed.</p>
                </div>
            </div>
            <div class="col-xl col-md-4 col-sm-6">
                <div class="benefit-box">
                    <div class="icon text-orange">
                        <i class="flaticon-target"></i>
                    </div>
                    <h5 class="fw-600"><a href="#" class="text-custom-white">Lifestyle</a></h5>
                    <p class="text-custom-white no-margin">Lorem ipsum dolor sit amet, consectetur adipi sicing elit, sed.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End benefits -->
<!-- Start Blog -->
<section class="section-padding">
    <div class="container">
        <div class="section-header">
            <div class="section-heading">
                <h6 class="text-orange mb-xl-10 sub-heading"><span>News</span></h6>
                <h3 class="text-blue fw-700 title">Get connect with us<span class="text-orange">.</span></h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="blog-slider row">
                    <div class="col-lg-12 slide-item">
                        <div class="blog-style-1">
                            <div class="post-img animate-img">
                                <a routerLink='/news-detail'>
                                    <img src="assets/images/blog-1.jpg" class="image-fit" alt="">
                                </a>
                            </div>
                            <div class="post-date">
                                <span class="date">24</span>
                                <span class="month">June</span>
                            </div>
                            <div class="post-content">
                                <div class="post-category">
                                    <p class="double-lines text-orange fw-600 mb-1"><span><a href="#" class="text-orange">Finance</a></span></p>
                                </div>
                                <h2 class="post-title"><a routerLink='/news-detail'>Improving lives with tech HSE lighthouse project</a></h2>
                                <ul class="custom-flex post-meta">
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-user text-orange"></i>
                                            Rosalia D.
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-calendar-alt text-orange"></i>
                                            24th June 2020
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 slide-item">
                        <div class="blog-style-1">
                            <div class="post-img animate-img">
                                <a routerLink='/news-detail'>
                                    <img src="assets/images/blog-2.jpg" class="image-fit" alt="">
                                </a>
                            </div>
                            <div class="post-date">
                                <span class="date">18</span>
                                <span class="month">June</span>
                            </div>
                            <div class="post-content">
                                <div class="post-category">
                                    <p class="double-lines text-orange fw-600 mb-1"><span><a href="#" class="text-orange">Business</a></span></p>
                                </div>
                                <h2 class="post-title"><a routerLink='/news-detail'>Improving lives with tech HSE lighthouse project</a></h2>
                                <ul class="custom-flex post-meta">
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-user text-orange"></i>
                                            Rosalia D.
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-calendar-alt text-orange"></i>
                                            18th June 2020
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 slide-item">
                        <div class="blog-style-1">
                            <div class="post-img animate-img">
                                <a routerLink='/news-detail'>
                                    <img src="assets/images/blog-1.jpg" class="image-fit" alt="">
                                </a>
                            </div>
                            <div class="post-date">
                                <span class="date">04</span>
                                <span class="month">June</span>
                            </div>
                            <div class="post-content">
                                <div class="post-category">
                                    <p class="double-lines text-orange fw-600 mb-1"><span><a href="#" class="text-orange">Consultancy</a></span></p>
                                </div>
                                <h2 class="post-title"><a routerLink='/news-detail'>Improving lives with tech HSE lighthouse project</a></h2>
                                <ul class="custom-flex post-meta">
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-user text-orange"></i>
                                            Rosalia D.
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-calendar-alt text-orange"></i>
                                            04th June 2020
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog -->
<app-footer-one></app-footer-one>