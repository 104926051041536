<app-header-four></app-header-four>
<!-- Start Subheader -->
<div class="normal-bg subheader" style="background-image: url('assets/images/subheader.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="subheader-content">
                    <h1 class="page-title text-custom-white fw-600">News Details</h1>
                    <ul class="custom-flex breadcrumb">
                        <li><a routerLink='/'>Home</a></li>
                        <li><a routerLink='/news-standard'>News</a></li>
                        <li>News Details</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
<!-- Start News -->
<section class="section-padding bg-light-white">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="post-details mb-md-80">
                    <ul class="custom-flex post-category">
                        <li>
                            <a href="#">Business</a>
                        </li>
                    </ul>
                    <h2 class="post-title">
                        <a href="#">Lorem ipsum dolor sit amet, consecte cing elit, sed do eiusmod tempor.</a>
                    </h2>
                    <!-- post meta -->
                    <ul class="custom-flex post-meta">
                        <li>
                            <a href="#">
                                <i class="fal fa-user"></i>
                                by Piklo D. Sindom
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fal fa-calendar-alt"></i>
                                24th March 2020
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fal fa-comments"></i>
                                35 Comments
                            </a>
                        </li>
                    </ul>
                    <p class="fw-500">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>
                    <p class="fw-500">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt.</p>
                    <!-- post image -->
                    <div class="post-image animate-img">
                        <a href="#">
                            <img src="assets/images/blog-standard-2.jpg" class="image-fit" alt="">
                        </a>
                    </div>
                    <h3 class="fw-600">A cleansing hot shower or bath</h3>
                    <p class="fw-500 mb-xl-40">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. </p>
                    <h3 class="fw-600">Setting the mood with incense</h3>
                    <p class="fw-500 mb-xl-40">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. </p>
                    <h3 class="fw-600">Setting the mood with incense</h3>
                    <ul class="mb-xl-40">
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do</li>
                    </ul>
                    <!-- post quote -->
                    <blockquote class="bg-orange">
                        <cite class="text-custom-white">by Hetmayer</cite>
                        <h3 class="fw-600 text-custom-white">Viral dreamcatcher keytar typewriter, aest hetic offal umami. Aesthetic polaroid pug pitchfork post-ironic.</h3>
                    </blockquote>
                    <p class="fw-500 mb-xl-40">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium. </p>
                    <figure class="image-alignment left">
                        <img src="assets/images/blog-detail-left.jpg" class="image-fit" alt="">
                    </figure>
                    <p class="fw-500 mb-xl-40">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium. </p>
                    <p class="fw-500 mb-xl-40">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium. </p>
                    <!--post tags -->
                    <div class="post-tags-media">
                        <div class="post-tags popular-tag-widget mb-xl-40">
                            <h5 class="fw-500">Related Tags</h5>
                            <div class="tags">
                                <a href="#">Popular</a>
                                <a href="#">Design</a>
                                <a href="#">UX</a>
                            </div>
                        </div>
                        <div class="post-social-media mb-xl-40">
                            <h5 class="fw-500">Social Share</h5>
                            <ul class="custom-flex">
                                <li>
                                    <a href="#">
                                        <i class="fab fa-facebook-f"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fab fa-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fab fa-vimeo-v"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fab fa-skype"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i class="fab fa-tumblr"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- post navigation -->
                    <div class="detail-post-navigation">
                        <div class="prev-post">
                            <span>Prev Post</span>
                            <a href="#">Tips On Minimalist</a>
                        </div>
                        <span class="icon">
                            <img src="assets/images/grid-icon.png" alt="icon">
                        </span>
                        <div class="next-post">
                            <span>Next Post</span>
                            <a href="#">Less Is More</a>
                        </div>
                    </div>
                    <!-- related post -->
                    <h3 class="fw-600">Related Post</h3>
                    <div class="related-post row mb-xl-40">
                        <div class="col-12 slide-item">
                            <div class="blog-style-2">
                                <div class="post-image animate-img">
                                    <a href="#">
                                        <img src="assets/images/related-post-1.jpg" class="image-fit" alt="">
                                    </a>
                                </div>
                                <div class="post-content">
                                    <div class="post-date">
                                        <a href="#">
                                            <i class="fal fa-calendar-alt"></i>
                                            24th March 2020
                                        </a>
                                    </div>
                                    <h4 class="fw-500 post-title"><a href="#">A series of iOS 7 inspire vector icons sense.</a></h4>
                                    <p class="fw-500 no-margin">Lorem ipsum dolor sit amet, conse ctet ur adipisicing elit, sed doing.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 slide-item">
                            <div class="blog-style-2">
                                <div class="post-image animate-img">
                                    <a href="#">
                                        <img src="assets/images/related-post-2.jpg" class="image-fit" alt="">
                                    </a>
                                </div>
                                <div class="post-content">
                                    <div class="post-date">
                                        <a href="#">
                                            <i class="fal fa-calendar-alt"></i>
                                            24th March 2020
                                        </a>
                                    </div>
                                    <h4 class="fw-500 post-title"><a href="#">A series of iOS 7 inspire vector icons sense.</a></h4>
                                    <p class="fw-500 no-margin">Lorem ipsum dolor sit amet, conse ctet ur adipisicing elit, sed doing.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 slide-item">
                            <div class="blog-style-2">
                                <div class="post-image animate-img">
                                    <a href="#">
                                        <img src="assets/images/related-post-1.jpg" class="image-fit" alt="">
                                    </a>
                                </div>
                                <div class="post-content">
                                    <div class="post-date">
                                        <a href="#">
                                            <i class="fal fa-calendar-alt"></i>
                                            24th March 2020
                                        </a>
                                    </div>
                                    <h4 class="fw-500 post-title"><a href="#">A series of iOS 7 inspire vector icons sense.</a></h4>
                                    <p class="fw-500 no-margin">Lorem ipsum dolor sit amet, conse ctet ur adipisicing elit, sed doing.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- post author -->
                    <div class="post-author mb-xl-40">
                        <div class="author-image">
                            <img src="assets/images/author-big.jpg" class="image-fit" alt="">
                        </div>
                        <div class="author-content">
                            <cite class="fw-500">Written by</cite>
                            <h3 class="fw-600">Rosalina D. William</h3>
                            <p class="fw-500 no-margin">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation is enougn for today.</p>
                        </div>
                    </div>
                    <!-- Comments -->
                    <div class="post-comments">
                        <h3 class="fw-600 mb-xl-40">Comments</h3>
                        <ul class="comments-list custom mb-xl-40">
                            <li>
                                <div class="comment-img">
                                    <img src="assets/images/comment-1.jpg" alt="img">
                                </div>
                                <div class="comment-desc">
                                    <div class="desc-top">
                                        <h6>Rosalina Kelian</h6>
                                        <span class="date">19th May 2020</span>
                                        <a href="#" class="reply-link"><i class="far fa-reply"></i>Reply</a>
                                    </div>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                        consequat.
                                    </p>
                                </div>
                                <ul class="children custom">
                                    <li>
                                        <div class="comment-img">
                                            <img src="assets/images/comment-2.jpg" alt="img">
                                        </div>
                                        <div class="comment-desc">
                                            <div class="desc-top">
                                                <h6>Rosalina Kelian<span class="saved"><i class="fas fa-bookmark"></i></span></h6>
                                                <span class="date">19th May 2020</span>
                                                <a href="#" class="reply-link"><i class="far fa-reply"></i>Reply</a>
                                            </div>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                                                ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                aliquip ex ea commodo consequat.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <div class="comment-img">
                                    <img src="assets/images/comment-3.jpg" alt="img">
                                </div>
                                <div class="comment-desc">
                                    <div class="desc-top">
                                        <h6>Arista Williamson</h6>
                                        <span class="date">19th May 2020</span>
                                        <a href="#" class="reply-link"><i class="far fa-reply"></i>Reply</a>
                                    </div>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                        consequat.
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="comment-form">
                        <h3 class="fw-600 mb-xl-40">Post Comments</h3>
                        <form action="#" method="POST">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-box form-group">
                                        <textarea name="#" rows="6" class="form-control form-control-custom" placeholder="Type your comments..."></textarea>
                                        <span class="icon text-orange">
                                            <i class="fal fa-pencil-alt"></i>
                                        </span>
                                    </div>
                                    <div class="form-box form-group">
                                        <input type="text" name="#" class="form-control form-control-custom" placeholder="Type your name..."/>
                                        <span class="icon text-orange">
                                            <i class="fal fa-user"></i>
                                        </span>
                                    </div>
                                    <div class="form-box form-group">
                                        <input type="email" name="#" class="form-control form-control-custom" placeholder="Type your email..."/>
                                        <span class="icon text-orange">
                                            <i class="fal fa-envelope"></i>
                                        </span>
                                    </div>
                                    <div class="form-box form-group">
                                        <input type="text" name="#" class="form-control form-control-custom" placeholder="Type your website..."/>
                                        <span class="icon text-orange">
                                            <i class="fal fa-globe"></i>
                                        </span>
                                    </div>
                                    <button type="submit" class="theme-btn btn-orange">Post Comment <i class="fal fa-plus"></i></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <aside class="col-lg-4">
                <app-news-sidebar></app-news-sidebar>
            </aside>
        </div>
    </div>
</section>
<!-- End News -->
<app-footer-one></app-footer-one>