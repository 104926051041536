<app-header-three></app-header-three>
<!-- Start Banner -->
<div class="main-banner-3 normal-bg" style="background-image: url('assets/images/main-banner-3.jpg');">
    <div class="transform-center">
        <div class="main-slider-3">
            <div class="slide-item">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-8">
                            <div class="banner-text">
                                <ul class="custom-flex list-text">
                                    <li class="text-custom-white fw-600 fs-20">Finance</li>
                                    <li class="text-custom-white fw-600 fs-20">Planning</li>
                                    <li class="text-custom-white fw-600 fs-20">Growth</li>
                                </ul>
                                <h1 class="fw-600 text-custom-white heading-title">Prosfer with a good planning assets.</h1>
                                <a routerLink='/service-1' class="theme-btn btn-red mr-3">Our Services <i class="fal fa-plus"></i></a>
                                <a href="#" class="theme-btn btn-border">Learn More <i class="fal fa-plus"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="slide-item">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-8">
                            <div class="banner-text">
                                <ul class="custom-flex list-text">
                                    <li class="text-custom-white fw-600 fs-20">Finance</li>
                                    <li class="text-custom-white fw-600 fs-20">Planning</li>
                                    <li class="text-custom-white fw-600 fs-20">Growth</li>
                                </ul>
                                <h2 class="fw-600 text-custom-white heading-title">Prosfer with a good planning assets.</h2>
                                <a routerLink='/service-1' class="theme-btn btn-red mr-3">Our Services <i class="fal fa-plus"></i></a>
                                <a href="#" class="theme-btn btn-border">Learn More <i class="fal fa-plus"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="slide-item">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-8">
                            <div class="banner-text">
                                <ul class="custom-flex list-text">
                                    <li class="text-custom-white fw-600 fs-20">Finance</li>
                                    <li class="text-custom-white fw-600 fs-20">Planning</li>
                                    <li class="text-custom-white fw-600 fs-20">Growth</li>
                                </ul>
                                <h2 class="fw-600 text-custom-white heading-title">Prosfer with a good planning assets.</h2>
                                <a routerLink='/service-1' class="theme-btn btn-red mr-3">Our Services <i class="fal fa-plus"></i></a>
                                <a href="#" class="theme-btn btn-border">Learn More <i class="fal fa-plus"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Banner -->
<!-- Start brand slider -->
<div class="section-padding clients style-2 bg-light count-section">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-md-10">
                <div class="brand-slider row">
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/clients-red-1.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/clients-red-2.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/clients-red-3.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/clients-red-4.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/clients-red-5.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/clients-red-1.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/clients-red-2.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/clients-red-3.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/clients-red-4.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/clients-red-5.png" class="image-fit" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End brand slider -->
<!-- Start about us -->
<section class="section-padding aboutus-3">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="section-header left-heading">
                    <div class="section-heading">
                        <h6 class="text-red mb-xl-10 sub-heading"><span>About Us</span></h6>
                        <h3 class="fw-700 title">Core level values and amazing team<span class="text-red">.</span></h3>
                        <p class="desc fw-500">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim veniam.</p>
                    </div>
                </div>
                <ul class="custom about-list">
                    <li>
                        <span>
                            <i class="fal fa-check"></i>
                        </span>
                        <div class="content">
                            <h5 class="fw-600"><a href="#">Mission and vision</a></h5>
                            <p class="fw-500">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                        </div>
                    </li>
                    <li>
                        <span>
                            <i class="fal fa-check"></i>
                        </span>
                        <div class="content">
                            <h5 class="fw-600"><a href="#">Expert team with good portfolio</a></h5>
                            <p class="fw-500">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="col-lg-6">
                <div class="right-side p-relative">
                    <img src="assets/images/about-3.jpg" class="image-fit" alt="">
                    <div class="about-counter">
                        <div>
                            <span class="count">40</span><span class="text-custom-white">+</span>
                            <p class="no-margin fs-12 text-custom-white">Years Of Experience</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End about us -->
<!-- Start tabs Services -->
<section class="section-padding bg-light">
    <div class="container">
        <div class="section-header">
            <div class="section-heading">
                <h6 class="text-red mb-xl-10 sub-heading"><span>Services</span></h6>
                <h3 class="fw-700 title">What kind solution we provide here<span class="text-red">.</span></h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="custom-tabs">
                    <ul class="custom-flex nav nav-tabs row">
                        <li class="nav-item col-lg-2 col-sm-4 col-6">
                            <a href="#vattax" class="nav-link" data-toggle="tab">
                                <i class="flaticon-receipt"></i>
                                <span class="fw-500">Vat Tax</span>
                            </a>
                        </li>
                        <li class="nav-item col-lg-2 col-sm-4 col-6">
                            <a href="#finance" class="nav-link active" data-toggle="tab">
                                <i class="flaticon-stats"></i>
                                <span class="fw-500">Finance</span>
                            </a>
                        </li>
                        <li class="nav-item col-lg-2 col-sm-4 col-6">
                            <a href="#homeloan" class="nav-link" data-toggle="tab">
                                <i class="flaticon-credit"></i>
                                <span class="fw-500">Home Loan</span>
                            </a>
                        </li>
                        <li class="nav-item col-lg-2 col-sm-4 col-6">
                            <a href="#banking" class="nav-link" data-toggle="tab">
                                <i class="flaticon-payment-method"></i>
                                <span class="fw-500">Banking</span>
                            </a>
                        </li>
                        <li class="nav-item col-lg-2 col-sm-4 col-6">
                            <a href="#jobfeed" class="nav-link" data-toggle="tab">
                                <i class="flaticon-suitcase"></i>
                                <span class="fw-500">Job Feed</span>
                            </a>
                        </li>
                        <li class="nav-item col-lg-2 col-sm-4 col-6">
                            <a href="#counsultancy" class="nav-link" data-toggle="tab">
                                <i class="flaticon-presentation"></i>
                                <span class="fw-500">Consultancy</span>
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade" id="vattax">
                            <div class="tab-inner">
                                <div class="row">
                                    <div class="col-lg-5">
                                        <img src="assets/images/service-tab-1.jpg" class="image-fit" alt="">
                                    </div>
                                    <div class="col-lg-7">
                                        <div class="right-side">
                                            <p class="double-lines fw-500 text-red mb-1"><span>Vat Tax</span></p>
                                            <h3 class="fw-600"><a href="#">Miranda halim it insurance business need consultancy support.</a></h3>
                                            <p class="fw-500">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p>
                                            <a routerLink='/project-detail' class="theme-btn btn-red">View Case Study</a>
                                            <i class="flaticon-receipt"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade active show" id="finance">
                            <div class="tab-inner">
                                <div class="row">
                                    <div class="col-lg-5">
                                        <img src="assets/images/service-tab-1.jpg" class="image-fit" alt="">
                                    </div>
                                    <div class="col-lg-7">
                                        <div class="right-side">
                                            <p class="double-lines fw-500 text-red mb-1"><span>Finance</span></p>
                                            <h3 class="fw-600"><a href="#">Miranda halim it insurance business need consultancy support.</a></h3>
                                            <p class="fw-500">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p>
                                            <a routerLink='/project-detail' class="theme-btn btn-red">View Case Study</a>
                                            <i class="flaticon-stats"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="homeloan">
                            <div class="tab-inner">
                                <div class="row">
                                    <div class="col-lg-5">
                                        <img src="assets/images/service-tab-1.jpg" class="image-fit" alt="">
                                    </div>
                                    <div class="col-lg-7">
                                        <div class="right-side">
                                            <p class="double-lines fw-500 text-red mb-1"><span>Home Loan</span></p>
                                            <h3 class="fw-600"><a href="#">Miranda halim it insurance business need consultancy support.</a></h3>
                                            <p class="fw-500">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p>
                                            <a routerLink='/project-detail' class="theme-btn btn-red">View Case Study</a>
                                            <i class="flaticon-credit"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="banking">
                            <div class="tab-inner">
                                <div class="row">
                                    <div class="col-lg-5">
                                        <img src="assets/images/service-tab-1.jpg" class="image-fit" alt="">
                                    </div>
                                    <div class="col-lg-7">
                                        <div class="right-side">
                                            <p class="double-lines fw-500 text-red mb-1"><span>Banking</span></p>
                                            <h3 class="fw-600"><a href="#">Miranda halim it insurance business need consultancy support.</a></h3>
                                            <p class="fw-500">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p>
                                            <a routerLink='/project-detail' class="theme-btn btn-red">View Case Study</a>
                                            <i class="flaticon-payment-method"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="jobfeed">
                            <div class="tab-inner">
                                <div class="row">
                                    <div class="col-lg-5">
                                        <img src="assets/images/service-tab-1.jpg" class="image-fit" alt="">
                                    </div>
                                    <div class="col-lg-7">
                                        <div class="right-side">
                                            <p class="double-lines fw-500 text-red mb-1"><span>Job Feed</span></p>
                                            <h3 class="fw-600"><a href="#">Miranda halim it insurance business need consultancy support.</a></h3>
                                            <p class="fw-500">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p>
                                            <a routerLink='/project-detail' class="theme-btn btn-red">View Case Study</a>
                                            <i class="flaticon-suitcase"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="counsultancy">
                            <div class="tab-inner">
                                <div class="row">
                                    <div class="col-lg-5">
                                        <img src="assets/images/service-tab-1.jpg" class="image-fit" alt="">
                                    </div>
                                    <div class="col-lg-7">
                                        <div class="right-side">
                                            <p class="double-lines fw-500 text-red mb-1"><span>Counsultancy</span></p>
                                            <h3 class="fw-600"><a href="#">Miranda halim it insurance business need consultancy support.</a></h3>
                                            <p class="fw-500">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p>
                                            <a routerLink='/project-detail' class="theme-btn btn-red">View Case Study</a>
                                            <i class="flaticon-presentation"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End tabs Services -->
<!-- Start why choose us -->
<section class="section-padding">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="section-header left-heading">
                    <div class="section-heading">
                        <h6 class="text-red mb-xl-10 sub-heading"><span>Why choose us</span></h6>
                        <h3 class="fw-700 title">See our impressions and what we have<span class="text-red">.</span></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 text-lg-right mb-xl-30">
                <a href="#" class="theme-btn btn-red">Price & Plans</a>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="choose-us-box mb-md-30">
                    <div class="top-sec">
                        <div class="icon">
                            <i class="flaticon-statistics text-red"></i>
                        </div>
                        <h4 class="fw-600 no-margin"><a routerLink='/service-detail'>Digital consultancy</a></h4>
                    </div>
                    <p class="fw-500 mb-xl-10">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua nim ad minim veniam. </p>
                    <a routerLink='/service-detail' class="fw-600 fs-14 link">Read More <i class="fas fa-long-arrow-right text-red"></i></a>
                    <i class="flaticon-statistics bg-icon"></i>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="choose-us-box mb-md-30">
                    <div class="top-sec">
                        <div class="icon">
                            <i class="flaticon-market text-red"></i>
                        </div>
                        <h4 class="fw-600 no-margin"><a routerLink='/service-detail'>Market Research</a></h4>
                    </div>
                    <p class="fw-500 mb-xl-10">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua nim ad minim veniam. </p>
                    <a routerLink='/service-detail' class="fw-600 fs-14 link">Read More <i class="fas fa-long-arrow-right text-red"></i></a>
                    <i class="flaticon-market bg-icon"></i>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="choose-us-box">
                    <div class="top-sec">
                        <div class="icon">
                            <i class="flaticon-investment text-red"></i>
                        </div>
                        <h4 class="fw-600 no-margin"><a routerLink='/service-detail'>Productivity</a></h4>
                    </div>
                    <p class="fw-500 mb-xl-10">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua nim ad minim veniam. </p>
                    <a routerLink='/service-detail' class="fw-600 fs-14 link">Read More <i class="fas fa-long-arrow-right text-red"></i></a>
                    <i class="flaticon-investment bg-icon"></i>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End why choose us -->
<!-- Start faqs -->
<section class="bg-light section-padding faqs-style-2">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-xl-6 col-lg-7">
                <div class="images-box mb-md-80">
                    <img src="assets/images/faq-img-1.jpg" class="image-fit image-1" alt="">
                    <img src="assets/images/faq-img-2.jpg" class="image-fit image-2" alt="">
                </div>
            </div>
            <div class="col-xl-6 col-lg-5">
                <div class="left-side">
                    <div class="section-header left-heading">
                        <div class="section-heading">
                            <h6 class="text-red mb-xl-10 sub-heading"><span>FAQ</span></h6>
                            <h3 class="fw-700 title">You have to know before start everything<span class="text-red">.</span></h3>
                        </div>
                    </div>
                    <div id="accordion" class="custom-faqs style-2">
                        <div class="card">
                            <div class="card-header">
                                <a class="collapsed card-link" data-toggle="collapse" href="#collapseOne">
                                    How to import my whole data?
                                    <span>
                                        <i class="fal fa-arrow-down"></i>
                                    </span>
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse" data-parent="#accordion">
                                <div class="card-body">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco lab oris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header">
                                <a class="card-link" data-toggle="collapse" href="#collapseTwo">
                                    How to import my whole data?
                                    <span>
                                        <i class="fal fa-arrow-down"></i>
                                    </span>
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse show" data-parent="#accordion">
                                <div class="card-body">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco lab oris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header">
                                <a class="collapsed card-link" data-toggle="collapse" href="#collapseThree">
                                    How to import my whole data?
                                    <span>
                                        <i class="fal fa-arrow-down"></i>
                                    </span>
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" data-parent="#accordion">
                                <div class="card-body">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco lab oris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End faqs -->
<!-- Start growth -->
<section class="section-padding growth-sec">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="section-header left-heading pb-0 mb-md-40">
                    <div class="section-heading">
                        <h6 class="text-red mb-xl-10 sub-heading"><span>Growth Level</span></h6>
                        <h3 class="fw-700 title">See our work impression and activity here<span class="text-red">.</span></h3>
                        <p class="desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim veniam.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div id="bar-chart" class="full-height"></div>
                <ul class="custom-flex chart-label">
                    <li>
                        <span></span>
                        Profit
                    </li>
                    <li>
                        <span></span>
                        Growth
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>
<!-- End growth -->
<!-- Start Gallery -->
<section class="section-padding-bottom slider-gallery-sec style-2 p-relative">
    <div class="container-fluid no-padding">
        <div class="row">
            <div class="col-12">
                <div class="slider-gallery row arrow-layout">
                    <div class="col-12 slide-item">
                        <img src="assets/images/gallery-1.jpg" class="image-fit" alt="">
                        <div class="gallery-text">
                            <p class="text-red mb-xl-10 fw-600 double-lines"><span>Consultancy</span></p>
                            <h3 class="fw-600"><a routerLink='/project-detail'>Miranda halim group consultancy</a></h3>
                        </div>
                    </div>
                    <div class="col-12 slide-item">
                        <img src="assets/images/gallery-2.jpg" class="image-fit" alt="">
                        <div class="gallery-text">
                            <p class="text-red mb-xl-10 fw-600 double-lines"><span>Consultancy</span></p>
                            <h3 class="fw-600"><a routerLink='/project-detail'>Miranda halim group consultancy</a></h3>
                        </div>
                    </div>
                    <div class="col-12 slide-item">
                        <img src="assets/images/gallery-3.jpg" class="image-fit" alt="">
                        <div class="gallery-text">
                            <p class="text-red mb-xl-10 fw-600 double-lines"><span>Consultancy</span></p>
                            <h3 class="fw-600"><a routerLink='/project-detail'>Miranda halim group consultancy</a></h3>
                        </div>
                    </div>
                    <div class="col-12 slide-item">
                        <img src="assets/images/gallery-4.jpg" class="image-fit" alt="">
                        <div class="gallery-text">
                            <p class="text-red mb-xl-10 fw-600 double-lines"><span>Consultancy</span></p>
                            <h3 class="fw-600"><a routerLink='/project-detail'>Miranda halim group consultancy</a></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Gallery -->
<!-- Start Blog -->
<section class="section-padding bg-light">
    <div class="container">
        <div class="section-header">
            <div class="section-heading">
                <h6 class="text-red mb-xl-10 sub-heading"><span>Insights</span></h6>
                <h3 class="fw-700 title">News and feeds<span class="text-red">.</span></h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="blog-slider row">
                    <div class="col-lg-12 slide-item">
                        <div class="blog-style-1 style-2">
                            <div class="post-img animate-img">
                                <a routerLink='/news-detail'>
                                    <img src="assets/images/blog-1.jpg" class="image-fit" alt="">
                                </a>
                            </div>
                            <div class="post-date">
                                <span class="date">24</span>
                                <span class="month">June</span>
                            </div>
                            <div class="post-content">
                                <div class="post-category">
                                    <p class="double-lines text-red fw-600 mb-1"><span><a href="#" class="text-red">Finance</a></span></p>
                                </div>
                                <h2 class="post-title"><a routerLink='/news-detail'>Improving lives with tech HSE lighthouse project</a></h2>
                                <ul class="custom-flex post-meta">
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-user text-red"></i>
                                            Rosalia D.
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-calendar-alt text-red"></i>
                                            24th June 2020
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <!-- blog overlay -->
                            <div class="post-overlay normal-bg" style="background-image: url('assets/images/blog-overlay-1.jpg');">
                                <div class="post-overlay-meta">
                                    <div class="post-category">
                                        <p class="double-lines text-custom-white fw-600 mb-1"><span><a href="#" class="text-custom-white">Finance</a></span></p>
                                    </div>
                                    <h2 class="post-title"><a routerLink='/news-detail' class="text-custom-white">Improving lives with tech HSE lighthouse project</a></h2>
                                    <ul class="custom-flex post-meta">
                                    <li>
                                        <a href="#" class="text-custom-white">
                                            <i class="fal fa-user text-custom-white"></i>
                                            Rosalia D.
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="text-custom-white">
                                            <i class="fal fa-calendar-alt text-custom-white"></i>
                                            24th June 2020
                                        </a>
                                    </li>
                                </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 slide-item">
                        <div class="blog-style-1 style-2">
                            <div class="post-img animate-img">
                                <a routerLink='/news-detail'>
                                    <img src="assets/images/blog-2.jpg" class="image-fit" alt="">
                                </a>
                            </div>
                            <div class="post-date">
                                <span class="date">18</span>
                                <span class="month">June</span>
                            </div>
                            <div class="post-content">
                                <div class="post-category">
                                    <p class="double-lines text-red fw-600 mb-1"><span><a href="#" class="text-red">Business</a></span></p>
                                </div>
                                <h2 class="post-title"><a routerLink='/news-detail'>Improving lives with tech HSE lighthouse project</a></h2>
                                <ul class="custom-flex post-meta">
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-user text-red"></i>
                                            Rosalia D.
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-calendar-alt text-red"></i>
                                            18th June 2020
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <!-- blog overlay -->
                            <div class="post-overlay normal-bg" style="background-image: url('assets/images/blog-overlay-1.jpg');">
                                <div class="post-overlay-meta">
                                    <div class="post-category">
                                        <p class="double-lines text-custom-white fw-600 mb-1"><span><a href="#" class="text-custom-white">Finance</a></span></p>
                                    </div>
                                    <h2 class="post-title"><a routerLink='/news-detail' class="text-custom-white">Improving lives with tech HSE lighthouse project</a></h2>
                                    <ul class="custom-flex post-meta">
                                    <li>
                                        <a href="#" class="text-custom-white">
                                            <i class="fal fa-user text-custom-white"></i>
                                            Rosalia D.
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="text-custom-white">
                                            <i class="fal fa-calendar-alt text-custom-white"></i>
                                            24th June 2020
                                        </a>
                                    </li>
                                </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 slide-item">
                        <div class="blog-style-1 style-2">
                            <div class="post-img animate-img">
                                <a routerLink='/news-detail'>
                                    <img src="assets/images/blog-1.jpg" class="image-fit" alt="">
                                </a>
                            </div>
                            <div class="post-date">
                                <span class="date">04</span>
                                <span class="month">June</span>
                            </div>
                            <div class="post-content">
                                <div class="post-category">
                                    <p class="double-lines text-red fw-600 mb-1"><span><a href="#" class="text-red">Consultancy</a></span></p>
                                </div>
                                <h2 class="post-title"><a routerLink='/news-detail'>Improving lives with tech HSE lighthouse project</a></h2>
                                <ul class="custom-flex post-meta">
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-user text-red"></i>
                                            Rosalia D.
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-calendar-alt text-red"></i>
                                            04th June 2020
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <!-- blog overlay -->
                            <div class="post-overlay normal-bg" style="background-image: url('assets/images/blog-overlay-1.jpg');">
                                <div class="post-overlay-meta">
                                    <div class="post-category">
                                        <p class="double-lines text-custom-white fw-600 mb-1"><span><a href="#" class="text-custom-white">Finance</a></span></p>
                                    </div>
                                    <h2 class="post-title"><a routerLink='/news-detail' class="text-custom-white">Improving lives with tech HSE lighthouse project</a></h2>
                                    <ul class="custom-flex post-meta">
                                    <li>
                                        <a href="#" class="text-custom-white">
                                            <i class="fal fa-user text-custom-white"></i>
                                            Rosalia D.
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="text-custom-white">
                                            <i class="fal fa-calendar-alt text-custom-white"></i>
                                            24th June 2020
                                        </a>
                                    </li>
                                </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog -->
<app-footer-three></app-footer-three>