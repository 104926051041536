<app-header-one></app-header-one>
<!-- Start Subheader -->
<div class="normal-bg subheader" style="background-image: url('assets/images/subheader.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="subheader-content">
                    <h1 class="page-title text-custom-white fw-600">Our Portfolio</h1>
                    <ul class="custom-flex breadcrumb">
                        <li><a routerLink='/'>Home</a></li>
                        <li>Portfolio</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
<!-- Start Portfolio -->
<section class="section-padding">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="portfolio-box mb-xl-30">
                    <div class="portfolio-img animate-img">
                        <a routerLink='/project-detail'>
                            <img src="assets/images/portfolio-1.jpg" class="image-fit" alt="">
                        </a>
                        <div class="portfolio-overlay">
                            <div class="portfolio-text">
                                <p class="text-orange double-lines fw-500 mb-1"><span>Consultancy</span></p>
                                <h4 class="fw-600 no-margin"><a routerLink='/project-detail' class="text-custom-white">Miranda box company business solutions <span class="text-orange">.</span></a></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="portfolio-box mb-xl-30">
                    <div class="portfolio-img animate-img">
                        <a routerLink='/project-detail'>
                            <img src="assets/images/portfolio-2.jpg" class="image-fit" alt="">
                        </a>
                        <div class="portfolio-overlay">
                            <div class="portfolio-text">
                                <p class="text-orange double-lines fw-500 mb-1"><span>Consultancy</span></p>
                                <h4 class="fw-600 no-margin"><a routerLink='/project-detail' class="text-custom-white">Miranda box company business solutions <span class="text-orange">.</span></a></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="portfolio-box mb-xl-30">
                    <div class="portfolio-img animate-img">
                        <a routerLink='/project-detail'>
                            <img src="assets/images/portfolio-3.jpg" class="image-fit" alt="">
                        </a>
                        <div class="portfolio-overlay">
                            <div class="portfolio-text">
                                <p class="text-orange double-lines fw-500 mb-1"><span>Consultancy</span></p>
                                <h4 class="fw-600 no-margin"><a routerLink='/project-detail' class="text-custom-white">Miranda box company business solutions <span class="text-orange">.</span></a></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="portfolio-box mb-xl-30">
                    <div class="portfolio-img animate-img">
                        <a routerLink='/project-detail'>
                            <img src="assets/images/portfolio-4.jpg" class="image-fit" alt="">
                        </a>
                        <div class="portfolio-overlay">
                            <div class="portfolio-text">
                                <p class="text-orange double-lines fw-500 mb-1"><span>Consultancy</span></p>
                                <h4 class="fw-600 no-margin"><a routerLink='/project-detail' class="text-custom-white">Miranda box company business solutions <span class="text-orange">.</span></a></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="portfolio-box mb-xl-30">
                    <div class="portfolio-img animate-img">
                        <a routerLink='/project-detail'>
                            <img src="assets/images/portfolio-5.jpg" class="image-fit" alt="">
                        </a>
                        <div class="portfolio-overlay">
                            <div class="portfolio-text">
                                <p class="text-orange double-lines fw-500 mb-1"><span>Consultancy</span></p>
                                <h4 class="fw-600 no-margin"><a routerLink='/project-detail' class="text-custom-white">Miranda box company business solutions <span class="text-orange">.</span></a></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="portfolio-box mb-xl-30">
                    <div class="portfolio-img animate-img">
                        <a routerLink='/project-detail'>
                            <img src="assets/images/portfolio-6.jpg" class="image-fit" alt="">
                        </a>
                        <div class="portfolio-overlay">
                            <div class="portfolio-text">
                                <p class="text-orange double-lines fw-500 mb-1"><span>Consultancy</span></p>
                                <h4 class="fw-600 no-margin"><a routerLink='/project-detail' class="text-custom-white">Miranda box company business solutions <span class="text-orange">.</span></a></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="portfolio-box mb-xl-30">
                    <div class="portfolio-img animate-img">
                        <a routerLink='/project-detail'>
                            <img src="assets/images/portfolio-7.jpg" class="image-fit" alt="">
                        </a>
                        <div class="portfolio-overlay">
                            <div class="portfolio-text">
                                <p class="text-orange double-lines fw-500 mb-1"><span>Consultancy</span></p>
                                <h4 class="fw-600 no-margin"><a routerLink='/project-detail' class="text-custom-white">Miranda box company business solutions <span class="text-orange">.</span></a></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="portfolio-box mb-xl-30">
                    <div class="portfolio-img animate-img">
                        <a routerLink='/project-detail'>
                            <img src="assets/images/portfolio-8.jpg" class="image-fit" alt="">
                        </a>
                        <div class="portfolio-overlay">
                            <div class="portfolio-text">
                                <p class="text-orange double-lines fw-500 mb-1"><span>Consultancy</span></p>
                                <h4 class="fw-600 no-margin"><a routerLink='/project-detail' class="text-custom-white">Miranda box company business solutions <span class="text-orange">.</span></a></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="portfolio-box mb-xl-30">
                    <div class="portfolio-img animate-img">
                        <a routerLink='/project-detail'>
                            <img src="assets/images/portfolio-9.jpg" class="image-fit" alt="">
                        </a>
                        <div class="portfolio-overlay">
                            <div class="portfolio-text">
                                <p class="text-orange double-lines fw-500 mb-1"><span>Consultancy</span></p>
                                <h4 class="fw-600 no-margin"><a routerLink='/project-detail' class="text-custom-white">Miranda box company business solutions <span class="text-orange">.</span></a></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="portfolio-box mb-md-30">
                    <div class="portfolio-img animate-img">
                        <a routerLink='/project-detail'>
                            <img src="assets/images/portfolio-10.jpg" class="image-fit" alt="">
                        </a>
                        <div class="portfolio-overlay">
                            <div class="portfolio-text">
                                <p class="text-orange double-lines fw-500 mb-1"><span>Consultancy</span></p>
                                <h4 class="fw-600 no-margin"><a routerLink='/project-detail' class="text-custom-white">Miranda box company business solutions <span class="text-orange">.</span></a></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="portfolio-box mb-md-30">
                    <div class="portfolio-img animate-img">
                        <a routerLink='/project-detail'>
                            <img src="assets/images/portfolio-11.jpg" class="image-fit" alt="">
                        </a>
                        <div class="portfolio-overlay">
                            <div class="portfolio-text">
                                <p class="text-orange double-lines fw-500 mb-1"><span>Consultancy</span></p>
                                <h4 class="fw-600 no-margin"><a routerLink='/project-detail' class="text-custom-white">Miranda box company business solutions <span class="text-orange">.</span></a></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="portfolio-box">
                    <div class="portfolio-img animate-img">
                        <a routerLink='/project-detail'>
                            <img src="assets/images/portfolio-12.jpg" class="image-fit" alt="">
                        </a>
                        <div class="portfolio-overlay">
                            <div class="portfolio-text">
                                <p class="text-orange double-lines fw-500 mb-1"><span>Consultancy</span></p>
                                <h4 class="fw-600 no-margin"><a routerLink='/project-detail' class="text-custom-white">Miranda box company business solutions <span class="text-orange">.</span></a></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Portfolio -->
<!-- Start Counter -->
<section class="bg-light-white section-padding-top counter-sec-2 p-relative count-section">
    <div class="container">
        <div class="section-header">
            <div class="section-heading">
                <h6 class="text-orange mb-xl-10 sub-heading"><span>Counter</span></h6>
                <h3 class="text-blue fw-700 title">See our work impressions going high<span class="text-orange">.</span></h3>
            </div>
        </div>
        <div class="lb-big-text text-white">Counter</div>
        <div class="row">
            <div class="col-12">
                <div class="counter-box">
                    <div class="row">
                        <div class="col-lg-3 col-sm-6">
                            <div class="counter-with-icon mb-md-40">
                                <div class="icon text-orange">
                                    <i class="flaticon-men-group-outline"></i>
                                </div>
                                <div class="counter-text">
                                    <span class="count">5000</span><span><i class="fal fa-plus"></i></span>
                                    <p class="no-margin fw-500">Case Solved</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="counter-with-icon mb-md-40">
                                <div class="icon text-orange">
                                    <i class="flaticon-trophy"></i>
                                </div>
                                <div class="counter-text">
                                    <span class="count">120</span><span><i class="fal fa-plus"></i></span>
                                    <p class="no-margin fw-500">Get Rewards</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="counter-with-icon mb-xs-40">
                                <div class="icon text-orange">
                                    <i class="flaticon-coffee"></i>
                                </div>
                                <div class="counter-text">
                                    <span class="count">4921</span><span><i class="fal fa-plus"></i></span>
                                    <p class="no-margin fw-500">Cup Coffee</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="counter-with-icon">
                                <div class="icon text-orange">
                                    <i class="flaticon-remote"></i>
                                </div>
                                <div class="counter-text">
                                    <span class="count">100</span><span><i class="fal fa-plus"></i></span>
                                    <p class="no-margin fw-500">Country Cover</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Counter -->
<!-- Start faqs -->
<section class="section-padding faqs-style-2 pt-counter">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-xl-6 col-lg-7">
                <div class="images-box mb-md-80">
                    <img src="assets/images/faq-img-3.jpg" class="image-fit image-1" alt="">
                    <img src="assets/images/faq-img-4.jpg" class="image-fit image-2" alt="">
                </div>
            </div>
            <div class="col-xl-6 col-lg-5">
                <div class="left-side">
                    <div class="section-header left-heading">
                        <div class="section-heading">
                            <h6 class="text-orange mb-xl-10 sub-heading"><span>FAQ</span></h6>
                            <h3 class="fw-700 title">You have to know before start everything<span class="text-orange">.</span></h3>
                        </div>
                    </div>
                    <div id="accordion" class="custom-faqs style-3">
                        <div class="card">
                            <div class="card-header">
                                <a class="collapsed card-link" data-toggle="collapse" href="#collapseOne">
                                    How to import my whole data?
                                    <span>
                                        <i class="fal fa-arrow-down"></i>
                                    </span>
                                </a>
                            </div>
                            <div id="collapseOne" class="collapse" data-parent="#accordion">
                                <div class="card-body">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco lab oris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header">
                                <a class="card-link" data-toggle="collapse" href="#collapseTwo">
                                    How to import my whole data?
                                    <span>
                                        <i class="fal fa-arrow-down"></i>
                                    </span>
                                </a>
                            </div>
                            <div id="collapseTwo" class="collapse show" data-parent="#accordion">
                                <div class="card-body">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco lab oris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header">
                                <a class="collapsed card-link" data-toggle="collapse" href="#collapseThree">
                                    How to import my whole data?
                                    <span>
                                        <i class="fal fa-arrow-down"></i>
                                    </span>
                                </a>
                            </div>
                            <div id="collapseThree" class="collapse" data-parent="#accordion">
                                <div class="card-body">
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco lab oris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End faqs -->
<!-- Start Blog -->
<section class="section-padding bg-light">
    <div class="container">
        <div class="section-header">
            <div class="section-heading">
                <h6 class="text-orange mb-xl-10 sub-heading"><span>Insights</span></h6>
                <h3 class="fw-700 title">News and feeds<span class="text-orange">.</span></h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="blog-slider row">
                    <div class="col-lg-12 slide-item">
                        <div class="blog-style-1 style-2">
                            <div class="post-img animate-img">
                                <a routerLink='/news-detail'>
                                    <img src="assets/images/blog-1.jpg" class="image-fit" alt="">
                                </a>
                            </div>
                            <div class="post-date bg-orange">
                                <span class="date">24</span>
                                <span class="month">June</span>
                            </div>
                            <div class="post-content">
                                <div class="post-category">
                                    <p class="double-lines text-orange fw-600 mb-1"><span><a href="#" class="text-orange">Finance</a></span></p>
                                </div>
                                <h2 class="post-title"><a routerLink='/news-detail'>Improving lives with tech HSE lighthouse project</a></h2>
                                <ul class="custom-flex post-meta">
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-user text-orange"></i>
                                            Rosalia D.
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-calendar-alt text-orange"></i>
                                            24th June 2020
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <!-- blog overlay -->
                            <div class="post-overlay normal-bg" style="background-image: url('assets/images/blog-overlay-1.jpg');">
                                <div class="post-overlay-meta">
                                    <div class="post-category">
                                        <p class="double-lines text-custom-white fw-600 mb-1"><span><a href="#" class="text-custom-white">Finance</a></span></p>
                                    </div>
                                    <h2 class="post-title"><a routerLink='/news-detail' class="text-custom-white">Improving lives with tech HSE lighthouse project</a></h2>
                                    <ul class="custom-flex post-meta">
                                    <li>
                                        <a href="#" class="text-custom-white">
                                            <i class="fal fa-user text-custom-white"></i>
                                            Rosalia D.
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="text-custom-white">
                                            <i class="fal fa-calendar-alt text-custom-white"></i>
                                            24th June 2020
                                        </a>
                                    </li>
                                </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 slide-item">
                        <div class="blog-style-1 style-2">
                            <div class="post-img animate-img">
                                <a routerLink='/news-detail'>
                                    <img src="assets/images/blog-2.jpg" class="image-fit" alt="">
                                </a>
                            </div>
                            <div class="post-date bg-orange">
                                <span class="date">18</span>
                                <span class="month">June</span>
                            </div>
                            <div class="post-content">
                                <div class="post-category">
                                    <p class="double-lines text-orange fw-600 mb-1"><span><a href="#" class="text-orange">Business</a></span></p>
                                </div>
                                <h2 class="post-title"><a routerLink='/news-detail'>Improving lives with tech HSE lighthouse project</a></h2>
                                <ul class="custom-flex post-meta">
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-user text-orange"></i>
                                            Rosalia D.
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-calendar-alt text-orange"></i>
                                            18th June 2020
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <!-- blog overlay -->
                            <div class="post-overlay normal-bg" style="background-image: url('assets/images/blog-overlay-1.jpg');">
                                <div class="post-overlay-meta">
                                    <div class="post-category">
                                        <p class="double-lines text-custom-white fw-600 mb-1"><span><a href="#" class="text-custom-white">Finance</a></span></p>
                                    </div>
                                    <h2 class="post-title"><a routerLink='/news-detail' class="text-custom-white">Improving lives with tech HSE lighthouse project</a></h2>
                                    <ul class="custom-flex post-meta">
                                    <li>
                                        <a href="#" class="text-custom-white">
                                            <i class="fal fa-user text-custom-white"></i>
                                            Rosalia D.
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="text-custom-white">
                                            <i class="fal fa-calendar-alt text-custom-white"></i>
                                            24th June 2020
                                        </a>
                                    </li>
                                </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 slide-item">
                        <div class="blog-style-1 style-2">
                            <div class="post-img animate-img">
                                <a routerLink='/news-detail'>
                                    <img src="assets/images/blog-1.jpg" class="image-fit" alt="">
                                </a>
                            </div>
                            <div class="post-date bg-orange">
                                <span class="date">04</span>
                                <span class="month">June</span>
                            </div>
                            <div class="post-content">
                                <div class="post-category">
                                    <p class="double-lines text-orange fw-600 mb-1"><span><a href="#" class="text-orange">Consultancy</a></span></p>
                                </div>
                                <h2 class="post-title"><a routerLink='/news-detail'>Improving lives with tech HSE lighthouse project</a></h2>
                                <ul class="custom-flex post-meta">
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-user text-orange"></i>
                                            Rosalia D.
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-calendar-alt text-orange"></i>
                                            04th June 2020
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <!-- blog overlay -->
                            <div class="post-overlay normal-bg" style="background-image: url('assets/images/blog-overlay-1.jpg');">
                                <div class="post-overlay-meta">
                                    <div class="post-category">
                                        <p class="double-lines text-custom-white fw-600 mb-1"><span><a href="#" class="text-custom-white">Finance</a></span></p>
                                    </div>
                                    <h2 class="post-title"><a routerLink='/news-detail' class="text-custom-white">Improving lives with tech HSE lighthouse project</a></h2>
                                    <ul class="custom-flex post-meta">
                                    <li>
                                        <a href="#" class="text-custom-white">
                                            <i class="fal fa-user text-custom-white"></i>
                                            Rosalia D.
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" class="text-custom-white">
                                            <i class="fal fa-calendar-alt text-custom-white"></i>
                                            24th June 2020
                                        </a>
                                    </li>
                                </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog -->
<app-footer-one></app-footer-one>