<app-header-two></app-header-two>
<!-- Start Main Slider -->
<div class="main-slider-2 normal-bg" style="background-image: url('assets/images/banner-bg.jpg');">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-xl-7 col-lg-6">
                <img src="assets/images/faq-man.png" class="image-fit" alt="">
            </div>
            <div class="col-xl-5 col-lg-6">
                <div class="transform-center">
                    <div class="banner-text">
                        <ul class="custom-flex list-text">
                            <li class="text-orange fw-600 fs-20">Finance</li>
                            <li class="text-orange fw-600 fs-20">Planning</li>
                            <li class="text-orange fw-600 fs-20">Growth</li>
                        </ul>
                        <h1 class="fw-600 heading-title">Prosfer with a good planning and assets.</h1>
                        <p class="mb-xl-40">We seamlessly merge two key components – economics &amp; information technology</p>
                        <a routerLink='/service-1' class="theme-btn btn-orange mr-3">
                            Our Services
                            <i class="fal fa-plus"></i>
                        </a>
                        <a routerLink='/about' class="theme-btn btn-white">
                            Learn More
                            <i class="fal fa-plus"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <img src="assets/images/about-icon-1.png" class="banner-icon-1" alt="">
    <img src="assets/images/about-icon-2.png" class="banner-icon-2" alt="">
    <img src="assets/images/banner-layer-1.png" class="banner-layer-1" alt="">
    <img src="assets/images/banner-layer-2.png" class="banner-layer-2" alt="">
    <div class="banner-layer-text">
        <a href="#" class="logo">
            <img src="assets/images/logo-icon.png" class="image-fit" alt="">
        </a>
        <h2>Libo<span class="text-orange">.</span></h2>
        <p>Finance Website</p>
        <a routerLink='/contact' class="theme-btn btn-orange">Get Appointment <i class="fal fa-plus"></i></a>
    </div>
</div>
<!-- End Main Slider -->
<!-- Start About -->
<section class="section-padding bg-dark-blue about-style-2">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-head-text">
                    <p class="text-custom-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="section-header left-heading pb-0">
                    <div class="section-heading">
                        <h6 class="text-orange mb-xl-10 sub-heading"><span>About Us</span></h6>
                        <h3 class="text-custom-white fw-700 title">Core level values and amazing team<span class="text-orange">.</span></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About -->
<!-- Start Services -->
<section class="bg-light-white section-padding-bottom">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <div class="service-slider row">
                    <div class="slide-item col-12">
                        <div class="service-style-1">
                            <div class="service-image animate-img">
                                <a routerLink='/about'>
                                    <img src="assets/images/service-1.jpg" class="image-fit" alt="">
                                </a>
                            </div>
                            <div class="service-caption">
                                <div class="icon-box text-orange">
                                    <i class="flaticon-receipt"></i>
                                </div>
                                <div class="content-box">
                                    <h5 class="fw-700 mb-1"><a routerLink='/about'>Learn About Us</a></h5>
                                    <p class="no-margin fw-500">Move with a great team, its better</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slide-item col-12">
                        <div class="service-style-1">
                            <div class="service-image animate-img">
                                <a routerLink='/service-1'>
                                    <img src="assets/images/service-2.jpg" class="image-fit" alt="">
                                </a>
                            </div>
                            <div class="service-caption">
                                <div class="icon-box text-orange">
                                    <i class="flaticon-label"></i>
                                </div>
                                <div class="content-box">
                                    <h5 class="fw-700 mb-1"><a routerLink='/service-1'>What We Do</a></h5>
                                    <p class="no-margin fw-500">Move with a great team, its better</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slide-item col-12">
                        <div class="service-style-1">
                            <div class="service-image animate-img">
                                <a routerLink='/about'>
                                    <img src="assets/images/service-3.jpg" class="image-fit" alt="">
                                </a>
                            </div>
                            <div class="service-caption">
                                <div class="icon-box text-orange">
                                    <i class="flaticon-credit"></i>
                                </div>
                                <div class="content-box">
                                    <h5 class="fw-700 mb-1"><a routerLink='/about'>Mission And Vision</a></h5>
                                    <p class="no-margin fw-500">Move with a great team, its better</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h5 class="fw-600 no-margin">Stop Wasting time and money on Finance. <a routerLink='/about' class="text-orange underline">Explore our company</a></h5>
            </div>
        </div>
    </div>
</section>
<!-- End Services -->
<!-- Start Skillset -->
<section class="section-padding skillset-2 count-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="left-skillset mb-md-80">
                    <div class="section-header left-heading pb-0">
                        <div class="section-heading">
                            <h6 class="text-orange mb-xl-10 sub-heading"><span>Our Skillset</span></h6>
                            <h3 class="fw-700 title">Get amazing experience with our cool team<span class="text-orange">.</span></h3>
                            <p class="desc fw-500">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim veniam.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="skill-set-bx normal-bg" style="background-image: url('assets/images/skillset-box.jpg');">
                                <div class="counter">
                                <span class="count text-custom-white">90</span><span class="text-custom-white fs-16">%</span>
                                </div>
                                <p class="text-custom-white">Problem Solving</p>
                            </div>
                        </div>
                        <div class="col-6 skill-box-2">
                            <div class="skill-set-bx normal-bg" style="background-image: url('assets/images/skillset-box.jpg');">
                                <div class="counter">
                                <span class="count text-custom-white">65</span><span class="text-custom-white fs-16">%</span>
                                </div>
                                <p class="text-custom-white">Business Grow</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <img src="assets/images/skillset-2.jpg" class="image-fit" alt="">
            </div>
        </div>
    </div>
</section>
<!-- End Skillset -->
<!-- Start Case study 2 -->
<div class="container-fluid no-padding">
    <div class="case-study-slider">
        <div class="slide-item">
            <div class="case-study-box animate-img">
                <img src="assets/images/case-study-1.jpg" class="image-fit" alt="">
                <div class="case-content">
                    <div class="top-text">
                        <p class="double-lines text-custom-white mb-1"><span>Finance</span></p>
                        <h5 class="fw-600"><a routerLink='/project-detail' class="text-custom-white">Miranda computer home finance solution<span class="text-orange">.</span></a></h5>

                    </div>
                    <a routerLink='/project-detail' class="text-custom-white double-lines fw-500 link"><span>Case Study</span></a>
                </div>
            </div>
        </div>
        <div class="slide-item">
            <div class="case-study-box animate-img">
                <img src="assets/images/case-study-2.jpg" class="image-fit" alt="">
                <div class="case-content">
                    <div class="top-text">
                        <p class="double-lines text-custom-white mb-1"><span>Finance</span></p>
                        <h5 class="fw-600"><a routerLink='/project-detail' class="text-custom-white">Miranda computer home finance solution<span class="text-orange">.</span></a></h5>

                    </div>
                    <a routerLink='/project-detail' class="text-custom-white double-lines fw-500 link"><span>Case Study</span></a>
                </div>
            </div>
        </div>
        <div class="slide-item">
            <div class="case-study-box animate-img">
                <img src="assets/images/case-study-3.jpg" class="image-fit" alt="">
                <div class="case-content">
                    <div class="top-text">
                        <p class="double-lines text-custom-white mb-1"><span>Finance</span></p>
                        <h5 class="fw-600"><a routerLink='/project-detail' class="text-custom-white">Miranda computer home finance solution<span class="text-orange">.</span></a></h5>

                    </div>
                    <a routerLink='/project-detail' class="text-custom-white double-lines fw-500 link"><span>Case Study</span></a>
                </div>
            </div>
        </div>
        <div class="slide-item">
            <div class="case-study-box animate-img">
                <img src="assets/images/case-study-4.jpg" class="image-fit" alt="">
                <div class="case-content">
                    <div class="top-text">
                        <p class="double-lines text-custom-white mb-1"><span>Finance</span></p>
                        <h5 class="fw-600"><a routerLink='/project-detail' class="text-custom-white">Miranda computer home finance solution<span class="text-orange">.</span></a></h5>

                    </div>
                    <a routerLink='/project-detail' class="text-custom-white double-lines fw-500 link"><span>Case Study</span></a>
                </div>
            </div>
        </div>
        <div class="slide-item">
            <div class="case-study-box animate-img">
                <img src="assets/images/case-study-5.jpg" class="image-fit" alt="">
                <div class="case-content">
                    <div class="top-text">
                        <p class="double-lines text-custom-white mb-1"><span>Finance</span></p>
                        <h5 class="fw-600"><a routerLink='/project-detail' class="text-custom-white">Miranda computer home finance solution<span class="text-orange">.</span></a></h5>

                    </div>
                    <a routerLink='/project-detail' class="text-custom-white double-lines fw-500 link"><span>Case Study</span></a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Case study 2 -->
<!-- Start Counter -->
<section class="bg-light-white section-padding-top counter-sec-2 p-relative count-section">
    <div class="container">
        <div class="section-header">
            <div class="section-heading">
                <h6 class="text-orange mb-xl-10 sub-heading"><span>Counter</span></h6>
                <h3 class="text-blue fw-700 title">See our work impressions going high<span class="text-orange">.</span></h3>
            </div>
        </div>
        <div class="lb-big-text text-white">Counter</div>
        <div class="row"> 
            <div class="col-12">
                <div class="counter-box">
                    <div class="row">
                        <div class="col-lg-3 col-sm-6">
                            <div class="counter-with-icon mb-md-40">
                                <div class="icon text-orange">
                                    <i class="flaticon-men-group-outline"></i>
                                </div>
                                <div class="counter-text">
                                    <span class="count">5000</span><span><i class="fal fa-plus"></i></span>
                                    <p class="no-margin fw-500">Case Solved</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="counter-with-icon mb-md-40">
                                <div class="icon text-orange">
                                    <i class="flaticon-trophy"></i>
                                </div>
                                <div class="counter-text">
                                    <span class="count">120</span><span><i class="fal fa-plus"></i></span>
                                    <p class="no-margin fw-500">Get Rewards</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="counter-with-icon mb-xs-40">
                                <div class="icon text-orange">
                                    <i class="flaticon-coffee"></i>
                                </div>
                                <div class="counter-text">
                                    <span class="count">4921</span><span><i class="fal fa-plus"></i></span>
                                    <p class="no-margin fw-500">Cup Coffee</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="counter-with-icon">
                                <div class="icon text-orange">
                                    <i class="flaticon-remote"></i>
                                </div>
                                <div class="counter-text">
                                    <span class="count">100</span><span><i class="fal fa-plus"></i></span>
                                    <p class="no-margin fw-500">Country Cover</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Counter -->
<!-- Start Services --> 
<section class="section-padding pt-counter">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="section-header left-heading">
                    <div class="section-heading">
                        <h6 class="text-orange mb-xl-10 sub-heading"><span>Services We Provide</span></h6>
                        <h3 class="text-blue fw-700 title">What we do here<span class="text-orange">.</span></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 text-lg-right mb-xl-30">
                <a routerLink='/contact' class="theme-btn btn-orange">Contact Us <i class="fal fa-plus"></i></a>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4">
                <div class="service-style-2">
                    <div class="service-icon text-orange">
                        <i class="flaticon-tax"></i>
                    </div>
                    <div class="service-content">
                        <h4 class="fw-600"><a routerLink='/service-detail'>Tax and loan service</a></h4>
                        <p class="fw-500">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore</p>
                        <a routerLink='/service-detail' class="underline fw-600 text-orange fs-14">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="service-style-2">
                    <div class="service-icon text-orange">
                        <i class="flaticon-payment-method"></i>
                    </div>
                    <div class="service-content">
                        <h4 class="fw-600"><a routerLink='/service-detail'>Banking solutions</a></h4>
                        <p class="fw-500">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore</p>
                        <a routerLink='/service-detail' class="underline fw-600 text-orange fs-14">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="service-style-2">
                    <div class="service-icon text-orange">
                        <i class="flaticon-suitcase"></i>
                    </div>
                    <div class="service-content">
                        <h4 class="fw-600"><a routerLink='/service-detail'>New job & alert</a></h4>
                        <p class="fw-500">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore</p>
                        <a routerLink='/service-detail' class="underline fw-600 text-orange fs-14">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="service-style-2">
                    <div class="service-icon text-orange">
                        <i class="flaticon-presentation"></i>
                    </div>
                    <div class="service-content">
                        <h4 class="fw-600"><a routerLink='/service-detail'>Digital strategy</a></h4>
                        <p class="fw-500">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore</p>
                        <a routerLink='/service-detail' class="underline fw-600 text-orange fs-14">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="service-style-2">
                    <div class="service-icon text-orange">
                        <i class="flaticon-credit"></i>
                    </div>
                    <div class="service-content">
                        <h4 class="fw-600"><a routerLink='/service-detail'>Home loan service</a></h4>
                        <p class="fw-500">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore</p>
                        <a routerLink='/service-detail' class="underline fw-600 text-orange fs-14">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="service-style-2">
                    <div class="service-icon text-orange">
                        <i class="flaticon-label"></i>
                    </div>
                    <div class="service-content">
                        <h4 class="fw-600"><a routerLink='/service-detail'>Classic consultancy</a></h4>
                        <p class="fw-500">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore</p>
                        <a routerLink='/service-detail' class="underline fw-600 text-orange fs-14">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services -->
<!-- Start Quote -->
<div class="quote-sec style-2 p-relative">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="quote-box bg-dark-blue">
                    <div class="row align-items-end justify-content-between">
                        <div class="col-xl-5 col-lg-6">
                            <img src="assets/images/faq-man.png" class="image-fit quote-img" alt="">
                        </div>
                        <div class="col-xl-6 col-lg-6">
                            <div class="quote-text">
                                <div class="section-header left-heading">
                                    <div class="section-heading">
                                        <h3 class="fw-700 title text-custom-white">Get a free consultancy right now<span class="text-orange">.</span></h3>
                                    </div>
                                </div>
                                <p class="text-custom-white">We help you see the world differently, discover opportunities you may never have imagined.</p>
                                <a href="#" class="text-custom-white contact-no">
                                    <i class="fal fa-phone text-orange"></i>
                                    +989 876 765 65 4
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Quote -->
<!-- Start team -->
<section class="section-padding team-sec">
    <div class="container">
        <div class="section-header">
            <div class="section-heading">
                <h6 class="text-orange mb-xl-10 sub-heading"><span>Team</span></h6>
                <h3 class="text-blue fw-700 title">Our Leaders<span class="text-orange">.</span></h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="team-slider row">
                    <div class="slide-item col-12">
                        <div class="team-box animate-img">
                            <img src="assets/images/team-1.jpg" class="image-fit" alt="">
                            <div class="team-caption">
                                <div class="content">
                                    <h5 class="fw-600 mb-1"><a routerLink='/team' class="text-custom-white">Romanisa S. Samu</a></h5>
                                    <p class="text-orange fw-600 double-lines"><span>Consultant</span></p>
                                    <p class="text-light-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidi dunt ut labore et dolore. </p>
                                    <ul class="custom-flex team-social">
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-facebook-f"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-behance"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-linkedin"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-youtube"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slide-item col-12">
                        <div class="team-box animate-img">
                            <img src="assets/images/team-2.jpg" class="image-fit" alt="">
                            <div class="team-caption">
                                <div class="content">
                                    <h5 class="fw-600 mb-1"><a routerLink='/team' class="text-custom-white">Romanisa S. Samu</a></h5>
                                    <p class="text-orange fw-600 double-lines"><span>Consultant</span></p>
                                    <p class="text-light-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidi dunt ut labore et dolore. </p>
                                    <ul class="custom-flex team-social">
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-facebook-f"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-behance"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-linkedin"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-youtube"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slide-item col-12">
                        <div class="team-box animate-img">
                            <img src="assets/images/team-3.jpg" class="image-fit" alt="">
                            <div class="team-caption">
                                <div class="content">
                                    <h5 class="fw-600 mb-1"><a routerLink='/team' class="text-custom-white">Romanisa S. Samu</a></h5>
                                    <p class="text-orange fw-600 double-lines"><span>Consultant</span></p>
                                    <p class="text-light-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidi dunt ut labore et dolore. </p>
                                    <ul class="custom-flex team-social">
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-facebook-f"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-behance"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-linkedin"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-youtube"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slide-item col-12">
                        <div class="team-box animate-img">
                            <img src="assets/images/team-1.jpg" class="image-fit" alt="">
                            <div class="team-caption">
                                <div class="content">
                                    <h5 class="fw-600 mb-1"><a routerLink='/team' class="text-custom-white">Romanisa S. Samu</a></h5>
                                    <p class="text-orange fw-600 double-lines"><span>Consultant</span></p>
                                    <p class="text-light-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidi dunt ut labore et dolore. </p>
                                    <ul class="custom-flex team-social">
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-facebook-f"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-behance"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-linkedin"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" class="text-light-white">
                                                <i class="fab fa-youtube"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End team -->
<!-- Start brand slider -->
<div class="section-padding clients bg-orange">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-md-10">
                <div class="brand-slider row">
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/client-1.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/client-2.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/client-3.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/client-4.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/client-5.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/client-1.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/client-2.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/client-3.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/client-4.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/client-5.png" class="image-fit" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End brand slider -->
<!-- Start Blog -->
<section class="section-padding">
    <div class="container">
        <div class="section-header">
            <div class="section-heading">
                <h6 class="text-orange mb-xl-10 sub-heading"><span>News</span></h6>
                <h3 class="text-blue fw-700 title">Get connect with us<span class="text-orange">.</span></h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12"> 
                <div class="blog-slider row">
                    <div class="col-lg-12 slide-item">
                        <div class="blog-style-1">
                            <div class="post-img animate-img">
                                <a routerLink='/news-detail'>
                                    <img src="assets/images/blog-1.jpg" class="image-fit" alt="">
                                </a>
                            </div>
                            <div class="post-date">
                                <span class="date">24</span>
                                <span class="month">June</span>
                            </div>
                            <div class="post-content">
                                <div class="post-category">
                                    <p class="double-lines text-orange fw-600 mb-1"><span><a href="#" class="text-orange">Finance</a></span></p>
                                </div>
                                <h2 class="post-title"><a routerLink='/news-detail'>Improving lives with tech HSE lighthouse project</a></h2>
                                <ul class="custom-flex post-meta">
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-user text-orange"></i>
                                            Rosalia D.
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-calendar-alt text-orange"></i>
                                            24th June 2020
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 slide-item">
                        <div class="blog-style-1">
                            <div class="post-img animate-img">
                                <a routerLink='/news-detail'>
                                    <img src="assets/images/blog-2.jpg" class="image-fit" alt="">
                                </a>
                            </div>
                            <div class="post-date">
                                <span class="date">18</span>
                                <span class="month">June</span>
                            </div>
                            <div class="post-content">
                                <div class="post-category">
                                    <p class="double-lines text-orange fw-600 mb-1"><span><a href="#" class="text-orange">Business</a></span></p>
                                </div>
                                <h2 class="post-title"><a routerLink='/news-detail'>Improving lives with tech HSE lighthouse project</a></h2>
                                <ul class="custom-flex post-meta">
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-user text-orange"></i>
                                            Rosalia D.
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-calendar-alt text-orange"></i>
                                            18th June 2020
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 slide-item">
                        <div class="blog-style-1">
                            <div class="post-img animate-img">
                                <a routerLink='/news-detail'>
                                    <img src="assets/images/blog-3.jpg" class="image-fit" alt="">
                                </a>
                            </div>
                            <div class="post-date">
                                <span class="date">04</span>
                                <span class="month">June</span>
                            </div>
                            <div class="post-content">
                                <div class="post-category">
                                    <p class="double-lines text-orange fw-600 mb-1"><span><a href="#" class="text-orange">Consultancy</a></span></p>
                                </div>
                                <h2 class="post-title"><a routerLink='/news-detail'>Improving lives with tech HSE lighthouse project</a></h2>
                                <ul class="custom-flex post-meta">
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-user text-orange"></i>
                                            Rosalia D.
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-calendar-alt text-orange"></i>
                                            04th June 2020
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog -->
<app-footer-two></app-footer-two>