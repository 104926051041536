<app-header-one></app-header-one>
<!-- Start Subheader -->
<div class="normal-bg subheader" style="background-image: url('assets/images/subheader.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="subheader-content">
                    <h1 class="page-title text-custom-white fw-600">Our Leaders</h1>
                    <ul class="custom-flex breadcrumb">
                        <li><a routerLink='/'>Home</a></li>
                        <li>Team</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
<!-- Start Team -->
<section class="section-padding team-sec">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="team-block-2 p-relative mb-xl-30">
                    <div class="team-image animate-img">
                        <img src="assets/images/team-1.jpg" class="image-fit" alt="">
                    </div>
                    <ul class="team-social-media custom">
                        <li>
                            <a href="#">
                                <i class="fal fa-plus"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-youtube"></i>
                            </a>
                        </li>
                    </ul>
                    <div class="team-caption">
                        <h4 class="fw-600 mb-1"><a href="#">Romanisa S. Samu</a></h4>
                        <p class="no-margin fw-500 double-lines text-orange"><span>Founder</span></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="team-block-2 p-relative mb-xl-30">
                    <div class="team-image animate-img">
                        <img src="assets/images/team-2.jpg" class="image-fit" alt="">
                    </div>
                    <ul class="team-social-media custom">
                        <li>
                            <a href="#">
                                <i class="fal fa-plus"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-youtube"></i>
                            </a>
                        </li>
                    </ul>
                    <div class="team-caption">
                        <h4 class="fw-600 mb-1"><a href="#">Miranda H. Halim</a></h4>
                        <p class="no-margin fw-500 double-lines text-orange"><span>CEO</span></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="team-block-2 p-relative mb-xl-30">
                    <div class="team-image animate-img">
                        <img src="assets/images/team-3.jpg" class="image-fit" alt="">
                    </div>
                    <ul class="team-social-media custom">
                        <li>
                            <a href="#">
                                <i class="fal fa-plus"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-youtube"></i>
                            </a>
                        </li>
                    </ul>
                    <div class="team-caption">
                        <h4 class="fw-600 mb-1"><a href="#">Yokolili L. Limon</a></h4>
                        <p class="no-margin fw-500 double-lines text-orange"><span>CTO</span></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="team-block-2 p-relative mb-xl-30">
                    <div class="team-image animate-img">
                        <img src="assets/images/team-4.jpg" class="image-fit" alt="">
                    </div>
                    <ul class="team-social-media custom">
                        <li>
                            <a href="#">
                                <i class="fal fa-plus"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-youtube"></i>
                            </a>
                        </li>
                    </ul>
                    <div class="team-caption">
                        <h4 class="fw-600 mb-1"><a href="#">Pakura P. Pixer</a></h4>
                        <p class="no-margin fw-500 double-lines text-orange"><span>Consultant</span></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="team-block-2 p-relative mb-md-30">
                    <div class="team-image animate-img">
                        <img src="assets/images/team-5.jpg" class="image-fit" alt="">
                    </div>
                    <ul class="team-social-media custom">
                        <li>
                            <a href="#">
                                <i class="fal fa-plus"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-youtube"></i>
                            </a>
                        </li>
                    </ul>
                    <div class="team-caption">
                        <h4 class="fw-600 mb-1"><a href="#">XKeedin L. Loma</a></h4>
                        <p class="no-margin fw-500 double-lines text-orange"><span>Consultant</span></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="team-block-2 p-relative mb-md-30">
                    <div class="team-image animate-img">
                        <img src="assets/images/team-6.jpg" class="image-fit" alt="">
                    </div>
                    <ul class="team-social-media custom">
                        <li>
                            <a href="#">
                                <i class="fal fa-plus"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-youtube"></i>
                            </a>
                        </li>
                    </ul>
                    <div class="team-caption">
                        <h4 class="fw-600 mb-1"><a href="#">Yokolili Y. Yankee</a></h4>
                        <p class="no-margin fw-500 double-lines text-orange"><span>Consultant</span></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="team-block-2 p-relative mb-sm-30">
                    <div class="team-image animate-img">
                        <img src="assets/images/team-7.jpg" class="image-fit" alt="">
                    </div>
                    <ul class="team-social-media custom">
                        <li>
                            <a href="#">
                                <i class="fal fa-plus"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-youtube"></i>
                            </a>
                        </li>
                    </ul>
                    <div class="team-caption">
                        <h4 class="fw-600 mb-1"><a href="#">Harison H. Hillima</a></h4>
                        <p class="no-margin fw-500 double-lines text-orange"><span>Consultant</span></p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <div class="team-block-2 p-relative">
                    <div class="team-image animate-img">
                        <img src="assets/images/team-8.jpg" class="image-fit" alt="">
                    </div>
                    <ul class="team-social-media custom">
                        <li>
                            <a href="#">
                                <i class="fal fa-plus"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i class="fab fa-youtube"></i>
                            </a>
                        </li>
                    </ul>
                    <div class="team-caption">
                        <h4 class="fw-600 mb-1"><a href="#">Pukaran P. Paktun</a></h4>
                        <p class="no-margin fw-500 double-lines text-orange"><span>Consultant</span></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Team -->
<!-- Start Skillset -->
<section class="skillset-sec">
    <div class="container-fluid no-padding">
        <div class="row no-gutters">
            <div class="col-lg-6">
                <img src="assets/images/skillset-bg-2.jpg" class="image-fit" alt="">
            </div>
            <div class="col-lg-6 bg-dark-blue section-padding">
                <div class="section-header left-heading">
                    <div class="section-heading">
                        <h6 class="text-orange mb-xl-10 sub-heading"><span>Our Skillset</span></h6>
                        <h3 class="text-custom-white fw-700 title">Get amazing experience with our cool team<span class="text-orange">.</span></h3>
                        <p class="desc text-custom-white">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim veniam.</p>
                    </div>
                </div>
                <div class="skill-progress-bar-sec">
                    <div class="skill-progress-bar mb-xl-30">
                        <div class="progressbar-wrap">
                            <div class="progressbar" data-width="72"></div>
                        </div>
                        <div class="progressbar-text">
                            <p class="text-custom-white">Digital content create</p>
                            <p class="text-custom-white">72%</p>
                        </div>
                    </div>
                    <div class="skill-progress-bar mb-xl-30">
                        <div class="progressbar-wrap">
                            <div class="progressbar" data-width="80"></div>
                        </div>
                        <div class="progressbar-text">
                            <p class="text-custom-white">UI/UX Designing</p>
                            <p class="text-custom-white">80%</p>
                        </div>
                    </div>
                    <div class="skill-progress-bar mb-xl-30">
                        <div class="progressbar-wrap">
                            <div class="progressbar" data-width="90"></div>
                        </div>
                        <div class="progressbar-text">
                            <p class="text-custom-white">Digital consultancy</p>
                            <p class="text-custom-white">90%</p>
                        </div>
                    </div>
                    <div class="skill-progress-bar">
                        <div class="progressbar-wrap">
                            <div class="progressbar" data-width="98"></div>
                        </div>
                        <div class="progressbar-text">
                            <p class="text-custom-white">Youtube video making</p>
                            <p class="text-custom-white">98%</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Skillset -->
<!-- Start About -->
<section class="section-padding about-style-2 style-2">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-head-text">
                    <p class="fw-500">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="section-header left-heading pb-0">
                    <div class="section-heading">
                        <h6 class="text-orange mb-xl-10 sub-heading"><span>Portfolio</span></h6>
                        <h3 class="fw-700 title">Core level values and amazing team<span class="text-orange">.</span></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About -->
<!-- Start Services -->
<section class="section-padding-bottom">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <div class="service-slider mt-0 mb-0 row">
                    <div class="slide-item col-12">
                        <div class="service-style-1 withouticon">
                            <div class="service-image animate-img">
                                <a routerLink='/project-detail'>
                                    <img src="assets/images/service-1.jpg" class="image-fit" alt="">
                                </a>
                                <div class="overlay-box">
                                    <a routerLink='/project-detail' class="theme-btn btn-orange">Case Study <i class="fal fa-arrow-right"></i></a>
                                </div>
                            </div>
                            <div class="service-caption">
                                <div class="content-box">
                                    <h5 class="fw-700 mb-1"><a routerLink='/project-detail'>Miranda business solution</a></h5>
                                    <p class="no-margin">Move with a great team, its better</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slide-item col-12">
                        <div class="service-style-1 withouticon">
                            <div class="service-image animate-img">
                                <a routerLink='/project-detail'>
                                    <img src="assets/images/service-2.jpg" class="image-fit" alt="">
                                </a>
                                <div class="overlay-box">
                                    <a routerLink='/project-detail' class="theme-btn btn-orange">Case Study <i class="fal fa-arrow-right"></i></a>
                                </div>
                            </div>
                            <div class="service-caption">
                                <div class="content-box">
                                    <h5 class="fw-700 mb-1"><a routerLink='/project-detail'>Kilixer consultancy house</a></h5>
                                    <p class="no-margin">Move with a great team, its better</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slide-item col-12">
                        <div class="service-style-1 withouticon">
                            <div class="service-image animate-img">
                                <a routerLink='/project-detail'>
                                    <img src="assets/images/service-3.jpg" class="image-fit" alt="">
                                </a>
                                <div class="overlay-box">
                                    <a routerLink='/project-detail' class="theme-btn btn-orange">Case Study <i class="fal fa-arrow-right"></i></a>
                                </div>
                            </div>
                            <div class="service-caption">
                                <div class="content-box">
                                    <h5 class="fw-700 mb-1"><a routerLink='/project-detail'>Miranda export import solution</a></h5>
                                    <p class="no-margin">Move with a great team, its better</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services -->
<!-- Start brand slider -->
<div class="section-padding bg-light-white">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-md-10">
                <div class="brand-slider row">
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/brand-1.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/brand-2.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/brand-3.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/brand-4.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/brand-5.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/brand-1.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/brand-2.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/brand-3.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/brand-4.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/brand-5.png" class="image-fit" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End brand slider -->
<!-- Start Testimonials -->
<section class="normal-bg section-padding testimonials-sec" style="background-image: url('assets/images/map-bg.jpg');">
    <div class="container">
        <div class="section-header">
            <div class="section-heading">
                <h6 class="text-orange mb-xl-10 sub-heading"><span>Testimonials</span></h6>
                <h3 class="fw-700 title">Clients feedbacks<span class="text-orange">.</span></h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="testimonial-slider">
                    <div class="col-12 slide-item">
                        <div class="testimonial-box">
                            <div class="user-img">
                                <img src="assets/images/testi-1.jpg" class="image-fit" alt="">
                            </div>
                            <p class="fw-500">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore ma gna aliqua. Ut enim ad minim ve niam, quis nostrud exercitation ullamco lab oris nisi ut aliquip ex ea commodo cons equat. Duis aute irure dolor in reprehe nderit in voluptate velit esse.</p>
                            <h4 class="no-margin fw-600 mb-1"><a href="#">Miranda H.Halim</a></h4>
                            <p class="no-margin fw-600 text-orange double-lines"><span>Founder, Miranda Co.</span></p>
                        </div>
                    </div>
                    <div class="col-12 slide-item">
                        <div class="testimonial-box">
                            <div class="user-img">
                                <img src="assets/images/testi-2.jpg" class="image-fit" alt="">
                            </div>
                            <p class="fw-500">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore ma gna aliqua. Ut enim ad minim ve niam, quis nostrud exercitation ullamco lab oris nisi ut aliquip ex ea commodo cons equat. Duis aute irure dolor in reprehe nderit in voluptate velit esse.</p>
                            <h4 class="no-margin fw-600 mb-1"><a href="#">Miranda H.Halim</a></h4>
                            <p class="no-margin fw-600 text-orange double-lines"><span>Founder, Miranda Co.</span></p>
                        </div>
                    </div>
                    <div class="col-12 slide-item">
                        <div class="testimonial-box">
                            <div class="user-img">
                                <img src="assets/images/testi-3.jpg" class="image-fit" alt="">
                            </div>
                            <p class="fw-500">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore ma gna aliqua. Ut enim ad minim ve niam, quis nostrud exercitation ullamco lab oris nisi ut aliquip ex ea commodo cons equat. Duis aute irure dolor in reprehe nderit in voluptate velit esse.</p>
                            <h4 class="no-margin fw-600 mb-1"><a href="#">Miranda H.Halim</a></h4>
                            <p class="no-margin fw-600 text-orange double-lines"><span>Founder, Miranda Co.</span></p>
                        </div>
                    </div>
                    <div class="col-12 slide-item">
                        <div class="testimonial-box">
                            <div class="user-img">
                                <img src="assets/images/testi-1.jpg" class="image-fit" alt="">
                            </div>
                            <p class="fw-500">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore ma gna aliqua. Ut enim ad minim ve niam, quis nostrud exercitation ullamco lab oris nisi ut aliquip ex ea commodo cons equat. Duis aute irure dolor in reprehe nderit in voluptate velit esse.</p>
                            <h4 class="no-margin fw-600 mb-1"><a href="#">Miranda H.Halim</a></h4>
                            <p class="no-margin fw-600 text-orange double-lines"><span>Founder, Miranda Co.</span></p>
                        </div>
                    </div>
                    <div class="col-12 slide-item">
                        <div class="testimonial-box">
                            <div class="user-img">
                                <img src="assets/images/testi-2.jpg" class="image-fit" alt="">
                            </div>
                            <p class="fw-500">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore ma gna aliqua. Ut enim ad minim ve niam, quis nostrud exercitation ullamco lab oris nisi ut aliquip ex ea commodo cons equat. Duis aute irure dolor in reprehe nderit in voluptate velit esse.</p>
                            <h4 class="no-margin fw-600 mb-1"><a href="#">Miranda H.Halim</a></h4>
                            <p class="no-margin fw-600 text-orange double-lines"><span>Founder, Miranda Co.</span></p>
                        </div>
                    </div>
                    <div class="col-12 slide-item">
                        <div class="testimonial-box">
                            <div class="user-img">
                                <img src="assets/images/testi-3.jpg" class="image-fit" alt="">
                            </div>
                            <p class="fw-500">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore ma gna aliqua. Ut enim ad minim ve niam, quis nostrud exercitation ullamco lab oris nisi ut aliquip ex ea commodo cons equat. Duis aute irure dolor in reprehe nderit in voluptate velit esse.</p>
                            <h4 class="no-margin fw-600 mb-1"><a href="#">Miranda H.Halim</a></h4>
                            <p class="no-margin fw-600 text-orange double-lines"><span>Founder, Miranda Co.</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Testimonials -->
<app-footer-one></app-footer-one>